import React, { Fragment } from "react";
import { useHistory } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { LIST_CATEGORIES } from "admin/queries";
import Spinner from "components/common/Spinner";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import { adminLiveCategoryPath } from "admin/urls";
import { ListLiveCategories } from "admin/types/LiveCategory";
import FloatingAddButton from "components/FloatingAddButton";

export default function LiveCategoriesList() {
  const history = useHistory();

  const { loading, error, data } =
    useQuery<ListLiveCategories>(LIST_CATEGORIES);

  const openCategory = (id: number) => () => {
    history.push(adminLiveCategoryPath(String(id)));
  };

  function newCategory() {
    history.push(adminLiveCategoryPath("new"));
  }

  if (loading) return <Spinner />;
  if (error) return <div>Error! {error.message}</div>;

  return (
    <Fragment>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="left">Id</TableCell>
            <TableCell align="left">Name</TableCell>
            <TableCell align="left">Order</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data &&
            data.categories.map((c) => (
              <TableRow
                hover
                style={{ cursor: "pointer" }}
                key={c.id}
                onClick={openCategory(c.id)}
              >
                <TableCell align="left">{c.id}</TableCell>
                <TableCell align="left">{c.name}</TableCell>
                <TableCell align="left">{c.order}</TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <FloatingAddButton onClick={newCategory} />
    </Fragment>
  );
}
