import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import Routes from "./routes/Routes";
import CallcenterContextProvider from "context/CallcenterContext";
import LuxonUtils from "@date-io/luxon";
import { ThemeProvider } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/lab";
import { Provider } from "react-redux";
import store from "config/store";
import apolloClient from "config/apolloClient";
import { ApolloProvider } from "@apollo/client";
import theme from "config/theme";

ReactDOM.render(
  <ApolloProvider client={apolloClient}>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <Router>
          <LocalizationProvider dateAdapter={LuxonUtils}>
            <CallcenterContextProvider>
              <Routes />
            </CallcenterContextProvider>
          </LocalizationProvider>
        </Router>
      </ThemeProvider>
    </Provider>
  </ApolloProvider>,
  document.getElementById("root")
);
