import React, { Fragment, useState } from "react";
import { useParams } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import { LIST_GROUP_MEMBERS } from "admin/queries";
import { ADD_USER, REMOVE_USER } from "admin/mutations";
import {
  ListGroupMembers,
  UserData,
  UserDisplay,
  RemoveUserFromGroup,
  RemoveUserFromGroupVars,
  AddUserToGroup,
  AddUserToGroupVars,
} from "admin/types/Group";
import { MutationHookOptions } from "@apollo/client/react/types/types";
import Spinner from "components/common/Spinner";
import ConfirmModal from "components/ConfirmModal/";
import Table from "components/Table";
import FloatingAddButton from "components/FloatingAddButton";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import etvClient from "config/etvClient";
import { successAlert, errorAlert } from "components/common/Alert";
import TextFieldBase from "components/common/fields/TextFieldBase";

export default function UsersGroupDetails() {
  const { id } = useParams();
  const [delId, setDelId] = useState(NaN);
  const [modalOpen, setModalOpen] = useState(false);
  const [username, setUsername] = useState("");

  const { loading, error, data } = useQuery<ListGroupMembers>(
    LIST_GROUP_MEMBERS,
    {
      client: etvClient,
      variables: {
        id,
      },
    }
  );

  const [removeUserFromGroup, { loading: loadingRemove }] = useMutation<
    RemoveUserFromGroup,
    RemoveUserFromGroupVars
  >(REMOVE_USER, {
    ...getRemoveOptions(id),
    onCompleted: onRemoveCompleted,
    client: etvClient,
  });

  const [addUserToGroup, { loading: loadingAdd }] = useMutation<
    AddUserToGroup,
    AddUserToGroupVars
  >(ADD_USER, {
    ...getAddOptions(id),
    onCompleted: onAddCompleted,
    client: etvClient,
  });

  function onRemoveCompleted() {
    successAlert("Successfully removed user");
  }

  function onAddCompleted(data: AddUserToGroup) {
    data.addUserToGroup.user
      ? successAlert("Added user to group")
      : errorAlert("Could not find user");
  }

  const handleRemoveConfirm = () => {
    removeUserFromGroup({ variables: { userId: delId, groupId: id } });
    setDelId(NaN);
  };

  const handleAddConfirm = () => {
    addUserToGroup({
      variables: { username, groupId: id },
    });
    setModalOpen(false);
  };

  const actionComponent = (row: UserData) => (
    <IconButton onClick={() => setDelId(row.id)}>
      <DeleteIcon />
    </IconButton>
  );

  const handleUsernameChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setUsername(e.target.value);

  if (loading) return <Spinner />;
  if (error) return <div>Error! {error.message}</div>;
  if (!data) return <div>No Data Available</div>;

  return (
    <Fragment>
      <Typography variant="h6">
        Details for {data.groupMembers.name}{" "}
      </Typography>
      <Table<UserDisplay, UserData>
        data={data.groupMembers.users}
        columns={columns}
        actionComponent={actionComponent}
        hover={false}
      />

      <ConfirmModal
        onCancel={() => setDelId(NaN)}
        onConfirm={handleRemoveConfirm}
        open={!isNaN(delId)}
        title={"Confirmation"}
        body={`Are you sure you want to remove ${
          data.groupMembers.users.find((u) => u.id === delId)?.username
        }`}
      />

      <Dialog open={modalOpen} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Add User to Group</DialogTitle>
        <DialogContent>
          <TextFieldBase
            type="text"
            label="Username"
            maxLength={150}
            error={false}
            helperText=""
            autoComplete=""
            name="username-input"
            handleChange={handleUsernameChange}
            handleFocus={null}
            handleBlur={null}
            value={username}
            multiline={false}
            disabled={false}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setModalOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleAddConfirm} color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog>

      <FloatingAddButton onClick={() => setModalOpen(true)} />
      <Backdrop open={loadingRemove || loadingAdd}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Fragment>
  );
}

const columns = {
  username: {
    label: "Username",
  },
  email: {
    label: "Email",
  },
};

const getRemoveOptions: (
  id: number
) => MutationHookOptions<RemoveUserFromGroup, RemoveUserFromGroupVars> = (
  id
) => ({
  update(cache, { data }) {
    const cached = cache.readQuery<ListGroupMembers>({
      query: LIST_GROUP_MEMBERS,
      variables: {
        id,
      },
    });

    const name = cached?.groupMembers.name;
    const members = cached?.groupMembers.users;
    const toRemove = data?.removeUserFromGroup;

    if (name && members && toRemove) {
      etvClient.writeQuery({
        query: LIST_GROUP_MEMBERS,
        variables: {
          id: id,
        },
        data: {
          groupMembers: {
            name: name,
            users: members.filter((m) => m.id !== toRemove.userId),
          },
        },
      });
    }
  },
});

const getAddOptions: (
  id: number
) => MutationHookOptions<AddUserToGroup, AddUserToGroupVars> = (id) => ({
  update(cache, { data }) {
    const cached = cache.readQuery<ListGroupMembers>({
      query: LIST_GROUP_MEMBERS,
      variables: {
        id,
      },
    });
    const name = cached?.groupMembers?.name;
    const users = cached?.groupMembers?.users;
    const newUser = data?.addUserToGroup?.user;
    if (name && users && newUser) {
      cache.writeQuery({
        query: LIST_GROUP_MEMBERS,
        variables: {
          id,
        },
        data: {
          groupMembers: {
            name: name,
            users: users.filter((u) => u.id !== newUser.id).concat([newUser]),
          },
        },
      });
    }
  },
});
