import React, { useEffect, useRef } from "react";
import { abTestingStatsUrl } from "constants/urls";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import Spinner from "../common/Spinner";
import { useSimpleFetch } from "hooks/useSimpleFetch";
import TableSortLabel from "@mui/material/TableSortLabel";
import { ERROR } from "constants/actions";

export default function DetailedAbTestingStats({ date }) {
  const { state, dispatch, fetch, sort } = useSimpleFetch();
  const { isFetching, data, sorting } = state;
  const start = date.startDate.toISO();
  const prevStart = useRef(start);

  useEffect(() => {
    if (data && start !== prevStart.current) {
      dispatch({ type: ERROR });
      prevStart.current = start;
    }
  }, [start, data]);

  function handleClick() {
    const config = {
      method: "GET",
      params: {
        start_date: date.startDate.toISODate(),
        end_date: date.endDate.toISODate(),
        detailed: true,
      },
    };
    fetch(abTestingStatsUrl, config);
  }

  const fields = [
    {
      name: "payment_flow",
      label: "Payment Flow",
      type: "Number",
    },
    {
      name: "username",
      label: "Username",
    },
    {
      name: "plan_status",
      label: "Plan status",
    },
    {
      name: "plan_name",
      label: "Plan name",
    },
    {
      name: "total_paid",
      label: "Total paid",
      type: "Number",
    },
    {
      name: "total_bonuses",
      label: "Total bonuses",
      type: "Number",
    },
  ];

  return (
    <div style={{ margin: "20px 0" }}>
      {!data && !isFetching && (
        <div style={{ textAlign: "center" }}>
          <Button color="secondary" variant="contained" onClick={handleClick}>
            Detailed stats
          </Button>
        </div>
      )}
      {isFetching ? (
        <Spinner />
      ) : (
        data && (
          <Table>
            <TableHead>
              <TableRow>
                {fields.map((field) => (
                  <TableCell align="center" key={field.name}>
                    <TableSortLabel
                      active={sorting.field === field.name}
                      direction={sorting.isAscending ? "asc" : "desc"}
                      onClick={sort(field)}
                    >
                      {field.label}
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((user) => (
                <TableRow key={user.id}>
                  <TableCell align="center">{user.payment_flow}</TableCell>
                  <TableCell align="center">{user.username}</TableCell>
                  <TableCell align="center">{user.plan_status}</TableCell>
                  <TableCell align="center">{user.plan_name}</TableCell>
                  <TableCell align="center">{user.total_paid}</TableCell>
                  <TableCell align="center">{user.total_bonuses}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )
      )}
    </div>
  );
}
