export const domain = "https://www.etvnet.com";
// export const domain = "http://localhost:8000";
export const api = domain + "/api/crm/v2/";

export const oldCrm = "https://crm.etvnet.com";
export const oldCrmCustomersUrl = oldCrm + "/customers/";

export const loginUrl = api + "auth/login2";

export const recentCustomersUrl = api + "customers/recent.json";
export const searchCustomersUrl = api + "customers/search";
export const customerProfileUrl = api + "customers/:customerId";
export const customerAddressUrl = api + "customers/address/:customerId";
export const customerSecondaryEmailUrl =
  api + "customers/secondary-emails/:customerId";
export const customerBillingUrl = api + "customers/billing/:customerId";

export const abTestingStatsUrl = api + "stats/ab-testing";
export const txsStatsUrl = api + "stats/txs";
export const fbLeadsStatsUrl = api + "stats/fb-leads";

export const txsListUrl = api + "txs/list/:customerId";
export const txsRefundUrl = api + "txs/refund/:customerId";

export const countriesUrl = api + "countries";
export const liveChannelFiltersUrl = api + "live/filters";
export const plansUrl = api + "plans";

const ccApiDomain = "https://callcenter-api.etvnet.com/";
// const ccApiDomain = "http://172.17.0.1:5000/";
export const ccAgentsUrl = ccApiDomain + "existing-extensions";
export const ccWorkloadStatsUrl =
  ccApiDomain + "workload?date=%date&agent=%agent";
// export const sendSmsUrl = ccApiDomain + "sms/send";

const lambdaDomain = process.env.REACT_APP_LAMBDA_SMS_APIGW + "Prod/";
//  "https://321oewjsj5.execute-api.us-east-1.amazonaws.com/Prod/";
export const sendSmsUrl = lambdaDomain + "sms";
export const listSmsUrl = lambdaDomain + "sms/:customerId";

// export const smsUrl = "http://127.0.0.1:3000/sms/";
export const smsUrl =
  "https://116l788llc.execute-api.us-east-1.amazonaws.com/Prod/sms/";

// export const emailUrl = "http://127.0.0.1:3000/email/";
export const emailUrl =
  "https://116l788llc.execute-api.us-east-1.amazonaws.com/Prod/email/";

export const callcenterWsUrl =
  "wss://1cscrps2ah.execute-api.us-east-1.amazonaws.com/Prod";

export const customerEventsUrl = process.env.REACT_APP_ETV_CUSTOMER_EVENTS_APIGW + "Prod/";
//  "https://028sweq3xe.execute-api.us-east-1.amazonaws.com/Prod/";

export const customerPhonesUrl =
  "https://ms9wypmqf3.execute-api.us-east-1.amazonaws.com/Prod/";
export const customerPhonesGraphQLDomain = customerPhonesUrl + "graphql/";

export const tasksUrl = "http://localhost:3375/";
export const tasksGraphQLDomain = tasksUrl + "graphql";

// export const liveDomain = "http://127.0.0.1:3000/";
export const liveDomain =
  "https://ynnqhg4ap5.execute-api.us-east-1.amazonaws.com/Prod/";
export const liveGraphQLDomain = liveDomain + "graphql/";
export const liveAdminApi = liveDomain + "admin/";
export const adminLiveChannelsUrl = liveAdminApi + "channels/";
export const adminLiveChannelUrl = liveAdminApi + "channels/:channelId";
export const adminLiveCategoriesUrl = liveAdminApi + "categories/";
export const adminTimezonesUrl = liveAdminApi + "timezones/";
export const adminBitratesUrl = liveAdminApi + "bitrates/";
export const adminChannelScheduleUrl = liveAdminApi + "channel-schedule/";
