import {
  adminLiveCategoriesPath,
  adminLiveTimezonesPath,
  adminUsersStaffPath,
  adminUsersGroupsPath,
  adminVodIndexesPath,
} from "admin/urls";

export const urls = [
  adminLiveCategoriesPath,
  adminLiveTimezonesPath,
  adminUsersStaffPath,
  adminUsersGroupsPath,
  adminVodIndexesPath,
] as const;

export type urlType = typeof urls[number];

export type Expansion = Record<
  urlType,
  {
    selected: string;
    expanded: string[];
  }
>;
