import { UPDATE_FORM } from "reducers/formReducer";
import TransferList from "components/common/fields/TransferList";
import React, { useState } from "react";
import Typography from "@mui/material/Typography";

function ChannelFilter({
  allItems,
  filters,
  filterId,
  filterType,
  dispatch,
  idField = "id",
  nameField = "name",
}) {
  const currentFilter = filters.find((f) => f.id === filterId);
  const names = allItems.map((a) => a[nameField]);
  const right = getRight();
  const options = names.filter((i) => !right.includes(i));
  const [left, setLeft] = useState(options);

  function getRight() {
    const selected = [];
    for (let i = 0; i < allItems.length; i++) {
      if (currentFilter[filterType].includes(allItems[i][idField].toString())) {
        selected.push(allItems[i][nameField]);
      }
    }
    return selected;
  }

  function updateForm(selected) {
    const excluded = filters.filter((f) => f.id !== filterId);
    const ids = selected.map((s) =>
      allItems.find((a) => a[nameField] === s)[idField].toString()
    );
    const copiedFlt = { ...currentFilter, [filterType]: ids };
    dispatch({
      type: UPDATE_FORM,
      payload: { filters: [...excluded, copiedFlt] },
    });
  }
  return (
    <div style={{ marginBottom: 16 }}>
      <Typography variant="h6" gutterBottom>
        {`Select ${filterType}`}
      </Typography>
      <TransferList
        left={left}
        setLeft={setLeft}
        right={right}
        setRight={updateForm}
      />
    </div>
  );
}

export const CountryFilter = (props) => (
  <ChannelFilter
    {...props}
    allItems={props.countries}
    filterType={"countries"}
  />
);

export const PlanFilter = (props) => (
  <ChannelFilter {...props} allItems={props.plans} filterType={"plans"} />
);

export const DeviceFilter = (props) => (
  <ChannelFilter
    {...props}
    allItems={props.devices}
    filterType={"devices"}
    nameField={"device"}
  />
);

export const CmGroupFilter = (props) => (
  <ChannelFilter
    {...props}
    allItems={props.cm_groups}
    filterType={"cm_groups"}
  />
);
