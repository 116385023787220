import React, { FormEvent, useEffect, useReducer, useRef } from "react";
import { useMutation } from "@apollo/client";
import {
  GetCustomerBillingInfo,
  CustomerCreditCard,
  UpdateCustomerBillingInfo,
  UpdateCustomerBillingInfoInput,
  UpdateCustomerBillingInfoVars,
} from "customers/types/Customer";
import { GET_CUSTOMER_BILLING_INFO } from "customers/queries";
import { UPDATE_CUSTOMER_BILLING_INFO } from "customers/mutations";
import {
  getCreditCardType,
  getCreditCardTypeInt,
  formatISODate,
} from "utils/helpers";
import etvClient from "config/etvClient";
import formReducer, { FormState, UPDATE_FORM } from "reducers/formReducer";
import { getFormErrors, isCreditCard } from "utils/helpers";
import Grid from "@mui/material/Grid";
import TextField from "components/common/fields/TextField";
import Backdrop from "@mui/material/Backdrop";
import Spinner from "components/common/Spinner";
import { errorAlert, successAlert } from "components/common/Alert";
import CreditCard from "components/common/fields/CreditCard";
import ExpirationDate from "components/common/fields/ExpirationDate";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

export interface CustomerBillingInfoFormProps {
  customerId: number;
  billingInfo?: CustomerCreditCard;
  close: () => void;
}

export default function CustomerBillingInfoForm({
  customerId,
  billingInfo,
  close,
}: CustomerBillingInfoFormProps) {
  const [state, dispatchForm] = useReducer(
    formReducer,
    {},
    populateFieldValues
  );

  const [update, { loading }] = useMutation<
    UpdateCustomerBillingInfo,
    UpdateCustomerBillingInfoVars
  >(UPDATE_CUSTOMER_BILLING_INFO, {
    client: etvClient,
    update: (cache, { data }) => {
      const cached = cache.readQuery<GetCustomerBillingInfo>({
        query: GET_CUSTOMER_BILLING_INFO,
        variables: { id: customerId.toString() },
      });
      const c = cached?.customer;
      const billingInfo = {
        fname: created.current?.fname,
        lname: created.current?.lname,
        ccNum: "*".repeat(12) + created.current?.ccNum.slice(-4),
        ccType: created.current?.ccType,
        expMonth: created.current?.expMonth,
        expYear: created.current?.expYear,
        added: c?.billingInfo?.added ?? new Date().toISOString(),
      };
      c &&
        etvClient.writeQuery({
          query: GET_CUSTOMER_BILLING_INFO,
          variables: { id: customerId.toString() },
          data: { customer: { ...c, billingInfo } },
        });
    },
    onCompleted: () => {
      close();
      successAlert(`${billingInfo ? "Updated" : "Created"} successfully`);
    },
    onError: (error) => {
      errorAlert(`Failed to update: ${error.message}`);
    },
  });

  const created = useRef<UpdateCustomerBillingInfoInput>();

  function handleSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    const ccField = state.credit_card;
    const isCard = isCreditCard(ccField.value);

    let errors = getFormErrors(state);
    if (errors || !isCard) {
      if (!isCard) {
        errors = { ...errors, [ccField.field]: { ...ccField, error: true } };
      }
      dispatchForm({ type: UPDATE_FORM, payload: errors });
      return;
    }
    // TODO: utils function to return credit card type name by id
    const ccType = getCreditCardType(state.credit_card.value);
    const ccTypeInt = getCreditCardTypeInt(ccType);
    const input: UpdateCustomerBillingInfoInput = {
      fname: state.first_name.value,
      lname: state.last_name.value,
      ccNum: state.credit_card.value,
      ccType: ccTypeInt,
      expMonth: Number(state.month.value),
      expYear: Number(state.year.value),
      cvv: Number(state.cvv.value),
    };
    created.current = input;
    update({ variables: { input, id: customerId } });
  }

  function populateFieldValues() {
    //deep copying categoryFields to avoid mutations
    const fields = JSON.parse(JSON.stringify(creditCardFields));
    fields.first_name.value = billingInfo?.fname || "";
    fields.last_name.value = billingInfo?.lname || "";
    fields.credit_card.value = billingInfo?.ccNum || "";
    fields.month.value = billingInfo?.expMonth || "";
    fields.year.value = billingInfo?.expYear || "";
    return fields;
  }

  const ccForm = (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <TextField {...state.first_name} dispatch={dispatchForm} />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField {...state.last_name} dispatch={dispatchForm} />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <CreditCard {...state.credit_card} dispatch={dispatchForm} />
        </Grid>
        <Grid item xs={12} md={4}>
          <ExpirationDate state={state} dispatch={dispatchForm} />
        </Grid>
        <Grid item xs={12} md={2}>
          <TextField {...state.cvv} dispatch={dispatchForm} />
        </Grid>
      </Grid>
    </>
  );

  return (
    <>
      {billingInfo ? (
        <form onSubmit={handleSubmit}>
          {ccForm}
          <Grid container justifyContent="center">
            <Button variant="contained" type="submit" color="primary">
              Update
            </Button>
            <Button onClick={close} style={{ marginLeft: 10 }} color="primary">
              Cancel
            </Button>
          </Grid>
        </form>
      ) : (
        <Dialog open={true} onClose={close} aria-labelledby="form-dialog-title">
          <form onSubmit={handleSubmit}>
            <DialogTitle id="form-dialog-title">Add Credit Card</DialogTitle>
            <DialogContent>{ccForm}</DialogContent>
            <DialogActions>
              <Button onClick={close} color="primary">
                Cancel
              </Button>
              <Button type="submit" color="primary">
                Ok
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      )}
      <Backdrop style={{ zIndex: 9999 }} open={loading}>
        <Spinner />
      </Backdrop>
    </>
  );
}

const creditCardFields: FormState = {
  first_name: {
    value: "",
    field: "first_name",
    label: "First name",
    pattern: "a-zA-Z- ",
    maxLength: 50,
    error: false,
    helperText: "Please enter first name",
  },
  last_name: {
    value: "",
    field: "last_name",
    label: "Last name",
    pattern: "a-zA-Z- ",
    maxLength: 50,
    error: false,
    helperText: "Please enter last name",
  },
  credit_card: {
    value: "",
    field: "credit_card",
    label: "Card Number",
    pattern: "0-9",
    maxLength: 25,
    error: false,
    helperText: "Please enter credit card number",
  },
  month: {
    value: "",
    field: "month",
    label: "MM",
    error: false,
    helperText: "Please select expiry month",
  },
  year: {
    value: "",
    field: "year",
    label: "YYYY",
    error: false,
    helperText: "Please select expiry year",
  },
  cvv: {
    value: "",
    field: "cvv",
    label: "CVV2",
    pattern: "0-9",
    maxLength: 4,
    error: false,
    helperText: "Please enter CVV2",
  },
};
