import React, { Fragment } from "react";
import { useHistory } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { LIST_TIMEZONES } from "admin/queries";
import { LiveTimezone, ListLiveTimezones } from "admin/types/LiveTimezone";
import { adminLiveTimezonePath } from "admin/urls";
import Spinner from "components/common/Spinner";
import Table from "components/Table";
import FloatingAddButton from "components/FloatingAddButton";
import { Align } from "components/Table/types";

export default function LiveTimezonesList() {
  const history = useHistory();

  const { loading, error, data } = useQuery<ListLiveTimezones>(LIST_TIMEZONES);

  const openTimezone = (row: LiveTimezone) => {
    const escaped = encodeURIComponent(row.name);
    history.push(adminLiveTimezonePath(escaped));
  };

  function newTimezone() {
    history.push(adminLiveTimezonePath("new"));
  }

  if (loading) return <Spinner />;
  if (error) return <div>Error! {error.message}</div>;
  if (!data) return <div>No Data Available</div>;

  const columns = {
    name: {
      label: "Name",
    },
    city: {
      label: "City",
    },
    order: {
      label: "Order",
    },
    isActive: {
      label: "Active",
      format: (x: string) => (x ? "yes" : "no"),
      align: Align.Center,
    },
    longitude: {
      label: "Longitude",
      align: Align.Right,
    },
    latitude: {
      label: "Latitude",
      align: Align.Right,
    },
  };

  return (
    <Fragment>
      <Table<LiveTimezone>
        data={data.timezones}
        columns={columns}
        onClick={openTimezone}
      />
      <FloatingAddButton onClick={newTimezone} />
    </Fragment>
  );
}
