import React, { FormEvent, useEffect, useReducer, useRef } from "react";
import formReducer, { FormState, UPDATE_FORM } from "reducers/formReducer";
import {
  Customer,
  GetCustomer,
  UpdateCustomerAccount,
  UpdateCustomerAccountInput,
  UpdateCustomerAccountVars,
} from "customers/types/Customer";
import Grid from "@mui/material/Grid";
import TextField from "components/common/fields/TextField";
import EmailField from "components/common/fields/EmailField";
import Checkbox from "components/common/fields/Checkbox";
import Button from "@mui/material/Button";
import { getFormData, getFormErrors } from "utils/helpers";
import { useMutation } from "@apollo/client";
import { UPDATE_CUSTOMER_ACCOUNT } from "customers/mutations";
import etvClient from "config/etvClient";
import { errorAlert, successAlert } from "components/common/Alert";
import Backdrop from "@mui/material/Backdrop";
import Spinner from "components/common/Spinner";
import { GET_CUSTOMER } from "customers/queries";

export interface CustomerAccountFormProps {
  customer: Customer;
  closeEdit: () => void;
}

export default function CustomerAccountForm({
  customer,
  closeEdit,
}: CustomerAccountFormProps) {
  const [state, dispatchForm] = useReducer(
    formReducer,
    {},
    populateFieldValues
  );
  const [update, { loading }] = useMutation<
    UpdateCustomerAccount,
    UpdateCustomerAccountVars
  >(UPDATE_CUSTOMER_ACCOUNT, {
    client: etvClient,
    onCompleted,
    onError: () => errorAlert("Failed to update"),
  });

  // using this to update cache
  const created = useRef<UpdateCustomerAccountInput>();

  function onCompleted(data: UpdateCustomerAccount) {
    try {
      const cached = etvClient.readQuery<GetCustomer>({
        query: GET_CUSTOMER,
        // doesn't find if passing int
        variables: { id: customer.id.toString() },
      });
      const c = cached?.customer;
      c &&
        etvClient.writeQuery({
          query: GET_CUSTOMER,
          data: { customer: { ...c, ...created.current } },
        });
    } catch (e) {
      console.log(e);
    }
    closeEdit();
    successAlert("Updated successfully");
  }

  function populateFieldValues() {
    //deep copying categoryFields to avoid mutations
    const fields = JSON.parse(JSON.stringify(accountFields));
    fields.firstName.value = customer.firstName;
    fields.lastName.value = customer.lastName;
    fields.username.value = customer.username;
    fields.email.value = customer.email;
    fields.emailConfirmed.checked = customer.emailConfirmed;
    return fields;
  }

  function handleSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    const errors = getFormErrors(state);
    if (errors) {
      dispatchForm({ type: UPDATE_FORM, payload: errors });
      return;
    }
    const input = {
      firstName: state.firstName.value,
      lastName: state.lastName.value,
      username: state.username.value,
      password: state.password.value,
      email: state.email.value,
      emailConfirmed: Boolean(state.emailConfirmed.checked),
    };
    created.current = input;

    update({ variables: { input, id: customer.id } });
  }

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <TextField {...state.firstName} dispatch={dispatchForm} />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField {...state.lastName} dispatch={dispatchForm} />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <TextField {...state.username} dispatch={dispatchForm} />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField {...state.password} dispatch={dispatchForm} />
        </Grid>
      </Grid>
      <Grid container spacing={2} alignItems={"center"}>
        <Grid item xs={12} md={6}>
          <EmailField {...state.email} dispatch={dispatchForm} />
        </Grid>
        <Grid item xs={12} md={6}>
          <Checkbox {...state.emailConfirmed} dispatch={dispatchForm} />
        </Grid>
      </Grid>
      <TextField {...state.comments} dispatch={dispatchForm} />
      <Grid container justifyContent="center">
        <Button variant="contained" type="submit" color="primary">
          Update
        </Button>
        <Button style={{ marginLeft: 10 }} onClick={closeEdit} color="primary">
          Cancel
        </Button>
      </Grid>
      <Backdrop style={{ zIndex: 9999 }} open={loading}>
        <Spinner />
      </Backdrop>
    </form>
  );
}

const accountFields: FormState = {
  firstName: {
    value: "",
    field: "firstName",
    label: "First name",
    maxLength: 30,
    error: false,
    helperText: "Please enter first name",
  },
  lastName: {
    value: "",
    field: "lastName",
    label: "Last name",
    maxLength: 30,
    error: false,
    helperText: "Please enter last name",
  },
  email: {
    value: "",
    field: "email",
    label: "Email",
    pattern: "^\\S+@\\S+$",
    error: false,
    helperText: "Please enter email",
  },
  username: {
    value: "",
    field: "username",
    label: "Username",
    pattern: "a-zA-Z@.+-_",
    maxLength: 254,
    error: false,
    helperText: "Please enter username",
  },
  password: {
    value: "******",
    field: "password",
    label: "Password",
    error: false,
    helperText: "Please enter password",
  },
  emailConfirmed: {
    field: "emailConfirmed",
    checked: false,
    label: "Email confirmed",
    value: "Email confirmed",
    error: false,
  },
  comments: {
    value: "",
    field: "comments",
    label: "Comments",
    maxLength: 500,
    blank: true,
    multiline: true,
  },
};
