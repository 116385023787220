import React from "react";
import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Paper from "@mui/material/Paper";

const useStyles = makeStyles({
  details: {
    background: "#f5f5f5",
    padding: 10,
  },
  agent: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 10,
  },
});

export default function Log({ agent, comment, diff }) {
  const classes = useStyles();

  return (
    <Paper className={classes.details}>
      <div className={classes.agent}>
        <Typography variant={"body2"}>Agent: {agent}</Typography>
      </div>
      <Typography variant={"body2"}>Comment: {comment}</Typography>
      <br />
      <Typography variant={"body2"}>Changes: </Typography>
      {diff &&
        diff.map((item) => (
          <Typography key={item.field} variant={"body2"}>
            {item.field}: {item.old || "none"} {"->"} {item.new}
          </Typography>
        ))}
    </Paper>
  );
}
