import React, { useContext, useState } from "react";
import { useSelector } from "react-redux";
import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import Collapse from "@mui/material/Collapse";
import CircleIcon from "@mui/icons-material/Circle";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import CallReceivedIcon from "@mui/icons-material/CallReceived";
import CallMadeIcon from "@mui/icons-material/CallMade";
import { CallCenterContext } from "callcenter/components/CallCenter";
import SoftPhone from "callcenter/components/SoftPhone/SoftPhone";
import { Agent, Queue } from "callcenter/types";

const STATUS_GROUPS = ["Online", "Offline"];
const TABS = ["Agents", "Queues", "Softphone"];

export function CallCenterDrawer() {
  const [tab, setTab] = useState(0);
  const { agents, queues } = useContext(CallCenterContext);
  // TODO rewrite redux reducers/state in typescript
  // @ts-ignore
  const user = useSelector((state) => state.user);

  function onTabChange(tab: number) {
    setTab(tab);
  }

  return (
    <Drawer
      variant="permanent"
      anchor="right"
      sx={{
        width: 320,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
          width: 320,
          boxSizing: "border-box",
        },
      }}
    >
      <Toolbar />
      <Box sx={{ overflow: "auto" }}>
        <DrawerTabs tab={tab} onTabChange={onTabChange} />
        <AgentsTabView display={tab === 0} agents={agents} />
        <QueuesTabView display={tab === 1} queues={queues} />
        <SoftPhone display={tab === 2} />
      </Box>
    </Drawer>
  );
}

interface DrawerTabsProps {
  tab: number;
  onTabChange: (tab: number) => void;
}

function DrawerTabs({ tab, onTabChange }: DrawerTabsProps) {
  return (
    <Tabs
      variant="fullWidth"
      value={tab}
      onChange={(event, tab) => onTabChange(tab)}
    >
      {TABS.map((t, i) => (
        <Tab key={i} value={i} label={t} />
      ))}
    </Tabs>
  );
}

interface AgentsTabViewProps {
  display: boolean;
  agents: Agent[];
}

function AgentsTabView({ display, agents }: AgentsTabViewProps) {
  return (
    <List
      subheader={<ListSubheader>Team States</ListSubheader>}
      sx={{
        display: display ? "block" : "none",
        "& .MuiListItem-root": {
          borderBottom: "1px solid #bdbdbd",
          "&:last-of-type": { border: "none" },
        },
      }}
    >
      {STATUS_GROUPS.map((g, i) => (
        <AgentListItemGroup
          key={i}
          title={g}
          isOpen={true}
          agents={
            g === "Online"
              ? agents.filter((a) => a.status === "Available")
              : agents.filter((a) => a.status === g)
          }
        />
      ))}
    </List>
  );
}

interface AgentListItemGroup {
  title: string;
  isOpen?: boolean;
  agents: Agent[];
}

function AgentListItemGroup({ title, isOpen, agents }: AgentListItemGroup) {
  const [open, setOpen] = useState(isOpen);

  function toggleOpen() {
    setOpen(!open);
  }

  return (
    <>
      <ListItemButton
        onClick={toggleOpen}
        sx={{ bgcolor: "#EAEEF3", borderBottom: "1px solid #bdbdbd" }}
      >
        <ListItemIcon>{open ? <ExpandLess /> : <ExpandMore />}</ListItemIcon>
        <ListItemText primary={`${agents.length} ${title}`} />
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {agents.map((a, j) => (
            <ListItem key={j} alignItems="center" sx={{ gap: 2 }}>
              <CircleIcon
                sx={{
                  fontSize: 14,
                  color: a.status === "Available" ? "green" : "red",
                }}
              />
              <ListItemText
                sx={{
                  "&>.MuiListItemText-secondary": {
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    gap: 1,
                  },
                }}
                primary={`${a.firstName} ${a.lastName}`}
                secondary={
                  <>
                    <span style={{ display: "block" }}>{a.status}</span>
                    {/*
                    CONTACT INFORMATION COMMENTED FOR NOW
                    {a.contacts.length > 0 && (
                      <Typography
                        component="span"
                        variant="subtitle2"
                        sx={{
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Tooltip
                          title={
                            a.contacts[0].incoming ? "Incoming" : "Outgoing"
                          }
                        >
                          {a.contacts[0].incoming ? (
                            <CallReceivedIcon fontSize="small" />
                          ) : (
                            <CallMadeIcon fontSize="small" />
                          )}
                        </Tooltip>
                        <span
                          style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {a.contacts[0].phone} {a.contacts[0].username} at{" "}
                          {a.contacts[0].startedAt}
                        </span>
                      </Typography>
                    )} */}
                  </>
                }
              />
            </ListItem>
          ))}
        </List>
      </Collapse>
    </>
  );
}

interface QueuesTabViewProps {
  display: boolean;
  queues: Queue[];
}

function QueuesTabView({ display, queues }: QueuesTabViewProps) {
  return (
    <List
      component="div"
      disablePadding
      sx={{
        display: display ? "block" : "none",
        "& .MuiListItem-root": {
          borderBottom: "1px solid #bdbdbd",
          "&:last-of-type": { border: "none" },
        },
      }}
    >
      {queues.map((q, j) => (
        <ListItem key={j} alignItems="center" sx={{ gap: 2 }}>
          <CircleIcon sx={{ fontSize: 14 }} />
          <ListItemText
            sx={{
              "&>.MuiListItemText-secondary": {
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: 1,
              },
            }}
            primary={q.name}
            secondary={q.count}
          />
        </ListItem>
      ))}
    </List>
  );
}
