import React, { Fragment, useEffect, useReducer, useState } from "react";
import { oldCrmCustomersUrl, searchCustomersUrl } from "constants/urls";
import { ERROR, FETCHING, SUCCESS } from "constants/actions";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { simpleInitialState, simpleReducer } from "reducers/simpleReducer";
import Spinner from "../common/Spinner";
import { useQueryParams } from "hooks/useQueryParams";
import { useHistory, useParams } from "react-router-dom";
import { _fetch } from "hooks/useFetch";
import { customerProfilePath } from "constants/paths";

export default function Search() {
  const [state, dispatch] = useReducer(simpleReducer, simpleInitialState);
  const { isFetching, data, errorMessage } = state;
  const history = useHistory();

  const field = useQueryParams().get("field");
  const { query } = useParams();

  useEffect(() => {
    query && fetchData();
  }, [query, field]);

  async function fetchData() {
    dispatch({ type: FETCHING });

    const config = {
      method: "GET",
      params: {
        query,
        field,
      },
    };
    const onSuccess = (data) => {
      dispatch({ type: SUCCESS, data });
    };
    const onError = (response) => {
      let msg = "Произошла ошибка при загрузке данных";
      if (response && response.data && response.data.msg)
        msg = response.data.msg;
      dispatch({ type: ERROR, msg });
    };

    await _fetch({ url: searchCustomersUrl, config, onSuccess, onError });
  }

  function openCustomerProfile(id) {
    history.push(customerProfilePath.replace(":customerId", id));
  }

  return (
    <Fragment>
      {isFetching ? (
        <Spinner />
      ) : (
        data && (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center">Username</TableCell>
                <TableCell align="center">Name</TableCell>
                <TableCell align="center">Plan</TableCell>
                <TableCell align="center">Date joined</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((item) => (
                <TableRow
                  style={{ cursor: "pointer" }}
                  onClick={() => openCustomerProfile(item.id)}
                  hover
                  key={item.id}
                >
                  <TableCell align="center">{item.username}</TableCell>
                  <TableCell align="center">
                    {item.first_name} {item.last_name}
                  </TableCell>
                  <TableCell align="center">{item.plan_name}</TableCell>
                  {/*<TableCell align="center">{item.account__plan__name}</TableCell>*/}
                  <TableCell align="center">{item.date_joined}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )
      )}
    </Fragment>
  );
}
