import ElasticIndex from "./ElasticIndex";

const indexNumbers = [...Array(4).keys()];
export default function VodIndexesTable() {
  return (
    <>
      {indexNumbers.map((n) => (
        <ElasticIndex key={n} name={`index${n + 1}`} />
      ))}
    </>
  );
}
