import React, { useContext, useMemo, useReducer } from "react";
import { CustomerContext } from "components/Customer/Page/index";
import formReducer, { UPDATE_FORM } from "reducers/formReducer";
import { getFormData, getFormErrors } from "utils/helpers";
import { customerAddressUrl } from "constants/urls";
import { UPDATE_DATA_FIELD } from "constants/actions";
import Grid from "@mui/material/Grid";
import TextField from "components/common/fields/TextField";
import Button from "@mui/material/Button";
import SelectField from "components/common/fields/SelectField";
import Problem from "components/Customer/Page/Sidebar/LogCallTab/Problem";

export default function LogCall() {
  const [state, dispatch] = useReducer(formReducer, logCallFields);

  async function handleSubmit(event) {
    event.preventDefault();
    const errors = getFormErrors(state);
    if (errors) {
      console.log(errors);
      dispatch({ type: UPDATE_FORM, payload: errors });
    } else {
      let data = getFormData(state);
      console.log(data);
      // const config = {
      //   method: "POST",
      //   data
      // };
      // const now = new Date().toISOString();
      // data = { ...data, created: data.created || now, updated: now };
      // const id = customerContext.customerId;
      // const url = customerAddressUrl.replace(":customerId", id);
      // const onError = () => {
      //   const message = "Failed to update";
      //   messageContext.dispatch({
      //     type: SHOW_MESSAGE,
      //     message,
      //     variant: "error"
      //   });
      // };
      // const onSuccess = () => {
      //   messageContext.dispatch({
      //     type: SHOW_MESSAGE,
      //     message: "Updated successfully",
      //     variant: "success"
      //   });
      //   customerContext.dispatch({
      //     type: UPDATE_DATA_FIELD,
      //     data,
      //     field: "address"
      //   });
      // };
      //
      // await fetch({ url, config, onSuccess, onError });
    }
  }

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <SelectField
          {...state.category}
          dispatch={dispatch}
          options={categoryOptions}
        />
        <Problem
          category={state.category.value}
          dispatch={dispatch}
          problem={state.problem}
        />
        <SelectField
          {...state.solution}
          dispatch={dispatch}
          options={solutionOptions}
        />
        <SelectField
          {...state.device}
          dispatch={dispatch}
          options={devicesOptions}
        />

        <TextField {...state.comments} dispatch={dispatch} />
        <Grid container justify={"center"}>
          <Button variant="contained" type="submit" color="primary">
            Submit
          </Button>
        </Grid>
      </form>
    </div>
  );
}
const categoryOptions = ["Technical", "Financial", "Account"];
const solutionOptions = ["dsf", "cvb"];
const devicesOptions = ["Windows 7 Safari 12.1", "iPad 666 Chrome 80"];

const logCallFields = {
  category: {
    value: "",
    field: "category",
    label: "Category",
    error: false,
    helperText: "Please select category",
  },
  problem: {
    value: "",
    field: "problem",
    label: "Problem",
    error: false,
    helperText: "Please select problem",
  },
  solution: {
    value: "",
    field: "solution",
    label: "Solution",
    error: false,
    helperText: "Please select solution",
  },
  device: {
    value: "",
    field: "device",
    label: "Device",
    error: false,
    helperText: "Please select device",
  },
  comments: {
    value: "",
    field: "comments",
    label: "Comments",
    maxLength: 500,
    blank: true,
    multiline: true,
  },
};
