import { createContext, useContext, useReducer } from "react";

export const indexContext = createContext(null);
export const indexDispatchContext = createContext(null);

export const useIndex = () => useContext(indexContext);
export const useIndexDispatch = () => useContext(indexDispatchContext);

export function IndexProvider({ children }) {
  const [state, dispatch] = useReducer(indexReducer, defaultState);
  return (
    <indexContext.Provider value={state}>
      <indexDispatchContext.Provider value={dispatch}>
        {children}
      </indexDispatchContext.Provider>
    </indexContext.Provider>
  );
}

function indexReducer(state, action) {
  switch (action.type) {
    case "option":
      return {
        ...state,
        [action.option]: action.value,
      };
    default:
      throw Error("Unknown action type");
  }
}

const defaultState = {
  commonFuzziness: false,
  query: "",
  size: 10,
  fuzziness: "2",
  operator: "AND",
};
