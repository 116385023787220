import React from "react";
import TextField from "@mui/material/TextField/TextField";
import { getCreditCardType, isCreditCard, sanitize } from "utils/helpers";
import CardIcons from "./CardIcons";
import { UPDATE_FIELD } from "reducers/formReducer";

export default function CreditCard(props) {
  const { dispatch } = props;
  const [value, setValue] = React.useState(props.value);
  const [type, setType] = React.useState();

  function handleChange(event) {
    setValue(event.target.value);
    const type = getCreditCardType(event.target.value);
    setType(type);
  }

  function handleFocus() {
    dispatch({
      type: UPDATE_FIELD,
      field: props.field,
      payload: { error: false },
    });
  }

  function handleBlur(event) {
    const value = event.target.value;
    const sanitizedValue = sanitize(value, props.pattern);
    setValue(sanitizedValue);
    const isCard = isCreditCard(sanitizedValue);
    if (isCard) {
      dispatch({
        type: UPDATE_FIELD,
        field: props.field,
        payload: { value: sanitizedValue, type },
      });
    } else if (sanitizedValue) {
      dispatch({
        type: UPDATE_FIELD,
        field: props.field,
        payload: { error: true },
      });
    }
  }

  return (
    <div style={{ position: "relative" }}>
      <CardIcons error={props.error} active={type} />
      <TextField
        error={props.error}
        autoComplete={props.autoComplete}
        name={props.name}
        label={props.label}
        fullWidth
        margin="normal"
        variant="outlined"
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        helperText={props.error ? props.helperText : ""}
        onFocus={handleFocus}
        inputProps={{ maxLength: props.maxLength }}
      />
    </div>
  );
}
