import React from "react";
import { useHistory } from "react-router";
import { makeStyles } from "@mui/styles";
import MenuItem from "@mui/material/MenuItem";
import Avatar from "@mui/material/Avatar";
import BaseMenu from "frame/components/BaseMenu";
import { colorFromString, invertColor } from "utils/colors";
import { useDispatch, useSelector } from "react-redux";
import { loginPath } from "constants/paths";
import { logout } from "reducers/userReducer";

const useStyles = makeStyles((theme) => ({
  avatar: (props: StyleProps) => ({
    margin: 0,
    marginRight: theme.spacing(1),
    backgroundColor: `#${props.avatarBackgroundColor}`,
    color: `#${props.avatarColor}`,
  }),
}));

export default function UserMenu() {
  const history = useHistory();
  const dispatch = useDispatch();
  // TODO rewrite redux reducers/state in typescript
  // @ts-ignore
  const user = useSelector((state) => state.user);
  const avatarBackgroundColor = colorFromString(user.initials);
  const avatarColor = invertColor(avatarBackgroundColor);
  const classes = useStyles({ avatarBackgroundColor, avatarColor });

  function handleUserLogout() {
    dispatch(logout());
    history.push(loginPath);
  }

  const icon = <Avatar className={classes.avatar}>{user.initials}</Avatar>;

  return (
    <BaseMenu edge="end" icon={icon}>
      <MenuItem onClick={handleUserLogout}>Logout</MenuItem>
    </BaseMenu>
  );
}

interface StyleProps {
  avatarBackgroundColor: string;
  avatarColor: string;
}
