import React from "react";
import FormGroup from "@mui/material/FormGroup";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import MuiCheckbox from "@mui/material/Checkbox";

export default function CheckboxBase({
  checked,
  handleChange,
  handleClick,
  value,
  label,
  error,
  helperText,
}) {
  return (
    <FormControl error={error}>
      <FormGroup>
        <FormControlLabel
          control={
            <MuiCheckbox
              checked={checked}
              onChange={handleChange}
              onClick={handleClick}
              value={value}
            />
          }
          label={label}
        />
        {error && <FormHelperText>{helperText}</FormHelperText>}
      </FormGroup>
    </FormControl>
  );
}
