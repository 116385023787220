import React from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { alpha } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import NotificationsIcon from "@mui/icons-material/Notifications";
import SearchField from "frame/components/SearchField";
import UserMenu from "frame/components/UserMenu";
import { homePath, callcenterPath } from "constants/paths";
import { adminPath } from "admin/urls";
import Alert from "components/common/Alert";

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: 200,
    },
  },
  toolbar: {
    minHeight: 0,
  },
  link: {
    textDecoration: "none",
    color: "inherit",
  },
  searchContainer: {
    display: "flex",
    justifyContent: "center",
  },
  iconContainer: {
    textAlign: "right",
  },
}));

export default function Frame() {
  const classes = useStyles();
  const history = useHistory();

  return (
    <div className={classes.grow}>
      <AppBar
        position="fixed"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <Toolbar className={classes.toolbar}>
          <Grid container spacing={3} alignItems={"center"}>
            <Grid item xl={3} lg={4} md={5} style={{ display: "flex" }}>
              <Typography variant="h6">
                <Link to={homePath} className={classes.link}>
                  CRM
                </Link>
              </Typography>
              <div style={{ marginLeft: "50px" }}>
                <Button
                  color="inherit"
                  onClick={() => history.push(callcenterPath)}
                >
                  Callcenter
                </Button>
                <Button color="inherit">Tasks</Button>
                {/*<Button color={"inherit"}>Stats</Button>*/}
                <Button color="inherit" onClick={() => history.push(adminPath)}>
                  Admin
                </Button>
              </div>
            </Grid>
            <Grid item xs className={classes.searchContainer}>
              <SearchField />
            </Grid>
            <Grid xs={2} item className={classes.iconContainer}>
              <IconButton
                aria-label="show 17 new notifications"
                color="inherit"
                sx={{ height: 50, width: 50, p: "5px" }}
              >
                <Badge badgeContent={17} color="secondary">
                  <NotificationsIcon />
                </Badge>
              </IconButton>
              <UserMenu />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Alert />
    </div>
  );
}
