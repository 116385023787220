import React, { useEffect } from "react";
import { oldCrmCustomersUrl, customerProfileUrl } from "constants/urls";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import SmsModal from "components/LegacySms/SmsModal";
import { Paper } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useSimpleFetch } from "hooks/useSimpleFetch";

export default function CustomerPhones({ customer }) {
  const { state, fetch } = useSimpleFetch();
  const { isFetching, data } = state;

  function openCustomerProfile() {
    window.open(oldCrmCustomersUrl + customer.id);
  }

  useEffect(() => {
    fetch(customerProfileUrl.replace(":customerId", customer.id));
  }, [customer.id]);

  if (!data) return null;

  return (
    <Paper style={{ padding: 8 }}>
      <Typography style={{ padding: 14 }} variant={"h6"}>
        Phones
      </Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="left">Phone number</TableCell>
            <TableCell align="left">Country code</TableCell>
            <TableCell align="left">Validation status</TableCell>
            <TableCell align="left">Carrier name</TableCell>
            <TableCell align="right">Carrier type</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.phones.map((item, index) => (
            <TableRow
              style={{ cursor: "pointer" }}
              onClick={openCustomerProfile}
              hover
              key={index + "phone"}
            >
              <TableCell align="left">{item.national_format}</TableCell>
              <TableCell align="left">{item.country_code}</TableCell>
              <TableCell align="left">{item.validation_status}</TableCell>
              <TableCell align="left">{item.carrier_name}</TableCell>
              <TableCell align="right">{item.carrier_type}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <SmsModal phones={data.phones} customer={customer} />
    </Paper>
  );
}
