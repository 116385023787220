import {
  FETCHING,
  SUCCESS,
  ERROR,
  SORT,
  UPDATE_DATA_FIELD,
  SET_DATA_FIELD
} from "../constants/actions";

export const simpleInitialState = {
  isFetching: false,
  errorMessage: void 0,
  data: void 0,
  sorting: {
    isAscending: true,
    field: void 0
  }
};

export function simpleReducer(state, action) {
  console.log(action.type);
  switch (action.type) {
    case FETCHING:
      return {
        ...state,
        errorMessage: void 0,
        isFetching: true
      };
    case SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.data
      };
    case SORT:
      return {
        ...state,
        data: action.sorted,
        sorting: {
          isAscending: !state.sorting.isAscending,
          field: action.field
        }
      };
    case ERROR:
      return simpleInitialState;
    case UPDATE_DATA_FIELD:
      return {
        ...state,
        data: {
          ...state.data,
          [action.field]:
            state.data[action.field] instanceof Array
              ? [...state.data[action.field], ...action.data]
              : {
                  ...state.data[action.field],
                  ...action.data
                }
        }
      };
    case SET_DATA_FIELD:
      return {
        ...state,
        data: {
          ...state.data,
          [action.field]: action.data
        }
      };
    default:
      return state;
  }
}
