import "amazon-connect-streams";
import React, { useEffect, useReducer, createContext, useRef } from "react";
import { _fetch } from "hooks/useFetch";
import { oldCrmCustomersUrl, searchCustomersUrl } from "constants/urls";
import { Queue, Agent, Contact, Customer, Callback } from "callcenter/types";
import callcenterReducer, {
  CallCenterState,
  DELETE_CALLBACK,
} from "reducers/callcenterReducer";
import { agents, queues } from "callcenter/mocks";
import {
  SET_AGENT,
  SET_CONTACT,
  SET_CUSTOMERS,
  INIT,
  CALLBACK,
  STATE_CHANGE,
} from "reducers/callcenterReducer";

interface CallCenterContext {
  agent?: connect.Agent;
  contact?: connect.Contact;
  agents: Agent[];
  queues: Queue[];
  customers: Customer[];
  callbacks: Callback[];
  startOutboundCall: (number: string) => void;
  deleteCallback: (phoneNumber: string, date: string) => void;
}

export const CallCenterContext = createContext({} as CallCenterContext);

interface CallCenterProps {
  children: React.ReactNode;
}

const initialState: CallCenterState = {
  agent: void 0,
  contact: void 0,
  agents: [],
  queues: [],
  customers: [],
  callbacks: [],
};

const WEBSOCKET_URL =
  process.env.REACT_APP_WEBSOCKET_URL || "wss://callcenter-api.etvnet.com";
const CCP_URL =
  process.env.REACT_APP_CCP_URL || "https://matvil.my.connect.aws/ccp-v2";

let ws = new WebSocket(WEBSOCKET_URL);

export function CallCenter({ children }: CallCenterProps) {
  const [state, dispatch] = useReducer(callcenterReducer, initialState);
  const { agent, contact, agents, queues, customers, callbacks } = state;
  const isMounted = useRef(false);

  // @ts-ignore
  window.agent = agent;
  // @ts-ignore
  window.contact = contact;

  useEffect(() => {
    const div = document.getElementById("callcenter");
    if (window.connect && div) {
      window.connect.core.initCCP(div, {
        ccpUrl: CCP_URL,
        softphone: { allowFramedSoftphone: true },
        pageOptions: {
          enableAudioDeviceSettings: true,
        },
      });
      window.connect.agent((agent) =>
        dispatch({ type: SET_AGENT, payload: agent })
      );
      window.connect.contact((contact) =>
        dispatch({ type: SET_CONTACT, payload: contact })
      );
    }

    return () => {
      if (window.connect && div) {
        window.connect.core.terminate();
      }
    };
  }, [window.connect]);

  useEffect(() => {
    isMounted.current = true;

    function connect() {
      if (ws.CLOSED) {
        ws = new WebSocket(WEBSOCKET_URL);
      }
      // Get initial list of queues and agents
      ws.onopen = () => {
        let toSend = { action: "sendmessage", message: INIT };
        ws && ws.send(JSON.stringify(toSend));

        toSend = { action: "sendmessage", message: CALLBACK };
        ws && ws.send(JSON.stringify(toSend));
      };
      // Subscribe to changes
      ws.onmessage = ({ data }) => {
        console.log(JSON.parse(data));
        const payload = JSON.parse(data);

        switch (payload?.message) {
          case INIT:
            const { agents, queues } = payload;
            dispatch({
              type: INIT,
              payload: { agents, queues },
            });
            break;
          case STATE_CHANGE: {
            const { agent, queues } = payload;
            dispatch({
              type: STATE_CHANGE,
              payload: { agent, queues },
            });
            break;
          }
          case CALLBACK:
            const { callbacks = [] } = payload;
            dispatch({
              type: CALLBACK,
              payload: { callbacks },
            });
            break;
          default:
            break;
        }
      };

      ws.onclose = () => {
        isMounted.current && setTimeout(connect, 1000);
      };
    }
    connect();

    return () => {
      isMounted.current = false;
      ws && ws.close();
    };
  }, []);

  useEffect(() => {
    if (contact) {
      //Triggers only for Incoming, right before onConnected
      contact.onAccepted(() => {
        console.log("onAccepted");
      });
      contact.onConnecting(() => {
        console.log("onConnecting");
      });
      // Triggers both for Incoming and Outgoing
      contact.onConnected(() => {
        console.log("onConnected");
        getActiveCallPhoneNumber();
      });
      // Triggers 2 times, after End call and after Set To Available
      contact.onEnded(() => {
        console.log("onEnded");
      });
      // Triggers once after Set To Available
      contact.onDestroy(() => {
        console.log("onDestroy");
      });
    }
  }, [contact]);

  // useEffect(() => {
  //   if (customers.length === 1) {
  //     window.open(oldCrmCustomersUrl + customers[0].id);
  //   }
  // }, [customers]);

  async function getActiveCallPhoneNumber() {
    const contact = agent?.getContacts()[0];
    if (contact) {
      // const isInbound = contact.isInbound();
      // const duration = contact.getStatusDuration();
      // const title =
      //   secondsToHms(duration / 1000) + isInbound ? " входящий" : "исходящий";
      // document.title = title;
      const initialConn = contact.getActiveInitialConnection();
      if (initialConn) {
        const endpoint = initialConn.getEndpoint();
        if (endpoint) {
          console.log(endpoint.phoneNumber);

          let phoneNumber = endpoint.phoneNumber.replace("+", "");
          if (phoneNumber.length === 11 && phoneNumber[0] === "1") {
            phoneNumber = phoneNumber.slice(1, 12);
          }

          const config = {
            method: "GET",
            params: {
              query: phoneNumber,
              // query: "4155720794",
              field: "phone",
            },
          };

          const onSuccess = (customers: Customer[]) =>
            dispatch({ type: SET_CUSTOMERS, payload: customers || [] });
          const onError = (data: any) => console.log(data);

          // @ts-ignore
          await _fetch({ url: searchCustomersUrl, config, onSuccess, onError });
        }
      }
    }
  }

  function startOutboundCall(phoneNumber: string) {
    const endpoint = window.connect.Endpoint.byPhoneNumber(phoneNumber);
    if (agent)
      agent.connect(endpoint, {
        failure: (e: any) => console.log(e, "outbound call failed"),
        success: () => console.log("success"),
      });
  }

  function deleteCallback(phoneNumber: string, date: string) {
    const payload = {
      action: "sendmessage",
      message: DELETE_CALLBACK,
      phoneNumber,
      date,
    };
    ws && ws.send(JSON.stringify(payload));
    dispatch({
      type: DELETE_CALLBACK,
      payload: { phoneNumber, date, queue: "" },
    });
  }

  const context: CallCenterContext = {
    agent,
    contact,
    agents,
    queues,
    customers,
    callbacks,
    startOutboundCall,
    deleteCallback,
  };

  return (
    <CallCenterContext.Provider value={context}>
      {children}
    </CallCenterContext.Provider>
  );
}
