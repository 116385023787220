import React, { useEffect, useReducer } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { LIST_CATEGORIES } from "admin/queries";
import { MutationHookOptions } from "@apollo/client/react/types/types";
import ActionButtonsBar from "components/ActionButtonsBar/ActionButtonsBar";
import {
  CreateLiveCategory,
  CreateLiveCategoryVars,
  DeleteLiveCategory,
  DeleteLiveCategoryVars,
  ListLiveCategories,
} from "admin/types/LiveCategory";
import { CREATE_CATEGORY, DELETE_CATEGORY } from "admin/mutations";
import { useHistory, useParams } from "react-router-dom";
import formReducer, {
  FormState,
  UPDATE_FIELD,
  UPDATE_FORM,
} from "reducers/formReducer";
import Grid from "@mui/material/Grid";
import TextField from "components/common/fields/TextField";
import Spinner from "components/common/Spinner";
import { getFormErrors } from "utils/helpers";
import { successAlert } from "components/common/Alert";
import { adminLiveCategoriesPath } from "admin/urls";

export default function LiveCategoryDetails() {
  const history = useHistory();
  const [formState, dispatchForm] = useReducer(formReducer, categoryFields);
  const { categoryId } = useParams();

  const { loading, error, data } =
    useQuery<ListLiveCategories>(LIST_CATEGORIES);
  const [createCategory] = useMutation<
    CreateLiveCategory,
    CreateLiveCategoryVars
  >(CREATE_CATEGORY, { ...createOptions, onCompleted: onCreateCompleted });
  const [deleteCategory] = useMutation<
    DeleteLiveCategory,
    DeleteLiveCategoryVars
  >(DELETE_CATEGORY, { ...deleteOptions, onCompleted: onDeleteCompleted });

  const isNew = categoryId === "new";
  const isPopulated = isNew || formState.id.value !== "";
  const action = isNew ? "Create" : "Save";

  useEffect(() => {
    if (data?.categories) {
      if (isNew) {
        populateNextIdField();
      } else {
        populateFieldValues();
      }
    }
  }, [data]);

  function populateFieldValues() {
    const category = data?.categories.find((c) => c.id === Number(categoryId));
    if (!category) {
      return;
    }
    //deep copying categoryFields to avoid mutations
    const fields = JSON.parse(JSON.stringify(categoryFields));
    fields.id.value = category.id.toString();
    fields.name.value = category.name;
    fields.order.value = category.order.toString();
    fields.color.value = category.color;
    fields.bgColor.value = category.bgColor;
    dispatchForm({ type: UPDATE_FORM, payload: fields });
  }

  function populateNextIdField() {
    const max = data?.categories.reduce((prev, current) =>
      prev.id > current.id ? prev : current
    );
    if (!max) {
      return;
    }
    const field = categoryFields.id;
    field.value = (max.id + 1).toString();
    dispatchForm({ type: UPDATE_FIELD, field: "id", payload: field });
  }

  function create() {
    const errors = getFormErrors(formState);
    if (errors) {
      dispatchForm({ type: UPDATE_FORM, payload: errors });
      return;
    }
    const input = {
      id: Number(formState.id.value),
      name: formState.name.value,
      order: Number(formState.order.value),
      color: formState.color.value,
      bgColor: formState.bgColor.value,
    };
    createCategory({
      variables: {
        input,
      },
    });
  }
  function onCreateCompleted() {
    successAlert(`${action + "d successfully"}`);
    history.replace(adminLiveCategoriesPath);
  }

  function onDeleteCompleted() {
    successAlert("Deleted successfully");
    history.replace(adminLiveCategoriesPath);
  }

  function del() {
    deleteCategory({ variables: { id: Number(categoryId) } });
  }

  if (loading || !isPopulated) return <Spinner />;
  if (error) return <div>Error! ${error.message}</div>;

  return (
    <div>
      <form>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField {...formState.order} dispatch={dispatchForm} />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField {...formState.name} dispatch={dispatchForm} />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField {...formState.color} dispatch={dispatchForm} />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField {...formState.bgColor} dispatch={dispatchForm} />
          </Grid>
          <Grid item xs={12}>
            {formState.id.value && (
              <TextField {...formState.id} dispatch={dispatchForm} />
            )}
          </Grid>
        </Grid>
      </form>
      <ActionButtonsBar
        back={{
          onClick: history.goBack,
        }}
        save={{
          onClick: create,
          label: action,
          body: `Are you sure you want to ${action.toLowerCase()} this category?`,
        }}
        del={{
          onClick: del,
          disabled: isNew,
          body: "Are you sure you want to delete this category?",
        }}
      />
    </div>
  );
}

const createOptions: MutationHookOptions<
  CreateLiveCategory,
  CreateLiveCategoryVars
> = {
  update(cache, { data }) {
    const cached = cache.readQuery<ListLiveCategories>({
      query: LIST_CATEGORIES,
    });
    const categories = cached?.categories;
    const createCategory = data?.createCategory;
    if (categories && createCategory) {
      const updated = categories
        .filter((c) => c.id !== createCategory.id)
        .concat([createCategory]);
      cache.writeQuery({
        query: LIST_CATEGORIES,
        data: { categories: updated },
      });
    }
  },
};

const deleteOptions: MutationHookOptions<
  DeleteLiveCategory,
  DeleteLiveCategoryVars
> = {
  update(cache, { data }) {
    const cached = cache.readQuery<ListLiveCategories>({
      query: LIST_CATEGORIES,
    });
    const categories = cached?.categories;
    const toDelete = data?.deleteCategory;
    categories &&
      toDelete &&
      cache.writeQuery({
        query: LIST_CATEGORIES,
        data: {
          categories: categories.filter((c) => c.id !== toDelete.id),
        },
      });
  },
};

const categoryFields: FormState = {
  id: {
    value: "",
    field: "id",
    label: "Id",
    disabled: true,
  },
  name: {
    value: "",
    field: "name",
    label: "Name",
    maxLength: 255,
    error: false,
    helperText: "Enter name",
  },
  order: {
    value: "",
    field: "order",
    label: "Order",
    pattern: "0-9",
    maxLength: 10,
    error: false,
    helperText: "Enter order",
  },
  color: {
    value: "",
    field: "color",
    label: "Color",
    pattern: "a-z0-9",
    maxLength: 6,
    error: false,
    helperText: "Enter color",
  },
  bgColor: {
    value: "",
    field: "bgColor",
    label: "Background color",
    pattern: "a-z0-9",
    maxLength: 6,
    error: false,
    helperText: "Enter background color",
  },
};
