import React, { useEffect } from "react";
import { createSlice } from "@reduxjs/toolkit";
import { _fetch } from "hooks/useFetch";
import { adminLiveChannelsUrl } from "constants/urls";
import { useDispatch, useSelector } from "react-redux";
import { mockedData } from "components/Admin/Live/Channels/data";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import IconButton from "@mui/material/IconButton";
import PhoneIcon from "@mui/icons-material/Phone";
import DeleteIcon from "@mui/icons-material/Delete";
import { useHistory } from "react-router-dom";
import { adminLiveChannelPath } from "constants/paths";

function Channels() {
  const history = useHistory();
  const dispatch = useDispatch();
  const state = useSelector((state) => state.liveChannels);
  const { isFetching, channels } = state;

  console.log(channels);

  useEffect(() => {
    fetchChannels();
    // dispatch(
    //   fetchingSuccess({
    //     channels: mockedData
    //   })
    // );
  }, []);

  async function fetchChannels() {
    const onSuccess = (channels) => {
      dispatch(
        fetchingSuccess({
          channels,
        })
      );
    };

    await _fetch({ url: adminLiveChannelsUrl, onSuccess });
  }

  const handleClick = (id) => () => {
    history.push(adminLiveChannelPath.replace(":channelId", id));
  };

  return (
    <Paper>
      {channels && (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="left">Name</TableCell>
              <TableCell align="left">Order</TableCell>
              <TableCell align="left">Tz</TableCell>
              <TableCell align="left">Hidden</TableCell>
              <TableCell align="left">Recorded</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {channels.map((c) => (
              <TableRow
                hover
                style={{ cursor: "pointer" }}
                key={c.name}
                onClick={handleClick(c.id)}
              >
                <TableCell align="left">{c.name}</TableCell>
                <TableCell align="left">{c.order}</TableCell>
                <TableCell align="left">{c.tz}</TableCell>
                <TableCell align="left">
                  {c.is_hidden ? "True" : "False"}
                </TableCell>
                <TableCell align="left">{c.recorded_sec}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </Paper>
  );
}

export default Channels;

const initialState = {
  isFetching: true,
  channels: void 0,
};

const liveChannels = createSlice({
  name: "liveChannels",
  initialState,
  reducers: {
    startFetching: (state) => {
      state.isFetching = true;
    },
    fetchingSuccess: (state, { payload }) => {
      const { channels } = payload;
      state.channels = channels;
      state.isFetching = false;
    },
    fetchingError: (state) => {
      state.isFetching = false;
    },
  },
});

const { startFetching, fetchingSuccess, fetchingError } = liveChannels.actions;

export const liveChannelsReducer = liveChannels.reducer;
