import React, { useContext } from "react";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Typography from "@mui/material/Typography";
import {
  CallCenter as CallCenterHOC,
  CallCenterContext,
} from "callcenter/components/CallCenter";
import { oldCrmCustomersUrl } from "constants/urls";
import { CallCenterDrawer } from "callcenter/components/Drawer";
import { Queues } from "callcenter/components/Queues";
import { DateTime } from "luxon";
import IconButton from "@mui/material/IconButton";
import PhoneIcon from "@mui/icons-material/Phone";
import DeleteIcon from "@mui/icons-material/Delete";
import { Callback } from "callcenter/types";

export default function CallCenter() {
  return (
    <CallCenterHOC>
      <div style={{ display: "flex" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            overflow: "auto",
            flexGrow: 1,
            gap: 16,
          }}
        >
          {/* COMMENTED FOR NOW */}
          {/* <Queues /> */}
          <CustomersTable />
          <CallbackTable />
        </div>
        <CallCenterDrawer />
      </div>
    </CallCenterHOC>
  );
}

function CustomersTable() {
  const { customers } = useContext(CallCenterContext);
  return (
    <div style={{ marginTop: 30 }}>
      <Typography variant="h5">Active call details</Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="center">Username</TableCell>
            <TableCell align="center">Email</TableCell>
            <TableCell align="center">Name</TableCell>
            <TableCell align="center">Plan</TableCell>
            <TableCell align="center">Date joined</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {customers.map((c) => (
            <TableRow
              style={{ cursor: "pointer" }}
              onClick={() => window.open(oldCrmCustomersUrl + c.id)}
              hover
              key={c.id}
            >
              <TableCell align="center">{c.username}</TableCell>
              <TableCell align="center">{c.email}</TableCell>
              <TableCell align="center">
                {c.first_name} {c.last_name}
              </TableCell>
              <TableCell align="center">{c.plan_name}</TableCell>
              <TableCell align="center">{c.date_joined}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}

function CallbackTable() {
  const { callbacks, startOutboundCall, deleteCallback } =
    useContext(CallCenterContext);

  const onClick = (c: Callback) => () => {
    window.open(`/customers/search/${c.phoneNumber}?field=phone`);
  };

  return (
    <div style={{ marginTop: 90 }}>
      <Typography variant="h5">Callback list</Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="left">Phone</TableCell>
            <TableCell align="left">Date</TableCell>
            <TableCell align="left">Queue</TableCell>
            <TableCell align="right">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {callbacks.map((c) => (
            <TableRow style={{ cursor: "pointer" }} hover key={c.date}>
              <TableCell onClick={onClick(c)} align="left">
                {c.phoneNumber}
              </TableCell>
              <TableCell onClick={onClick(c)} align="left">
                {DateTime.fromISO(c.date).toISO()}
              </TableCell>
              <TableCell onClick={onClick(c)} align="left">
                {c.queue}
              </TableCell>
              <TableCell align="right">
                <IconButton onClick={() => startOutboundCall(c.phoneNumber)}>
                  <PhoneIcon />
                </IconButton>
                <IconButton
                  onClick={() => deleteCallback(c.phoneNumber, c.date)}
                >
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}
