import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

export default function Row({ label, value }) {
  return (
    <Grid container>
      <Grid item lg={2}>
        <Typography align={"right"} variant="body1">
          <strong>{label}:</strong>
        </Typography>
      </Grid>
      <Grid item style={{ paddingLeft: 16 }}>
        <Typography variant="body1">{value}</Typography>
      </Grid>
    </Grid>
  );
}
