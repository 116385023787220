import React from "react";
import { makeStyles } from "@mui/styles";
import IconButton from "@mui/material/IconButton";

const useStyles = makeStyles((theme) => ({
  iconButton: {
    "&:hover": {
      backgroundColor: "transparent",
    },
    padding: 0,
  },
}));

export default function HoverlessIconButton({ children, onClick }) {
  const classes = useStyles();

  return (
    <IconButton onClick={onClick} className={classes.iconButton}>
      {children}
    </IconButton>
  );
}
