import { ApolloClient, HttpLink, InMemoryCache } from "@apollo/client";
import { tasksGraphQLDomain } from "constants/urls";

const tasksApolloClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: new HttpLink({
    uri: tasksGraphQLDomain,
  }),
});

export default tasksApolloClient;
