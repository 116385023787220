import React from "react";
import LuxonUtils from "@date-io/luxon";
import { LocalizationProvider, DatePicker as MuiDatePicker } from "@mui/lab";

export default function DateSelector({ date, handleChange, label }) {
  return (
    <LocalizationProvider dateAdapter={LuxonUtils}>
      <MuiDatePicker
        autoOk
        value={date}
        onChange={handleChange}
        label={label}
        format="yyyy-MM-dd"
        disableFuture
        disableToolbar
        variant="inline"
      />
    </LocalizationProvider>
  );
}
