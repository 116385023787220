import React, { useState, ChangeEvent, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  adminLiveCategoriesPath,
  adminLiveTimezonesPath,
  adminUsersStaffPath,
  adminUsersGroupsPath,
  adminVodIndexesPath,
} from "admin/urls";
import { urls, urlType, Expansion } from "admin/types/TreeNav";
import TreeView from "@mui/lab/TreeView";
import TreeItem from "@mui/lab/TreeItem";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Typography } from "@mui/material";

export default function TreeNav() {
  const history = useHistory();
  const location = useLocation();

  const [expanded, setExpanded] = useState([] as string[]);
  const [selected, setSelected] = useState("");

  // auto-select current url in nav
  useEffect(() => {
    const matchUrl = urls.find((u) => location.pathname.includes(u));
    if (matchUrl) {
      setSelected(urlExpansions[matchUrl].selected);
      setExpanded(urlExpansions[matchUrl].expanded);
    } else {
      setSelected("");
      setExpanded([]);
    }
  }, [location]);

  const handleToggle = (event: ChangeEvent<{}>, nodeIds: string[]) => {
    setExpanded(nodeIds);
  };

  const handleSelect = (event: ChangeEvent<{}>, nodeId: string) => {
    if (urlMappings[nodeId]) {
      history.push(urlMappings[nodeId]);
    }
    setSelected(nodeId);
  };

  const TreeLeaf = ({ node }: { node: string }) => (
    <TreeItem
      nodeId={node}
      label={<Typography variant="button">{node}</Typography>}
    />
  );

  return (
    <TreeView
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
      expanded={expanded}
      selected={selected}
      onNodeToggle={handleToggle}
      onNodeSelect={handleSelect}
    >
      <TreeItem
        nodeId="Admin"
        label={<Typography variant="button">Admin</Typography>}
      >
        <TreeItem
          nodeId="Live"
          label={<Typography variant="button">Live</Typography>}
        >
          <TreeLeaf node={"Channels"} />
          <TreeLeaf node={"Categories"} />
          <TreeLeaf node={"Timezones"} />
          <TreeLeaf node={"Bitrates"} />
        </TreeItem>
        <TreeItem
          nodeId="Users"
          label={<Typography variant="button">Users</Typography>}
        >
          <TreeLeaf node={"Staff"} />
          <TreeLeaf node={"Groups"} />
        </TreeItem>
        <TreeItem
          nodeId="Vod"
          label={<Typography variant="button">Vod</Typography>}
        >
          <TreeLeaf node={"Indexes"} />
        </TreeItem>
      </TreeItem>
    </TreeView>
  );
}

// import urls in types/TreeNav as well
const urlMappings: Record<string, urlType> = {
  Categories: adminLiveCategoriesPath,
  Timezones: adminLiveTimezonesPath,
  Staff: adminUsersStaffPath,
  Groups: adminUsersGroupsPath,
  Indexes: adminVodIndexesPath,
};

const urlExpansions: Expansion = {
  [adminLiveCategoriesPath]: {
    selected: "Categories",
    expanded: ["Admin", "Live"],
  },
  [adminLiveTimezonesPath]: {
    selected: "Timezones",
    expanded: ["Admin", "Live"],
  },
  [adminUsersStaffPath]: {
    selected: "Staff",
    expanded: ["Admin", "Users"],
  },
  [adminUsersGroupsPath]: {
    selected: "Groups",
    expanded: ["Admin", "Users"],
  },
  [adminVodIndexesPath]: {
    selected: "Indexes",
    expanded: ["Admin", "Vod"],
  },
};
