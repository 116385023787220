import { DateTime } from "luxon";

export function isFunc(f) {
  return typeof f === "function";
}

export const sort = (field, asc, type) => (a, b) => {
  let fieldA = a[field];
  let fieldB = b[field];
  if (type === "Number") {
    fieldA = Number(fieldA);
    fieldB = Number(fieldB);
  } else if (type === "Date") {
    fieldA = new Date(fieldA);
    fieldB = new Date(fieldB);
  }
  return asc ? fieldA < fieldB : fieldA > fieldB;
};

export function createQueryString(obj) {
  let str = [];
  for (let p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
  return str.join("&");
}

export function sanitize(str, pattern, maxLength) {
  let sanitized = str.replace(new RegExp(`[^${pattern}]+`, "g"), "");
  sanitized = sanitized.trim();
  return maxLength ? sanitized.slice(0, maxLength) : sanitized;
}

export function getFormErrors(form) {
  let withErrors = void 0;

  const isCheckboxValid = (field) =>
    field.type === "checkbox" && field.required && !field.checked;

  for (let key in form) {
    if (form.hasOwnProperty(key)) {
      const field = form[key];
      const isFieldValid = field.value || field.blank;
      if (!isFieldValid || isCheckboxValid(field)) {
        withErrors = {
          ...withErrors,
          [key]: {
            ...field,
            error: true,
          },
        };
      }
    }
  }
  return withErrors;
}

export function getFormData(state) {
  let payload = {};
  for (let key in state) {
    if (state.hasOwnProperty(key)) {
      const field = state[key];
      if (field.type === "checkbox") {
        payload[key] = field.checked;
      } else if (field.value) {
        payload[key] = field.value;
      }
    }
  }
  return payload;
}

export function isEmail(email) {
  const re = /\S+@\S+\.\S+/;
  return re.test(email);
}

export function getCreditCardType(creditCardNumber) {
  const amex = /^3[47]/;
  const visa = /^4/;
  const masterCard = /^5[1-5]/;

  if (amex.test(creditCardNumber)) {
    return "amex";
  }
  if (visa.test(creditCardNumber)) {
    return "visa";
  }
  if (masterCard.test(creditCardNumber)) {
    return "masterCard";
  }
  return null;
}

export function getCreditCardTypeInt(creditCardType) {
  if (!creditCardType) return 0;
  switch (creditCardType.toLowerCase()) {
    case "amex":
      return 1;
    case "visa":
      return 2;
    case "mastercard":
      return 3;
    default:
      return 0;
  }
}

export function getCreditCardIconUrl(creditCardType) {
  switch (creditCardType) {
    case 1:
      return process.env.PUBLIC_URL + "/amex_icon.png";
    case 2:
      return process.env.PUBLIC_URL + "/visa_icon.png";
    case 3:
      return process.env.PUBLIC_URL + "/mcard_icon.png";
    default:
      return "";
  }
}

export function getCreditCardTypeString(creditCardType) {
  switch (creditCardType) {
    case 1:
      return "Amex";
    case 2:
      return "Visa";
    case 3:
      return "Mastercard";
  }
  return null;
}

export function isCreditCard(str) {
  const creditCard = /^(?:4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14}|(222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[0-9]{12}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11}|6[27][0-9]{14})$/;
  const sanitized = str.replace(/[- ]+/g, "");
  if (!creditCard.test(sanitized)) {
    return false;
  }
  let sum = 0;
  let digit;
  let tmpNum;
  let shouldDouble;
  for (let i = sanitized.length - 1; i >= 0; i--) {
    digit = sanitized.substring(i, i + 1);
    tmpNum = parseInt(digit, 10);
    if (shouldDouble) {
      tmpNum *= 2;
      if (tmpNum >= 10) {
        sum += (tmpNum % 10) + 1;
      } else {
        sum += tmpNum;
      }
    } else {
      sum += tmpNum;
    }
    shouldDouble = !shouldDouble;
  }
  return !!(sum % 10 === 0 ? sanitized : false);
}

export function range(size, startAt = 0) {
  return [...Array(size).keys()].map((i) => i + startAt);
}

export function formatISODate(date) {
  return DateTime.fromISO(date).toFormat("d MMM y");
}

// ISO 3166-1 alpha-2
// ⚠️ No support for IE 11
export function countryToFlag(isoCode) {
  return typeof String.fromCodePoint !== "undefined"
    ? isoCode
        .toUpperCase()
        .replace(/./g, (char) =>
          String.fromCodePoint(char.charCodeAt(0) + 127397)
        )
    : isoCode;
}
