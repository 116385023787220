import React, { Fragment, useContext, useState } from "react";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import EditIcon from "@mui/icons-material/Edit";
import ErrorIcon from "@mui/icons-material/Error";
import HoverlessIconButton from "components/common/HoverlessIconButton";
import { countryToFlag, formatISODate } from "utils/helpers";
import Row from "components/Customer/Page/Main/ProfileTab/Row";
import { CustomerContext } from "customers/components/CustomerInfo";
import CustomerAccountForm from "customers/components/CustomerAccountForm";
// import CustomerPhoneDetails from "customers/components/CustomerPhoneDetails";
import CustomerPhones from "customers/components/CustomerPhones/CustomerPhones";

export default function Account() {
  const [edit, setEdit] = useState(false);
  const customer = useContext(CustomerContext);

  function openEdit() {
    setEdit(true);
  }

  function closeEdit() {
    setEdit(false);
  }

  if (!customer) return null;

  const email = (
    <span style={{ display: "flex", alignItems: "center" }}>
      {customer.email}
      {customer.emailConfirmed ? (
        <VerifiedUserIcon fontSize={"inherit"} style={{ color: "green" }} />
      ) : (
        <ErrorIcon fontSize={"inherit"} style={{ color: "red" }} />
      )}
    </span>
  );
  const fullName = (
    <Fragment>
      {customer.firstName} {customer.lastName}
    </Fragment>
  );

  const ip = (
    <Fragment>
      {customer.registrationIp} {countryToFlag(customer.registrationCountry)}
    </Fragment>
  );

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h5">Account</Typography>
        <HoverlessIconButton onClick={openEdit}>
          <EditIcon />
        </HoverlessIconButton>
      </div>
      <Divider />

      {edit ? (
        <CustomerAccountForm customer={customer} closeEdit={closeEdit} />
      ) : (
        <div style={{ padding: "2%" }}>
          <Row label={"Full name"} value={fullName} />
          <Row label={"Email"} value={email} />
          <Row label={"Username"} value={customer.username} />
          <Row
            label={"Registered"}
            value={formatISODate(customer.dateJoined)}
          />
          <Row label={"IP"} value={ip} />
          <Row label={"Last login"} value={formatISODate(customer.lastLogin)} />
        </div>
      )}

      {/* <CustomerPhoneDetails /> */}
      <CustomerPhones customer={customer} />
    </div>
  );
}
