import React, { useContext, useMemo, useReducer } from "react";
import formReducer, { UPDATE_FORM } from "reducers/formReducer";
import { getFormData, getFormErrors } from "utils/helpers";
import TextField from "components/common/fields/TextField";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import EmailField from "components/common/fields/EmailField";
import Checkbox from "components/common/fields/Checkbox";
import { UPDATE_DATA_FIELD } from "constants/actions";
import { CustomerContext } from "components/Customer/Page/index";
import { customerProfileUrl } from "constants/urls";
import { useDispatch } from "react-redux";
import { showAlert } from "components/common/Alert";
import { _fetch } from "hooks/useFetch";

export default function AccountForm({ closeEdit }) {
  const dispatch = useDispatch();
  const customerContext = useContext(CustomerContext);
  const { user } = customerContext.data;
  const fields = useMemo(populateValues, []);
  const [state, dispatchForm] = useReducer(formReducer, fields);

  function populateValues() {
    const fields = { ...accountFields };
    fields.first_name.value = user.first_name;
    fields.last_name.value = user.last_name;
    fields.username.value = user.username;
    fields.email.value = user.email;
    fields.email_confirmed.checked = user.email_confirmed;
    return fields;
  }

  async function handleSubmit(event) {
    event.preventDefault();
    const errors = getFormErrors(state);
    if (errors) {
      console.log(errors);
      dispatchForm({ type: UPDATE_FORM, payload: errors });
    } else {
      const data = getFormData(state);
      const config = {
        method: "POST",
        data,
      };

      const id = customerContext.customerId;
      const url = customerProfileUrl.replace(":customerId", id);
      const onError = () => {
        dispatch(
          showAlert({
            message: "Failed to update",
            severity: "error",
          })
        );
      };
      const onSuccess = () => {
        dispatch(
          showAlert({
            message: "Updated successfully",
            severity: "success",
          })
        );
        customerContext.dispatch({
          type: UPDATE_DATA_FIELD,
          data,
          field: "user",
        });
      };
      await _fetch({ url, config, onSuccess, onError });
      closeEdit();
    }
  }

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField {...state.first_name} dispatch={dispatchForm} />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField {...state.last_name} dispatch={dispatchForm} />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField {...state.username} dispatch={dispatchForm} />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField {...state.password} dispatch={dispatchForm} />
          </Grid>
        </Grid>
        <Grid container spacing={2} alignItems={"center"}>
          <Grid item xs={12} md={6}>
            <EmailField {...state.email} dispatch={dispatchForm} />
          </Grid>
          <Grid item xs={12} md={6}>
            <Checkbox {...state.email_confirmed} dispatch={dispatchForm} />
          </Grid>
        </Grid>
        <TextField {...state.comments} dispatch={dispatchForm} />
        <Grid container justify={"center"}>
          <Button variant="contained" type="submit" color="primary">
            Update
          </Button>
          <Button
            style={{ marginLeft: 10 }}
            onClick={closeEdit}
            color="primary"
          >
            Cancel
          </Button>
        </Grid>
      </form>
    </div>
  );
}

const accountFields = {
  first_name: {
    value: "",
    field: "first_name",
    label: "First name",
    maxLength: 30,
    error: false,
    helperText: "Please enter first name",
  },
  last_name: {
    value: "",
    field: "last_name",
    label: "Last name",
    maxLength: 30,
    error: false,
    helperText: "Please enter last name",
  },
  email: {
    value: "",
    field: "email",
    label: "Email",
    pattern: "^\\S+@\\S+$",
    error: false,
    helperText: "Please enter email",
  },
  username: {
    value: "",
    field: "username",
    label: "Username",
    pattern: "a-zA-Z@.+-_",
    maxLength: 254,
    error: false,
    helperText: "Please enter username",
  },
  password: {
    value: "******",
    field: "password",
    label: "Password",
    error: false,
    helperText: "Please enter password",
  },
  email_confirmed: {
    field: "email_confirmed",
    checked: false,
    label: "Email confirmed",
    value: "Email confirmed",
    error: false,
    type: "checkbox",
  },
  comments: {
    value: "",
    field: "comments",
    label: "Comments",
    maxLength: 500,
    blank: true,
    multiline: true,
  },
};
