import React, { Fragment, useState } from "react";
import { makeStyles } from "@mui/styles";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import Backdrop from "@mui/material/Backdrop";
import Spinner from "components/common/Spinner";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenModal({
  children,
  open,
  handleClose,
  title,
}) {
  const classes = useStyles();
  // It takes a while to render children. This help to render Modal max fast
  const [entered, setEntered] = useState(false);

  return (
    <Fragment>
      <Dialog
        onExited={() => setEntered(false)}
        onEntered={() => setEntered(true)}
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <Typography variant="h6" className={classes.title}>
              {title}
            </Typography>
            <IconButton
              edge="end"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        {entered ? children : <Spinner />}
      </Dialog>
    </Fragment>
  );
}
