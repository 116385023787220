import React, { useEffect, useMemo, useState, useContext } from "react";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import { txsListUrl } from "constants/urls";
import { useSimpleFetch } from "hooks/useSimpleFetch";
import Spinner from "components/common/Spinner";
import { Paper } from "@mui/material";
import Typography from "@mui/material/Typography";
import { countryToFlag } from "utils/helpers";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { DateTime } from "luxon";
import TxRefundModal from "customers/components/TxRefundModal/TxRefundModal";
import { CustomerContext } from "customers/components/CustomerInfo/CustomerInfo";

const staticUrl = "//static.etvnet.com/crm/images/cc";

export function CustomerTxs() {
  const customer = useContext(CustomerContext);
  const { state, fetch, dispatch } = useSimpleFetch();
  const { isFetching, data } = state;
  const [anchorEl, setAnchorEl] = useState(null);
  const [tx, setTx] = useState(null);
  const [open, setOpen] = useState(false);
  const [txType, setTxType] = useState("refund");

  const handleModalOpen = (txType) => () => {
    setTxType(txType);
    setOpen(true);
    setAnchorEl(null);
  };

  function handleModalClose() {
    setOpen(false);
  }

  const handleClick = (tx) => (event) => {
    setTx(tx);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setTx(null);
    setAnchorEl(null);
  };

  useEffect(() => {
    fetch({ url: txsListUrl.replace(":customerId", customer.id) });
  }, []);

  function getFlag(tx) {
    if (!tx.merchant) {
      return "";
    }
    if (tx.currency === "USD") {
      return countryToFlag("US");
    } else if (tx.currency === "CAD") {
      return countryToFlag("CA");
    }
    return "";
  }

  function getCreditCardImg(type) {
    switch (type) {
      case 1:
        return `${staticUrl}/american-express.png`;
      case 2:
        return `${staticUrl}/visa.png`;
      case 3:
        return `${staticUrl}/master-card.png`;
      case 4:
        return `${staticUrl}/discover.png`;
      default:
        return "";
    }
  }

  function getColor(tx) {
    if (tx.type === "Credit Card Return") {
      return "#ff9800";
    }
    if (
      tx.type?.search("Credit Card") >= 0 &&
      tx.memo?.search("Approved") >= 0
    ) {
      return "#4caf50";
    }
    if (tx.memo?.search("eclined") >= 0 || tx.memo?.search("expired") >= 0) {
      return "#f44336";
    }
    return "#1976d2";
  }

  function canRefund(tx) {
    const types = ["Credit Card", "Recurring Credit Card"];
    return types.includes(tx?.type) && tx?.status === "Complete";
  }

  function canVoid(tx) {
    const created = DateTime.fromISO(tx?.created).toISODate();
    const now = DateTime.local().toISODate();
    return canRefund(tx) && now === created;
  }

  const paper = useMemo(
    () =>
      data && (
        <Paper style={{ padding: 8 }}>
          <Typography style={{ padding: 14 }} variant={"h6"}>
            Transactions
          </Typography>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell align="center">Date</TableCell>
                <TableCell align="center">Type</TableCell>
                <TableCell align="center">Description</TableCell>
                <TableCell align="center">Amount</TableCell>
                <TableCell align="center">Merchant</TableCell>
                <TableCell align="center">Credit card</TableCell>
                <TableCell align="center" />
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((tx, index) => (
                <TableRow key={index + "tx"}>
                  <TableCell align="center">
                    {tx.created.replace("T", " ")}
                  </TableCell>
                  <TableCell align="center">{tx.type}</TableCell>
                  <TableCell align="center">
                    {tx.type === "Credit Card Return"
                      ? tx.comments
                      : `${tx.memo} ${tx.reference_num}`}
                  </TableCell>
                  <TableCell
                    style={{ width: "15%", color: getColor(tx) }}
                    align="center"
                  >
                    ${tx.amount.toFixed(2)} {tx.currency}
                  </TableCell>
                  <TableCell style={{ width: "15%" }} align="center">
                    {getFlag(tx)} {tx.merchant}
                  </TableCell>
                  <TableCell style={{ width: "10%" }} align="center">
                    <div
                      style={{
                        width: "10%",
                        display: "flex",
                      }}
                    >
                      <div>
                        <img
                          width={"24px"}
                          src={getCreditCardImg(tx.cc_type)}
                          alt=""
                        />
                      </div>
                      <div>{tx.cc_safe_number && `*${tx.cc_safe_number}`}</div>
                    </div>
                  </TableCell>
                  <TableCell align="center" style={{ width: "2%" }}>
                    <IconButton onClick={handleClick(tx)}>
                      <MoreVertIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      ),
    [data]
  );

  return (
    <div style={{ marginTop: 30 }}>
      {isFetching ? <Spinner /> : paper}
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem disabled={!canRefund(tx)} onClick={handleModalOpen("refund")}>
          Refund
        </MenuItem>
        <MenuItem disabled={!canVoid(tx)} onClick={handleModalOpen("void")}>
          Void
        </MenuItem>
      </Menu>
      {tx && (
        <TxRefundModal
          dispatch={dispatch}
          tx={tx}
          txType={txType}
          customer={customer}
          open={open}
          close={handleModalClose}
        />
      )}
    </div>
  );
}
