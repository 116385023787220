import React from "react";
import RecentlyViewedCustomers from "./components/RecentlyViewedCustomers";

export default function Home() {
  return (
    <div style={{ paddingTop: 16 }}>
      <div style={{ marginBottom: 16 }}>welcome to CRM2</div>
      <RecentlyViewedCustomers />
    </div>
  );
}
