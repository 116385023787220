import { configureStore } from "@reduxjs/toolkit";
import { alertReducer } from "components/common/Alert";
import { initUser, userReducer } from "reducers/userReducer";
import { liveChannelsReducer } from "components/Admin/Live/Channels";
import { liveChannelReducer } from "components/Admin/Live/Channels/Channel";
import { billingReducer } from "components/Customer/Page/Main/BillingTab/Account";
import { plansReducer } from "components/Customer/Page/Main/BillingTab/AccountForm";

const store = configureStore({
  reducer: {
    alert: alertReducer,
    user: userReducer,
    billing: billingReducer,
    plans: plansReducer,
    liveChannels: liveChannelsReducer,
    liveChannel: liveChannelReducer,
  },
  devTools: true,
  preloadedState: { user: initUser() },
});

export default store;
