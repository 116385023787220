import axios from "axios";

export async function fetchIndex({
  query,
  size,
  fuzziness,
  operator,
  index,
  cb,
  error,
}) {
  try {
    const {
      data: {
        duration,
        items: { hits },
      },
    } = await axios.post(
      "https://api.etvnet.com/apis/vod/v1/search",
      {
        size,
        query,
        fuzziness,
        operator,
        index,
      },
      { headers: { "Content-Type": "application/json" } }
    );
    cb({ hits, duration });
  } catch (e) {
    error(e);
    console.error(e);
  }
}
