import React, { useEffect, useMemo, useReducer, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { createSlice } from "@reduxjs/toolkit";
import { _fetch } from "hooks/useFetch";
import {
  adminBitratesUrl,
  adminChannelScheduleUrl,
  adminLiveCategoriesUrl,
  adminLiveChannelsUrl,
  adminLiveChannelUrl,
  adminTimezonesUrl,
  liveChannelFiltersUrl,
} from "constants/urls";
import { mockedChannel } from "components/Admin/Live/Channels/data";
import formReducer, { UPDATE_FIELD, UPDATE_FORM } from "reducers/formReducer";
import Grid from "@mui/material/Grid";
import TextField from "components/common/fields/TextField";
import SelectField from "components/common/fields/SelectField";
import ChannelFilters from "components/Admin/Live/Channels/Filters/ChannelFilters";
import { getFormData, getFormErrors } from "utils/helpers";
import Button from "@mui/material/Button";

function Channel() {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.liveChannel);
  const {
    isFetching,
    channel,
    categories,
    categoryOptions,
    tzOptions,
    bitrates,
    scheduleOptions,
    timezones,
    schedule,
  } = state;
  const { channelId } = useParams();
  const history = useHistory();
  const [formState, dispatchForm] = useReducer(formReducer);

  function populateValues(channel) {
    const fields = { ...channelFields };
    fields.name.value = channel.name;
    fields.order.value = channel.order;
    fields.id.value = channel.id;
    fields.slug.value = channel.slug;
    fields.cdn_id.value = channel.cdn_id;
    fields.recorded_sec.value = channel.recorded_sec;
    fields.bg_color.value = channel.bg_color;
    fields.keywords.value = channel.keywords;
    fields.v5_icon.value = channel.v5_icon;
    fields.v4_icon.value = channel.v4_icon;
    fields.v3_icon.value = channel.v3_icon;
    fields.filters = channel.filters;
    dispatchForm({ type: UPDATE_FORM, payload: fields });
  }

  useEffect(() => {
    // fetchChannel();
    dispatch(
      fetchingSuccess({
        channel: mockedChannel,
      })
    );
    populateValues(mockedChannel);

    return () => {
      dispatch(reset());
    };
  }, []);

  useEffect(() => {
    if (channel && !categories) {
      fetchCategories();
      fetchTimezones();
      fetchBitrates();
      fetchSchedule();
      fetchFilters();
    }
  }, [channel, categories]);

  async function fetchChannel() {
    const onSuccess = (channel) => {
      dispatch(
        fetchingSuccess({
          channel,
        })
      );
      populateValues(channel);
    };
    const url = adminLiveChannelUrl.replace(":channelId", channelId);
    await _fetch({ url, onSuccess });
  }

  async function fetchCategories() {
    const onSuccess = (categories) => {
      const categoryOptions = categories.map((c) => c.name);
      const activeCategory = categories.find(
        (c) => c.sk == channel.category
      ).name;
      dispatch(
        fetchingCategoriesSuccess({
          categories,
          categoryOptions,
        })
      );
      dispatchForm({
        type: UPDATE_FIELD,
        field: "category",
        payload: { value: activeCategory },
      });
    };
    await _fetch({ url: adminLiveCategoriesUrl, onSuccess });
  }

  async function fetchTimezones() {
    const onSuccess = (timezones) => {
      const tzOptions = timezones.map((c) => c.city);
      const activeTz = timezones.find((c) => c.sk === channel.tz).city;
      dispatch(
        fetchingTimezonesSuccess({
          timezones,
          tzOptions,
        })
      );
      dispatchForm({
        type: UPDATE_FIELD,
        field: "tz",
        payload: { value: activeTz },
      });
    };
    await _fetch({ url: adminTimezonesUrl, onSuccess });
  }

  // TODO try createAsyncThunk
  async function fetchBitrates() {
    const onSuccess = (bitrates) => {
      dispatch(
        fetchingBitratesSuccess({
          bitrates,
        })
      );
      dispatchForm({
        type: UPDATE_FIELD,
        field: "bitrates",
        payload: { value: channel.bitrates },
      });
    };
    await _fetch({ url: adminBitratesUrl, onSuccess });
  }

  async function fetchSchedule() {
    const onSuccess = (schedule) => {
      const scheduleOptions = schedule.map((s) => s.channel_name);
      const activeSchedule = schedule.find(
        (s) => s.provider === channel.schedule
      ).channel_name;
      dispatch(
        fetchingScheduleSuccess({
          schedule,
          scheduleOptions,
        })
      );
      dispatchForm({
        type: UPDATE_FIELD,
        field: "schedule",
        payload: { value: activeSchedule },
      });
    };
    await _fetch({ url: adminChannelScheduleUrl, onSuccess });
  }

  async function fetchFilters() {
    const onSuccess = (data) => {
      const { plans, devices, cm_groups, countries } = data;
      dispatch(
        fetchingFiltersSuccess({
          plans,
          devices,
          cm_groups,
          countries,
        })
      );
    };
    await _fetch({ url: liveChannelFiltersUrl, onSuccess });
  }

  async function handleSubmit(e) {
    e.preventDefault();
    // const errors = getFormErrors(formState);
    // delete errors.filters;
    // if (errors || errors !== {}) {
    //   console.log(errors);
    //   dispatchForm({type: UPDATE_FORM, payload: errors});
    // }
    // else {
    let data = getFormData(formState);
    const category = Number(
      categories.find((c) => c.name === data.category).sk
    );
    const tz = timezones.find((t) => t.city === data.tz).sk;
    const scheduleProvider = schedule.find(
      (s) => s.channel_name === data.schedule
    ).provider;

    data = {
      ...data,
      filters: formState.filters,
      category,
      tz,
      schedule: scheduleProvider,
    };
    const config = {
      method: "POST",
      data: { ...channel, ...data },
    };
    console.log(data);
    // await _fetch({ url: adminLiveChannelsUrl, config });
    // }
  }

  return (
    <div>
      {formState && (
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField {...formState.id} dispatch={dispatchForm} />
            </Grid>
            <Grid item xs={12}>
              <TextField {...formState.name} dispatch={dispatchForm} />
            </Grid>
            <Grid item xs={12}>
              <TextField {...formState.slug} dispatch={dispatchForm} />
            </Grid>
            <Grid item xs={12}>
              <TextField {...formState.v5_icon} dispatch={dispatchForm} />
            </Grid>
            <Grid item xs={12}>
              <TextField {...formState.v4_icon} dispatch={dispatchForm} />
            </Grid>
            <Grid item xs={12}>
              <TextField {...formState.v3_icon} dispatch={dispatchForm} />
            </Grid>
            <Grid item xs={12}>
              <TextField {...formState.order} dispatch={dispatchForm} />
            </Grid>
            <Grid item xs={12}>
              <TextField {...formState.recorded_sec} dispatch={dispatchForm} />
            </Grid>
            <Grid item xs={12}>
              <TextField {...formState.cdn_id} dispatch={dispatchForm} />
            </Grid>
            <Grid item xs={12}>
              <TextField {...formState.keywords} dispatch={dispatchForm} />
            </Grid>
            <Grid item xs={12}>
              <TextField {...formState.bg_color} dispatch={dispatchForm} />
            </Grid>
            <Grid item xs={12}>
              <SelectField
                {...formState.bitrates}
                dispatch={dispatchForm}
                options={bitrates}
                multiple={true}
              />
            </Grid>
            <Grid item xs={12}>
              <SelectField
                {...formState.category}
                dispatch={dispatchForm}
                disabled={categoryOptions.length === 0}
                options={categoryOptions}
              />
            </Grid>
            <Grid item xs={12}>
              <SelectField
                {...formState.tz}
                dispatch={dispatchForm}
                disabled={tzOptions.length === 0}
                options={tzOptions}
              />
            </Grid>
            <Grid item xs={12}>
              <SelectField
                {...formState.schedule}
                dispatch={dispatchForm}
                disabled={scheduleOptions.length === 0}
                options={scheduleOptions}
              />
            </Grid>
            <ChannelFilters
              {...state}
              filters={formState.filters}
              dispatch={dispatchForm}
            />
          </Grid>
          <Grid container justify={"center"}>
            <Button
              onClick={() => history.goBack()}
              variant="contained"
              color="secondary"
            >
              Cancel
            </Button>
            <Button variant="contained" type="submit" color="primary">
              Save
            </Button>
          </Grid>
        </form>
      )}
    </div>
  );
}

export default Channel;

const initialState = {
  isFetching: true,
  channel: void 0,
  categories: void 0,
  categoryOptions: [],
  timezones: void 0,
  tzOptions: [],
  bitrates: [],
  schedule: void 0,
  scheduleOptions: [],
  plans: void 0,
  devices: void 0,
  cm_groups: void 0,
  countries: void 0,
};

const liveChannel = createSlice({
  name: "liveChannel",
  initialState,
  reducers: {
    startFetching: (state) => {
      state.isFetching = true;
    },
    fetchingSuccess: (state, { payload }) => {
      const { channel } = payload;
      state.channel = channel;
      state.isFetching = false;
    },
    fetchingCategoriesSuccess: (state, { payload }) => {
      const { categories, categoryOptions } = payload;
      state.categories = categories;
      state.categoryOptions = categoryOptions;
    },
    fetchingTimezonesSuccess: (state, { payload }) => {
      const { timezones, tzOptions } = payload;
      state.timezones = timezones;
      state.tzOptions = tzOptions;
    },
    fetchingBitratesSuccess: (state, { payload }) => {
      const { bitrates } = payload;
      state.bitrates = bitrates;
    },
    fetchingScheduleSuccess: (state, { payload }) => {
      const { schedule, scheduleOptions } = payload;
      state.schedule = schedule;
      state.scheduleOptions = scheduleOptions;
    },
    fetchingFiltersSuccess: (state, { payload }) => {
      const { plans, devices, cm_groups, countries } = payload;
      state.plans = plans;
      state.devices = devices;
      state.cm_groups = cm_groups;
      state.countries = countries;
    },
    fetchingError: (state) => {
      state.isFetching = false;
    },
    reset: () => initialState,
  },
});
const {
  startFetching,
  fetchingSuccess,
  fetchingCategoriesSuccess,
  fetchingTimezonesSuccess,
  fetchingBitratesSuccess,
  fetchingScheduleSuccess,
  fetchingFiltersSuccess,
  reset,
} = liveChannel.actions;

export const liveChannelReducer = liveChannel.reducer;

const channelFields = {
  id: {
    value: "",
    field: "id",
    label: "Id",
    disabled: true,
  },
  name: {
    value: "",
    field: "name",
    label: "Name",
    maxLength: 255,
    error: false,
    helperText: "Enter name",
  },
  slug: {
    value: "",
    field: "slug",
    label: "Slug",
    maxLength: 255,
    error: false,
    helperText: "Enter name",
  },
  v5_icon: {
    value: "",
    field: "v5_icon",
    label: "V5 Icon",
    maxLength: 255,
    error: false,
  },
  v4_icon: {
    value: "",
    field: "v4_icon",
    label: "V4 Icon",
    maxLength: 255,
    error: false,
  },
  v3_icon: {
    value: "",
    field: "v3_icon",
    label: "V3 Icon",
    maxLength: 255,
    error: false,
  },
  order: {
    value: "",
    field: "order",
    label: "Order",
    pattern: "0-9",
    maxLength: 10,
    error: false,
    helperText: "Enter order",
  },
  recorded_sec: {
    value: "",
    field: "recorded_sec",
    label: "Recorded",
    pattern: "0-9",
    maxLength: 100,
    error: false,
    helperText: "Enter recorded",
  },
  cdn_id: {
    value: "",
    field: "cdn_id",
    label: "Cdn id",
    pattern: "0-9",
    maxLength: 20,
    error: false,
    helperText: "Enter cdn id",
  },
  bg_color: {
    value: "",
    field: "bg_color",
    label: "Background color",
    pattern: "a-z0-9",
    maxLength: 6,
    error: false,
    helperText: "Enter background color",
  },
  keywords: {
    value: "",
    field: "keywords",
    label: "Keywords",
    pattern: "а-я, ",
    maxLength: 255,
    error: false,
    helperText: "Enter keywords",
  },
  category: {
    value: "",
    field: "category",
    label: "Category",
    error: false,
    helperText: "Select category",
  },
  tz: {
    value: "",
    field: "tz",
    label: "Timezone",
    error: false,
    helperText: "Select timezone",
  },
  schedule: {
    value: "",
    field: "schedule",
    label: "Schedule",
    error: false,
    helperText: "Select schedule",
  },
  bitrates: {
    value: [],
    field: "bitrates",
    label: "Bitrates",
  },
  filters: {},
};
