import axios from "axios";
import { deleteState, loadState } from "utils/localStorage";
import { userKey } from "constants/storageKeys";
import store from "config/store";

function getAxiosInstance(config = {}) {
  let {
    method = "GET",
    params,
    data,
    headers,
    timeout,
    cancelToken,
    token,
    etvnetAuth = true,
  } = config;
  if (token && etvnetAuth) {
    headers = { ...headers, Authorization: `Token ${token}` };
  }
  return axios.create({
    timeout: timeout || 5000,
    validateStatus: () => true,
    data,
    params,
    headers,
    method,
    cancelToken,
  });
}

export async function _fetch({
  url,
  config,
  onSuccess = () => {},
  onError = () => {},
  onFail = () => {},
  skipRetry = false,
}) {
  const response = await fetchWithRetry(url, config, skipRetry);
  if (response && !(response instanceof Error)) {
    if (response.status >= 200 && response.status < 300) {
      onSuccess(response.data);
    } else {
      onFail(response.data);
    }
  } else {
    const title = "Network error";
    const err = response.toString();
    const message =
      err.search("timeout") > 0
        ? "Request timeout. Please try again later"
        : "Please check your internet connection";
    store.dispatch({
      type: "alert/showErrorAlert",
      payload: { title, message },
    });
    onError();
  }
}

async function fetchWithRetry(url, config, retried = false) {
  let response;
  let error;
  const user = loadState(userKey);
  const axiosInstance = getAxiosInstance({ token: user?.token, ...config });

  try {
    response = await axiosInstance(url);
  } catch (e) {
    error = e;
  }

  if (response?.status === 401 || response?.status === 403) {
    deleteState(userKey);
    store.dispatch({ type: "user/logout" });
    return response;
  }

  if (response?.status < 500 || (retried && response?.status >= 500)) {
    return response;
  }

  if (!retried) {
    return fetchWithRetry(url, config, true);
  }

  return error;
}
