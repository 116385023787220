import { gql } from "@apollo/client";

export const GET_CUSTOMER = gql`
  query Customer($id: Int!) {
    customer(id: $id) {
      id
      username
      password
      email
      emailConfirmed
      firstName
      lastName
      dateJoined
      lastLogin
      registrationIp
      registrationCountry
      planName
      planStatus
      address {
        city
        state
        country
        zip
        created
        updated
      }
    }
  }
`;

export const GET_CUSTOMER_BILLING_INFO = gql`
  query Customer($id: Int!) {
    customer(id: $id) {
      id
      billingInfo {
        fname
        lname
        ccNum
        ccType
        expMonth
        expYear
        added
      }
    }
  }
`;

export const GET_CUSTOMER_INVOICE = gql`
  query Customer($id: Int!) {
    customer(id: $id) {
      id
      invoice {
        start
        end
        planName
        totalTime
        amount
        amountDue
        currency
      }
    }
  }
`;

export const GET_CUSTOMER_PHONES = gql`
  query findPhones($id: Int!) {
    phones(userId: $id) {
      pk
      sk
      cleansedPhoneNumberE164
      originalPhoneNumber
      phoneType
      countryCodeIso2
      city
      carrier
    }
  }
`;
