import React from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import TableContainer from "@mui/material/TableContainer";
import Typography from "@mui/material/Typography";
import { Profile } from "home/types/RecentlyViewedCustomers";
import { formatISODate } from "utils/helpers";
import { loadState, saveState } from "utils/localStorage";
import { customerProfilePath } from "constants/paths";
import Table from "components/Table";
import { Align } from "components/Table/types";

const useStyles = makeStyles((theme) => ({
  title: {
    paddingLeft: theme.spacing(2),
  },
}));

export default function RecentlyViewedCustomers() {
  const classes = useStyles();
  const profiles = getRecentProfiles();
  const history = useHistory();

  function openCustomerProfile(profile: Profile) {
    history.push(
      customerProfilePath.replace(":customerId", profile.id.toString())
    );
  }

  if (profiles.length === 0) {
    return null;
  }

  const columns = {
    id: {
      label: "Id",
    },
    username: {
      label: "User Name",
    },
    lastSeen: {
      label: "Viewed On",
      align: Align.Right,
      format: (x: string) => formatISODate(x),
    },
  };

  return (
    <TableContainer>
      <Typography variant="h6" className={classes.title}>
        Recently Viewed
      </Typography>
      <Table<Profile>
        size="small"
        data={profiles}
        onClick={openCustomerProfile}
        columns={columns}
      />
    </TableContainer>
  );
}

function getRecentProfiles(): Profile[] {
  const profiles = loadState("recentProfiles");
  if (!profiles) {
    return [];
  }
  return profiles;
}

// store last 10 profiles in local storage
export function storeProfile(newProfile: Profile): void {
  let profiles = loadState("recentProfiles") || [];

  profiles = profiles.filter((p: Profile) => p.id !== newProfile.id);
  profiles.unshift(newProfile);
  profiles = profiles.slice(0, 10);

  saveState("recentProfiles", profiles);
}
