import {
  ApolloClient,
  InMemoryCache,
  // NormalizedCacheObject,
} from "@apollo/client";
import { HttpLink } from "@apollo/client";
import { ApolloLink } from "@apollo/client/link/core";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { userKey } from "constants/storageKeys";
import { loadState, deleteState } from "utils/localStorage";
import { loginPath } from "constants/paths";

const httpLink = new HttpLink({
  // uri: "http://localhost:8000/graphql/",
  uri: "https://www.etvnet.com/api/graphql/",
});

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      authorization: `Token ${loadState(userKey)?.token}`,
    },
  };
});

const logoutLink = onError(({ networkError }) => {
  // @ts-ignore
  if (networkError?.statusCode === 401) {
    deleteState(userKey);
    etvClient.clearStore();
    window.location.replace(loginPath);
  }
});

const etvClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: ApolloLink.from([logoutLink, authLink, httpLink]),
});

export default etvClient;

// // Example of how to extend client object. A new function resets the link
// // objects and hence the headers. This is called when a user logs in to
// // keep token values current
// interface EtvClient extends ApolloClient<NormalizedCacheObject> {
//   renewToken: () => void;
// }

// const etvConstructor = new ApolloClient({
//   cache: new InMemoryCache(),
// });

// function renewToken(this: ApolloClient<NormalizedCacheObject>) {
//   const httpLink = new HttpLink({
//     uri: "http://localhost:8000/graphql/",
//     headers: { authorization: `Token ${loadState(userKey)?.token}` },
//   });

//   const logoutLink = onError(({ networkError }) => {
//     // @ts-ignore
//     if (networkError?.statusCode === 401) {
//       deleteState(userKey);
//       console.log(this);
//       this.clearStore();
//       window.location.replace(loginPath);
//     }
//   });

//   this.setLink(logoutLink.concat(httpLink));
// }

// // const boundRenew = renewToken.bind(etvConstructor);
// const etvClient: EtvClient = Object.defineProperty(
//   etvConstructor,
//   "renewToken",
//   { value: renewToken, writable: true }
// );
// etvClient.renewToken();

// export default etvClient;
