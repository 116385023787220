import React from "react";
import { useHistory } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { LIST_GROUPS } from "admin/queries";
import { Group, GroupDisplay, ListGroups } from "admin/types/Group";
import { adminUsersGroupPath } from "admin/urls";
import Spinner from "components/common/Spinner";
import Table from "components/Table";
import etvClient from "config/etvClient";

export default function UsersGroupsList() {
  const history = useHistory();

  const { loading, error, data } = useQuery<ListGroups>(LIST_GROUPS, {
    client: etvClient,
  });

  const openGroup = (row: Group) => {
    history.push(adminUsersGroupPath(row.id.toString()));
  };

  if (loading) return <Spinner />;
  if (error) return <div>Error! {error.message}</div>;
  if (!data) return <div>No Data Available</div>;

  return (
    <Table<GroupDisplay, Group>
      data={data.groups}
      columns={columns}
      onClick={openGroup}
    />
  );
}

const columns = {
  name: {
    label: "Group Name",
  },
  memberCount: {
    label: "# Memebers",
  },
};
