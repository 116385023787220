import React, { Fragment } from "react";
import { useParams } from "react-router-dom";
import { gql, useMutation, useQuery } from "@apollo/client";
import Spinner from "components/common/Spinner";
import { AppBar } from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import Grid from "@mui/material/Grid";
import Tabs from "components/Customer/Page/Main/Tabs";
import { mainTabsData, sideTabsData } from "components/Customer/Page/config";
import Button from "@mui/material/Button";

interface Category {
  id: number;
  name: string;
  order: number;
  color: string;
  bgColor: string;
}

interface CategoryVars {
  id: number;
}

const GET_CATEGORY = gql`
  query Category($id: Int!) {
    getcategory(id: $id) {
      id
      name
      order
      color
      bgColor
    }
  }
`;

const CREATE_CATEGORY = gql`
  mutation CreateCategory($input: CategoryInput!) {
    createCategory(input: $input)
  }
`;
const DELETE_CATEGORY = gql`
  mutation DeleteCategory($id: Int!) {
    deleteCategory(id: $id)
  }
`;

export default function Category() {
  const { categoryId } = useParams();
  // const { loading, error, data } = useQuery<LiveCategory, CategoryVars>(
  //   GET_CATEGORY,
  //   {
  //     variables: { id: Number(categoryId) }
  //   }
  // );
  const [createCategory, asd] = useMutation<
    { createCategory: Boolean },
    { input: Category }
  >(CREATE_CATEGORY);
  const [deleteCategory, qwe] = useMutation<
    { createCategory: Boolean },
    { id: Number }
  >(DELETE_CATEGORY);

  // if (loading) return <Spinner />;
  // if (error) return `Error! ${error.message}`;

  function create() {
    createCategory({
      variables: {
        input: {
          id: 666,
          name: "asd",
          order: 666,
          color: "asd",
          bgColor: "qwe",
        },
      },
    });
  }

  function deleteCat() {
    deleteCategory({ variables: { id: 666 } });
  }

  return (
    <div>
      <button onClick={create}>Create </button>
      <button onClick={deleteCat}>Delete </button>
      <AppBar
        position="fixed"
        color={"transparent"}
        // color="primary"
        style={{ top: "auto", bottom: 0 }}
      >
        <Toolbar>
          <Grid container>
            <Grid item xs>
              <Button variant={"contained"} color={"secondary"}>
                {" "}
                Delete
              </Button>
            </Grid>
            <Grid item xs style={{ display: "flex", justifyContent: "end" }}>
              <Button> Back</Button>
              <Button variant={"contained"} color={"primary"}>
                {" "}
                Save
              </Button>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      {/*Needs to be here according to docs*/}
      <Toolbar />
    </div>
  );
}
