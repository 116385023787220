import React, { useEffect } from "react";
import { UPDATE_FIELD } from "reducers/formReducer";
import { range } from "utils/helpers";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const months = range(12, 1);

export default function Month({
  year,
  month,
  handleChange,
  dispatch,
  handleFocus,
}) {
  const currentMonth = React.useMemo(() => new Date().getMonth(), []);
  const currentYear = React.useMemo(() => new Date().getFullYear(), []);
  const disabled = React.useCallback(
    (month) => {
      return (
        Number(month) < currentMonth + 1 && Number(year.value) === currentYear
      );
    },
    [year.value]
  );

  useEffect(() => {
    if (month && disabled(month.value)) {
      dispatch({
        type: UPDATE_FIELD,
        field: "month",
        payload: {
          value: "",
        },
      });
    }
  }, [disabled, month.value]);

  return (
    <React.Fragment>
      <FormControl variant="outlined" fullWidth style={{ marginTop: 16 }}>
        <InputLabel error={month.error}>{month.label}</InputLabel>
        <Select
          native
          input={
            <OutlinedInput
              error={month.error}
              autoComplete={month.autoComplete}
              name={month.name}
              value={month.value}
              onChange={handleChange("month")}
              onFocus={handleFocus("month")}
              labelWidth={50}
            />
          }
        >
          <option value="" />
          {months.map((month) => (
            <option disabled={disabled(month)} key={month + "m"} value={month}>
              {month}
            </option>
          ))}
        </Select>
        {month.error && (
          <FormHelperText error>{month.helperText}</FormHelperText>
        )}
      </FormControl>
    </React.Fragment>
  );
}
