export const UPDATE_FIELD = "UPDATE_FIELD";
export const UPDATE_FORM = "UPDATE_FORM";
export const UPDATE_CHECKBOX = "UPDATE_CHECKBOX";

export interface FormField {
  value: string;
  field: string;
  label: string;
  pattern?: string;
  maxLength?: number;
  error?: false;
  checked?: boolean;
  disabled?: boolean;
  helperText?: string;
  blank?: boolean;
  multiline?: boolean;
}

export interface FormState {
  [key: string]: FormField;
}

type Action =
  | { type: typeof UPDATE_FIELD; field: string; payload?: FormField }
  | { type: typeof UPDATE_CHECKBOX; field: string; payload?: FormField }
  | { type: typeof UPDATE_FORM; payload: FormState };

export default function formReducer(state: FormState, action: Action) {
  console.log(action.type);
  switch (action.type) {
    case UPDATE_FIELD:
      return {
        ...state,
        [action.field]: {
          ...state[action.field],
          ...action.payload,
        },
      };
    case UPDATE_FORM:
      return {
        ...state,
        ...action.payload,
      };
    case UPDATE_CHECKBOX:
      return {
        ...state,
        [action.field]: {
          ...state[action.field],
          checked: !state[action.field].checked,
        },
      };

    default:
      return state;
  }
}
