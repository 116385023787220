import React, { useContext, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import { makeStyles } from "@mui/styles";
import SendIcon from "@mui/icons-material/Send";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import { successAlert, errorAlert } from "components/common/Alert";
import { txsRefundUrl } from "constants/urls";
import { _fetch } from "hooks/useFetch";
import { ADD_REFUND_TX } from "constants/actions";

const useStyles = makeStyles((theme) => ({
  dialog: {
    textAlign: "center",
  },
  button: {
    margin: theme.spacing(1),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
}));

export default function TxRefundModal({
  tx,
  txType,
  customer,
  open,
  close,
  dispatch,
}) {
  const classes = useStyles();
  const [value, setValue] = useState(tx.amount);
  const [error, setError] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  function handleChange(event) {
    const value = event.target.value;
    setValue(value);
  }

  function handleFocus() {
    setError(false);
  }

  async function handleSubmit() {
    const amount = Number(value);
    if (isNaN(value) || amount > tx.amount) {
      setError(true);
      return;
    }
    setIsFetching(true);
    const data = {
      amount,
      type: txType,
      ref: tx.reference_num,
      username: customer.username,
    };
    const config = {
      method: "POST",
      data,
    };

    const onSuccess = (data) => {
      console.log(data);
      successAlert(`Successfully ${txType}ed`);
      dispatch({ type: ADD_REFUND_TX, tx: data });
    };

    const onFail = (data) => {
      console.log(data);
      let message = "Ошибка";
      if (data?.err) {
        message = data.err;
      } else if (data?.error === "insufficient_scope") {
        message = "Нет прав для этой операции";
      }
      errorAlert(message);
    };

    const url = txsRefundUrl.replace(":customerId", customer.id);
    await _fetch({ url, config, onSuccess, onFail });

    setIsFetching(false);
    close();
  }

  return (
    <div style={{ marginTop: 20 }}>
      <Dialog
        fullWidth
        maxWidth={"md"}
        open={open}
        onClose={close}
        className={classes.dialog}
        aria-labelledby="form-dialog-title"
      >
        <Container>
          <DialogTitle id="form-dialog-title">
            {txType === "refund" ? "Refunding" : "Voiding"} transaction #
            <strong>{tx.reference_num}</strong> to user{" "}
            <strong>{customer.username}</strong>
          </DialogTitle>
          <DialogContent>
            <Grid
              container
              justify={"center"}
              style={{ marginTop: 20, textAlign: "left" }}
            >
              <TextField
                variant={"outlined"}
                error={error}
                helperText={error ? "Enter the correct amount" : ""}
                value={value}
                onChange={handleChange}
                onFocus={handleFocus}
                label="Amount"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {tx.currency}
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </DialogContent>
          <DialogActions style={{ justifyContent: "center" }}>
            <Button
              onClick={handleSubmit}
              variant="contained"
              color="secondary"
              className={classes.button}
            >
              {`Send ${txType}`}
              <SendIcon className={classes.rightIcon} />
            </Button>
          </DialogActions>
        </Container>
      </Dialog>
      {isFetching && (
        <Backdrop open={isFetching} style={{ zIndex: 9999 }}>
          <CircularProgress color="secondary" />
        </Backdrop>
      )}
    </div>
  );
}
