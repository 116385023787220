import { gql } from "@apollo/client";

export const UPDATE_CUSTOMER_ACCOUNT = gql`
  mutation UpdateCustomerAccount(
    $id: Int!
    $input: UpdateCustomerAccountInput!
  ) {
    updateCustomerAccount(id: $id, input: $input) {
      ok
    }
  }
`;

export const REMOVE_CUSTOMER_BILLING_INFO = gql`
  mutation RemoveCustomerBillingInfo($id: Int!) {
    removeCustomerBillingInfo(id: $id) {
      ok
    }
  }
`;

export const UPDATE_CUSTOMER_BILLING_INFO = gql`
  mutation UpdateCustomerBillingInfo(
    $id: Int!
    $input: UpdateCustomerBillingInfoInput!
  ) {
    updateCustomerBillingInfo(id: $id, input: $input) {
      ok
    }
  }
`;

export const ADD_CUSTOMER_PHONE = gql`
  mutation createPhone($input: NewPhone!) {
    createPhone(input: $input) {
      pk
      sk
      cleansedPhoneNumberE164
      originalPhoneNumber
      phoneType
      countryCodeIso2
      city
      carrier
    }
  }
`;

export const DELETE_CUSTOMER_PHONE = gql`
  mutation deletePhone($input: DeletePhone!) {
    deletePhone(input: $input) {
      ok
    }
  }
`;

export const CREATE_TASK = gql`
  mutation createTask($input: NewTask!) {
    createTask(input: $input) {
      pk
      sk
    }
  }
`;
