import React, { useState } from "react";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Typography } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";
import HoverlessIconButton from "components/common/HoverlessIconButton";
import { eventsConfig } from "components/Customer/Page/Main/ActivityTab/eventTypes";

const useStyles = makeStyles({
  flex: {
    display: "flex",
    alignItems: "center",
  },
  name: {
    paddingLeft: 10,
  },
  details: {
    borderLeft: (config) => `3px solid ${config.color}`,
    marginLeft: 50,
    paddingLeft: 20,
    paddingTop: 10,
    minHeight: 20,
  },
});

export default function Event({ children, name, dueDate, type }) {
  const config = eventsConfig[type];
  const classes = useStyles(config);
  const [open, setOpen] = useState();

  function toggle() {
    setOpen((o) => !o);
  }
  return (
    <div>
      <Grid container justifyContent="space-between">
        <div className={classes.flex} onClick={toggle}>
          {open ? (
            <HoverlessIconButton>
              <ExpandMoreIcon />
            </HoverlessIconButton>
          ) : (
            <HoverlessIconButton>
              <ChevronRightIcon />
            </HoverlessIconButton>
          )}
          {config.icon}

          <Typography className={classes.name} variant={"body1"}>
            {name}
          </Typography>
        </div>
        <div className={classes.flex}>
          <Typography variant={"body1"}>{dueDate}</Typography>
          <HoverlessIconButton>
            <MoreVertIcon />
          </HoverlessIconButton>
        </div>
      </Grid>
      <div className={classes.details}>{open && children}</div>
    </div>
  );
}
