import React, { useEffect, useReducer } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import { MutationHookOptions } from "@apollo/client/react/types/types";
import { LIST_TIMEZONES } from "admin/queries";
import { CREATE_TIMEZONE, DELETE_TIMEZONE } from "admin/mutations";
import formReducer, { FormState, UPDATE_FORM } from "reducers/formReducer";
import Grid from "@mui/material/Grid";
import TextField from "components/common/fields/TextField";
import Spinner from "components/common/Spinner";
import ActionButtonsBar from "components/ActionButtonsBar/ActionButtonsBar";
import Checkbox from "components/common/fields/Checkbox";
import SelectField from "components/common/fields/SelectField";
import { getFormErrors } from "utils/helpers";
import { successAlert } from "components/common/Alert";
import {
  ListLiveTimezones,
  CreateLiveTimezone,
  CreateLiveTimezoneVars,
  DeleteLiveTimezone,
  DeleteLiveTimezoneVars,
} from "admin/types/LiveTimezone";
import { adminLiveTimezonesPath } from "admin/urls";
import { timezones } from "admin/components/LiveTimezoneDetails/timezones";

export default function LiveTimezoneDetails() {
  const history = useHistory();
  const [formState, dispatchForm] = useReducer(formReducer, timezoneFields);

  const { timezone } = useParams();
  const timezoneName = decodeURIComponent(timezone);

  const { loading, error, data } = useQuery<ListLiveTimezones>(LIST_TIMEZONES);

  const [createTimezone] = useMutation<
    CreateLiveTimezone,
    CreateLiveTimezoneVars
  >(CREATE_TIMEZONE, { ...createOptions, onCompleted: onCreateCompleted });
  const [deleteTimezone] = useMutation<
    DeleteLiveTimezone,
    DeleteLiveTimezoneVars
  >(DELETE_TIMEZONE, { ...deleteOptions, onCompleted: onDeleteCompleted });

  const isNew = timezoneName === "new";
  const isPopulated = isNew || formState.name.value !== "";
  const action = isNew ? "Create" : "Save";

  useEffect(() => {
    if (data?.timezones) {
      populateFieldValues();
    }
  }, [data]);

  function populateFieldValues() {
    const timezone = data?.timezones.find((t) => t.name === timezoneName);
    if (!timezone) {
      return;
    }
    //deep copying to avoid mutations
    const fields = JSON.parse(JSON.stringify(timezoneFields));
    fields.name.value = timezone.name;
    fields.order.value = timezone.order;
    fields.city.value = timezone.city;
    fields.isActive.checked = timezone.isActive;
    fields.longitude.value = timezone.longitude;
    fields.latitude.value = timezone.latitude;
    dispatchForm({ type: UPDATE_FORM, payload: fields });
  }

  function create() {
    const errors = getFormErrors(formState);
    if (errors) {
      dispatchForm({ type: UPDATE_FORM, payload: errors });
      return;
    }

    const input = {
      name: formState.name.value,
      order: parseInt(formState.order.value),
      city: formState.city.value,
      isActive: formState.isActive.checked || false,
      longitude: parseFloat(formState.longitude.value),
      latitude: parseFloat(formState.latitude.value),
    };
    createTimezone({
      variables: {
        input,
      },
    });
  }

  function onCreateCompleted() {
    successAlert(`${action + "d successfully"}`);
    history.replace(adminLiveTimezonesPath);
  }

  function onDeleteCompleted() {
    successAlert("Deleted successfully");
    history.replace(adminLiveTimezonesPath);
  }

  function del() {
    deleteTimezone({ variables: { name: timezoneName } });
  }

  if (loading || !isPopulated) return <Spinner />;
  if (error) return <div>Error! ${error.message}</div>;

  return (
    <div>
      <form>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField {...formState.city} dispatch={dispatchForm} />
          </Grid>
          <Grid item xs={12} md={3}>
            <SelectField
              dispatch={dispatchForm}
              autoComplete=""
              name={formState.name.label}
              field="name"
              value={formState.name.value}
              label={formState.name.label}
              error={formState.name.error}
              helperText={formState.name.helperText}
              options={timezones}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <TextField {...formState.longitude} dispatch={dispatchForm} />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField {...formState.latitude} dispatch={dispatchForm} />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField {...formState.order} dispatch={dispatchForm} />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Checkbox
              field="isActive"
              dispatch={dispatchForm}
              checked={formState.isActive.checked}
              label={formState.isActive.label}
            />
          </Grid>
        </Grid>
      </form>
      <ActionButtonsBar
        back={{
          onClick: history.goBack,
        }}
        save={{
          onClick: create,
          label: action,
          body: `Are you sure you want to ${action.toLowerCase()} this category?`,
        }}
        del={{
          onClick: del,
          disabled: isNew,
          body: "Are you sure you want to delete this category?",
        }}
      />
    </div>
  );
}

const createOptions: MutationHookOptions<
  CreateLiveTimezone,
  CreateLiveTimezoneVars
> = {
  update(cache, { data }) {
    const cached = cache.readQuery<ListLiveTimezones>({
      query: LIST_TIMEZONES,
    });
    const timezones = cached?.timezones;
    const createTimezone = data?.createTimezone;

    if (timezones && createTimezone) {
      const updated = timezones
        .filter((t) => t.name !== createTimezone.name)
        .concat([createTimezone]);
      cache.writeQuery({
        query: LIST_TIMEZONES,
        data: { timezones: updated },
      });
    }
  },
};

const deleteOptions: MutationHookOptions<
  DeleteLiveTimezone,
  DeleteLiveTimezoneVars
> = {
  update(cache, { data }) {
    const cached = cache.readQuery<ListLiveTimezones>({
      query: LIST_TIMEZONES,
    });
    const timezones = cached?.timezones;
    const toDelete = data?.deleteTimezone;
    timezones &&
      toDelete &&
      cache.writeQuery({
        query: LIST_TIMEZONES,
        data: {
          timezones: timezones.filter((t) => t.name !== toDelete.name),
        },
      });
  },
};

const timezoneFields: FormState = {
  name: {
    value: "",
    field: "name",
    label: "Name",
    helperText: "Select a timezone",
    error: false,
  },
  order: {
    value: "",
    field: "order",
    label: "Order",
    pattern: "0-9",
    maxLength: 10,
    error: false,
    helperText: "Enter order",
  },
  city: {
    value: "",
    field: "city",
    label: "City",
    pattern: "a-zA-Z\u0430-\u044f",
    error: false,
    helperText: "Enter a city",
  },
  isActive: {
    value: "",
    field: "isActive",
    label: "Active",
    error: false,
    blank: true,
    checked: true,
  },
  longitude: {
    value: "",
    field: "longitude",
    label: "Longitude",
    pattern: "-0-9.",
    error: false,
    helperText: "Enter longitude number",
  },
  latitude: {
    value: "",
    field: "latitude",
    label: "Latitude",
    pattern: "-0-9.",
    error: false,
    helperText: "Enter latitude number",
  },
};
