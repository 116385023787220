import React from "react";
import Typography from "@mui/material/Typography";

export default function Index() {
  return (
    <Typography component="h1" variant="h5">
      404, not found :(
    </Typography>
  );
}
