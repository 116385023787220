import React from "react";
import { Fab } from "@mui/material";
import { makeStyles } from "@mui/styles";
import AddIcon from "@mui/icons-material/Add";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));

export interface FloatingAddButtonProps {
  onClick: () => void;
}

export default function FloatingAddButton({ onClick }: FloatingAddButtonProps) {
  const classes = useStyles();

  return (
    <Fab
      className={classes.root}
      color="primary"
      aria-label="add"
      onClick={onClick}
    >
      <AddIcon />
    </Fab>
  );
}
