import React, { useState } from "react";
import Menu from "@mui/material/Menu";
import IconButton from "@mui/material/IconButton";

import { makeStyles } from "@mui/styles";
const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(6),
  },
}));

export default function BaseMenu({
  iconClass,
  edge,
  icon,
  children,
}: {
  iconClass?: string;
  edge?: "start" | "end" | false;
  icon?: JSX.Element;
  children?: React.ReactNode;
}) {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const classes = useStyles();

  function handleClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    setAnchorEl(event.currentTarget);
  }
  function handleClose() {
    setAnchorEl(null);
  }
  return (
    <React.Fragment>
      <IconButton
        onClick={handleClick}
        edge={edge}
        className={iconClass}
        color="inherit"
        size="small"
      >
        {icon}
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        variant="menu"
        PopoverClasses={{
          paper: classes.paper,
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        onClick={handleClose}
      >
        {children}
      </Menu>
    </React.Fragment>
  );
}
