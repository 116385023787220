import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MuiAlert from "@mui/lab/Alert";
import Snackbar from "@mui/material/Snackbar";
import { makeStyles } from "@mui/styles";
import { createSlice } from "@reduxjs/toolkit";
import AlertTitle from "@mui/lab/AlertTitle";
import store from "config/store";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
  // this avoid jumps on render
  alert: {
    marginTop: -0.1,
  },
  title: {
    fontWeight: "bold",
  },
}));

export default function Alert({
  anchorOrigin = {
    vertical: "bottom",
    horizontal: "left",
  },
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const alerts = useSelector((state) => state.alert);
  const { message, severity, title } = alerts;
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (message) {
      setOpen(false);
      // Make sure next alert renders correctly
      // while first isn't hidden yet
      setTimeout(() => setOpen(true), 10);
    }
  }, [message]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
    setTimeout(() => dispatch(hideAlert()), 200);
  };

  return (
    <div className={classes.root}>
      <Snackbar
        anchorOrigin={anchorOrigin}
        open={open}
        autoHideDuration={10000}
        onClose={handleClose}
      >
        <MuiAlert severity={severity}>
          {title && <AlertTitle className={classes.title}>{title}</AlertTitle>}
          <div className={classes.alert}>{message}</div>
        </MuiAlert>
      </Snackbar>
    </div>
  );
}

const initialState = {
  message: void 0,
  severity: "success",
  title: "",
};

const alert = createSlice({
  name: "alert",
  initialState,
  reducers: {
    showAlert: (state, { payload }) => {
      const { message, severity } = payload;
      state.message = message;
      state.severity = severity;
    },
    showSuccessAlert: (state, { payload }) => {
      const { message, title } = payload;
      state.message = message;
      state.severity = "success";
      state.title = title;
    },
    showErrorAlert: (state, { payload }) => {
      const { message, title } = payload;
      state.message = message;
      state.severity = "error";
      state.title = title;
    },
    hideAlert: () => initialState,
  },
});

export const { showAlert, hideAlert, showSuccessAlert, showErrorAlert } =
  alert.actions;
export const alertReducer = alert.reducer;

export function successAlert(message, title = "") {
  store.dispatch(
    showSuccessAlert({
      message,
      title,
    })
  );
}

export function errorAlert(message, title = "") {
  store.dispatch(
    showErrorAlert({
      message,
      title,
    })
  );
}
