import React, { useEffect, useState } from "react";
import SelectField from "components/common/fields/SelectField";
import { UPDATE_FIELD } from "reducers/formReducer";

export default function Problem({ category, problem, dispatch }) {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (category === "Technical") {
      setOptions(technicalOptions);
    }
    if (category === "Financial") {
      setOptions(financialOptions);
    }
    if (category === "Account") {
      setOptions(accountOptions);
    }
    dispatch({
      type: UPDATE_FIELD,
      field: problem.field,
      payload: {
        value: ""
      }
    });
  }, [category]);

  return (
    <SelectField
      {...problem}
      dispatch={dispatch}
      options={options}
      disabled={!options.length}
    />
  );
}
const technicalOptions = [
  "Не работает зум на айдпеде",
  "Рука не достает до мышки"
];
const financialOptions = ["Заплатил и не получил", "Не получил, но заплатил"];
const accountOptions = [
  "Ни хачу больши сматреть евтен",
  "Ни магу залагинится, праверти"
];
