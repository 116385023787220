import React from "react";
import blueGrey from "@mui/material/colors/blueGrey";
import pink from "@mui/material/colors/pink";
import green from "@mui/material/colors/green";
import AssignmentIcon from "@mui/icons-material/Assignment";
import EmailIcon from "@mui/icons-material/Email";
import SmsIcon from "@mui/icons-material/Sms";
import SyncAltIcon from "@mui/icons-material/SyncAlt";
import { deepOrange, lightGreen, yellow } from "@mui/material/colors";

// export const taskType = 'task';
// export const emailType = 'email';
// export const smsType = 'sms';

// export const smsEventColor = blueGrey[500];

export const eventIconColors = {
  log: lightGreen[500],
  email: pink[200],
  task: green[300],
  sms: yellow[600],
};

export const eventsConfig = {
  task: {
    color: eventIconColors.task,
    icon: <AssignmentIcon style={{ color: eventIconColors.task }} />,
  },
  email: {
    color: eventIconColors.email,
    icon: <EmailIcon style={{ color: eventIconColors.email }} />,
  },
  sms: {
    color: eventIconColors.sms,
    icon: <SmsIcon style={{ color: eventIconColors.sms }} />,
  },
  log: {
    color: eventIconColors.log,
    icon: <SyncAltIcon style={{ color: eventIconColors.log }} />,
  },
};
