import React from "react";
import { range } from "utils/helpers";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormHelperText from "@mui/material/FormHelperText";

const years = range(11, new Date().getFullYear());

export default function Year({ year, handleChange, handleFocus }) {
  return (
    <React.Fragment>
      <FormControl variant="outlined" fullWidth style={{ marginTop: 16 }}>
        <InputLabel error={year.error}>{year.label}</InputLabel>
        <Select
          native
          input={
            <OutlinedInput
              error={year.error}
              autoComplete={year.autoComplete}
              name={year.name}
              value={year.value}
              onChange={handleChange("year")}
              onFocus={handleFocus("year")}
              labelWidth={30}
            />
          }
        >
          <option value="" />
          {years.map((year) => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </Select>
        {year.error && <FormHelperText error>{year.helperText}</FormHelperText>}
      </FormControl>
    </React.Fragment>
  );
}
