import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    secondary: {
      main: "#D7034B",
    },
  },
  // typography: {
  //   fontSize: 16
  // }
});

export default theme;
