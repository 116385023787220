import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import etvClient from "config/etvClient";
import {
  GetCustomerBillingInfo,
  RemoveCustomerBillingInfo,
  RemoveCustomerBillingInfoVars,
} from "customers/types/Customer";
import { getCreditCardIconUrl, formatISODate } from "utils/helpers";
import { GET_CUSTOMER_BILLING_INFO } from "customers/queries";
import { REMOVE_CUSTOMER_BILLING_INFO } from "customers/mutations";
import { errorAlert, successAlert } from "components/common/Alert";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/AddCircle";
import Divider from "@mui/material/Divider";
import { makeStyles } from "@mui/styles";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import ConfirmModal from "components/ConfirmModal";
import Spinner from "components/common/Spinner";
import Backdrop from "@mui/material/Backdrop";

import CustomerBillingInfoForm from "../CustomerBillingInfoForm";

const useStyles = makeStyles((theme) => ({
  iconButton: {
    "&:hover": {
      backgroundColor: "transparent",
    },
    padding: 0,
  },
}));

export default function CustomerBillingInfoDetails() {
  const classes = useStyles();
  const [edit, setEdit] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [addModal, setAddModal] = useState(false);
  const { customerId } = useParams();

  // REMOVE BILLING INFO BLOCK
  const [del, delData] = useMutation<
    RemoveCustomerBillingInfo,
    RemoveCustomerBillingInfoVars
  >(REMOVE_CUSTOMER_BILLING_INFO, {
    client: etvClient,
    onCompleted,
    onError: () => errorAlert("Failed to delete"),
  });

  const { loading, error, data } = useQuery<GetCustomerBillingInfo>(
    GET_CUSTOMER_BILLING_INFO,
    {
      variables: { id: customerId },
      client: etvClient,
    }
  );
  const customer = data?.customer;
  const creditCard = data?.customer.billingInfo;

  if (loading) return <Spinner />;

  function openAdd() {
    if (!creditCard) {
      setAddModal(true);
    }
  }

  function closeAdd() {
    setAddModal(false);
  }

  function openEdit() {
    setEdit(true);
  }

  function closeEdit() {
    setEdit(false);
  }

  function openDelete() {
    setDeleteModal(true);
  }

  function closeDelete() {
    setDeleteModal(false);
  }

  function onCompleted(data: RemoveCustomerBillingInfo) {
    try {
      const cached = etvClient.readQuery<GetCustomerBillingInfo>({
        query: GET_CUSTOMER_BILLING_INFO,
        variables: { id: customerId },
      });
      const c = cached?.customer;
      c &&
        etvClient.writeQuery({
          query: GET_CUSTOMER_BILLING_INFO,
          data: { customer: { ...c, billingInfo: null } },
        });
    } catch (e) {
      console.log(e);
    }
    successAlert("Removed successfully");
    closeDelete();
  }

  function handleConfirmRemove() {
    del({ variables: { id: customerId } });
  }

  // const cards = [
  //     {
  //       type: "Visa",
  //       number: "0123",
  //       expires: "12 / 2021",
  //       name: "Yuriy Gaslin",
  //       added: "1 May 2018"
  //     },
  //     {
  //       type: "Master card",
  //       number: "4356",
  //       expires: "08 / 2039",
  //       name: "Yuriy Gaslin",
  //       added: "22 June 2007"
  //     }
  // ];

  return (
    <div style={{ marginTop: 30 }}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h5">Credit cards</Typography>
        <IconButton className={classes.iconButton} onClick={openAdd}>
          <AddIcon />
        </IconButton>
      </div>
      <Divider />
      {addModal && (
        <CustomerBillingInfoForm customerId={customerId} close={closeAdd} />
      )}
      {customer && edit ? (
        <CustomerBillingInfoForm
          customerId={customer.id}
          billingInfo={creditCard}
          close={closeEdit}
        />
      ) : (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="left">Type</TableCell>
              <TableCell align="left">Number</TableCell>
              <TableCell align="left">Expires</TableCell>
              <TableCell align="left">Name</TableCell>
              <TableCell align="left">Added</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {creditCard && (
              <TableRow style={{ cursor: "pointer" }} key={"credit_card"}>
                <TableCell align="left">
                  <img src={getCreditCardIconUrl(creditCard.ccType)} alt="" />
                </TableCell>
                <TableCell align="left">{creditCard.ccNum.slice(-4)}</TableCell>
                <TableCell align="left">
                  {creditCard.expMonth}/{creditCard.expYear}
                </TableCell>
                <TableCell align="left">
                  {creditCard.fname} {creditCard.lname}
                </TableCell>
                <TableCell align="left">
                  {formatISODate(creditCard.added)}
                </TableCell>
                <TableCell align="right">
                  <IconButton onClick={openEdit}>
                    <EditIcon />
                  </IconButton>
                  <IconButton onClick={openDelete}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            )}
            <ConfirmModal
              onCancel={closeDelete}
              onConfirm={handleConfirmRemove}
              open={deleteModal}
              title={"Confirmation"}
              body={"Are you sure you want to delete?"}
            />
          </TableBody>
        </Table>
      )}
      <Backdrop style={{ zIndex: 9999 }} open={delData.loading}>
        <Spinner />
      </Backdrop>
    </div>
  );
}
