import React from "react";
import { Switch } from "react-router-dom";
import { Route } from "react-router-dom";
import LiveCategoriesList from "admin/components/LiveCategoriesList";
import LiveCategoryDetails from "admin/components/LiveCategoryDetails";
import LiveTimezonesList from "admin/components/LiveTimezonesList";
import LiveTimezoneDetails from "admin/components/LiveTimezoneDetails";
import UsersStaffList from "admin/components/UsersStaffList";
import UsersGroupsList from "admin/components/UsersGroupsList";
import UsersGroupDetials from "admin/components/UsersGroupDetails";
import VodIndexes from "./components/VodIndexes";
import TreeNav from "admin/components/TreeNav";
import Grid from "@mui/material/Grid";
import {
  adminLiveCategoriesPath,
  adminLiveCategoryPath,
  adminLiveTimezonesPath,
  adminLiveTimezonePath,
  adminUsersStaffPath,
  adminUsersGroupsPath,
  adminUsersGroupPath,
  adminVodIndexesPath,
} from "admin/urls";

export default function AdminSection() {
  return (
    <Grid container>
      <Grid item xs={2}>
        <TreeNav />
      </Grid>
      <Grid item xs={10}>
        <Switch>
          <Route
            exact
            path={adminLiveCategoriesPath}
            component={LiveCategoriesList}
          />
          <Route
            exact
            path={adminLiveCategoryPath(":categoryId")}
            component={LiveCategoryDetails}
          />
          <Route
            exact
            path={adminLiveTimezonesPath}
            component={LiveTimezonesList}
          />
          <Route
            exact
            path={adminLiveTimezonePath(":timezone")}
            component={LiveTimezoneDetails}
          />
          <Route exact path={adminUsersStaffPath} component={UsersStaffList} />
          <Route
            exact
            path={adminUsersGroupsPath}
            component={UsersGroupsList}
          />
          <Route
            exact
            path={adminUsersGroupPath(":id")}
            component={UsersGroupDetials}
          />
          <Route exact path={adminVodIndexesPath} component={VodIndexes} />
        </Switch>
      </Grid>
    </Grid>
  );
}
