import React, { useState, useRef } from "react";
import TextFieldBase from "components/common/fields/TextFieldBase";
import { sanitize } from "utils/helpers";
import { UPDATE_FIELD } from "reducers/formReducer";

export default function TextField(props) {
  const { dispatch } = props;
  const [value, setValue] = useState(props.value);
  // Autocomplete doesn't trigger focus or blur
  // const wasFocused = useRef(false);

  function handleChange(event) {
    const value = event.target.value;
    setValue(value);
    dispatch({
      type: UPDATE_FIELD,
      field: props.field,
      payload: { value },
    });
    // This does not update currently focused field
    // !wasFocused.current &&
    //   dispatch({
    //     type: UPDATE_FIELD,
    //     field: props.field,
    //     payload: { value },
    //   });
  }

  function handleFocus() {
    dispatch({
      type: UPDATE_FIELD,
      field: props.field,
      payload: { error: false },
    });
    // wasFocused.current = true;
  }

  const handleBlur = (event) => {
    const sanitizedValue = props.pattern
      ? sanitize(event.target.value, props.pattern, props.maxLength)
      : event.target.value;
    setValue(sanitizedValue);
    dispatch({
      type: UPDATE_FIELD,
      field: props.field,
      payload: { value: sanitizedValue },
    });
    // wasFocused.current = false;
  };

  const additionalProps = {
    value,
    handleChange,
    handleFocus,
    handleBlur,
  };

  return <TextFieldBase {...props} {...additionalProps} />;
}
