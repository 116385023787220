import React, { useReducer } from "react";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import { loginUrl } from "constants/urls";
import { saveState } from "utils/localStorage";
import { userKey } from "constants/storageKeys";
import { _fetch } from "hooks/useFetch";
import { useDispatch, useSelector } from "react-redux";
import { loginFailed, loginStart, loginSuccess } from "reducers/userReducer";
import formReducer, { UPDATE_FORM } from "reducers/formReducer";
import { getFormData, getFormErrors } from "utils/helpers";
import Alert, { showAlert, showErrorAlert } from "components/common/Alert";
import TextField from "components/common/fields/TextField";
import Backdrop from "@mui/material/Backdrop";
import Spinner from "components/common/Spinner";
import { unknownError } from "constants/errors";
import etvClient from "config/etvClient";

const useStyles = makeStyles((theme) => ({
  // "@global": {
  //   body: {
  //     backgroundColor: theme.palette.common.white
  //   }
  // },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  progress: {
    margin: theme.spacing(2),
  },
  spinner: {
    paddingTop: "48px",
    paddingBottom: "24px",
    display: "flex",
    justifyContent: "center",
  },
  error: {
    backgroundColor: "ff0000",
  },
}));

export default function Login() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const { isFetching } = user;
  const [form, dispatchForm] = useReducer(formReducer, loginFields);

  async function handleSubmit(e) {
    e.preventDefault();
    const errors = getFormErrors(form);
    if (errors) {
      console.log(errors);
      dispatchForm({ type: UPDATE_FORM, payload: errors });
    } else {
      dispatch(loginStart());
      const data = getFormData(form);
      const config = {
        method: "POST",
        data,
      };
      const onSuccess = (data) => {
        dispatch(loginSuccess(data));
        saveState(userKey, data);
      };

      const onFail = (data) => {
        dispatch(loginFailed());
        const title = "Login failed";
        const message = loginErrors[data?.err] || unknownError;
        dispatch(showErrorAlert({ message, title }));
      };

      const onError = () => {
        dispatch(loginFailed());
      };

      await _fetch({ url: loginUrl, config, onSuccess, onFail, onError });
    }
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Link href="/login/">
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
        </Link>
        <Typography component="h1" variant="h5">
          Вход
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container justifyContent="center">
            <TextField {...form.username} dispatch={dispatchForm} />
            <TextField {...form.password} dispatch={dispatchForm} />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Войти
            </Button>
          </Grid>
        </form>
      </div>
      <Backdrop open={isFetching}>
        <Spinner />
      </Backdrop>
      <Alert anchorOrigin={{ vertical: "top", horizontal: "center" }} />
    </Container>
  );
}

const loginFields = {
  username: {
    value: "",
    field: "username",
    label: "Email",
    pattern: "a-zA-Z@.+-_",
    maxLength: 254,
    error: false,
    autoComplete: "email",
    helperText: "Please enter email",
  },
  password: {
    value: "",
    field: "password",
    type: "password",
    label: "Password",
    maxLength: 254,
    error: false,
    autoComplete: "",
    helperText: "Please enter password",
  },
};

const loginErrors = {
  // VALIDATION_FAILED: "Ввведенные данные не прошли валидацию",
  // TOO_MANY_REQUESTS: "Превышен лимит попыток с данного IP. Попробуйте позже",
  // AUTH_FAILED: "Юзер с таким логином и паролем не найден",
  // NO_GROUP: "Доступ запрещен. Юзер не состоит в нужной группе",
  // TOKEN_EXPIRED: "Ваш токен протух. Нужно залогиниться снова",
  // INACTIVE_USER: "Юзер должен быть активным",
  // NOT_STAFF_USER: "Юзер должен быть стаффом",
  VALIDATION_FAILED:
    "Credentials didn't pass validation. Make sure you entered them correctly",
  TOO_MANY_REQUESTS: "Reached limit of maximum login attempts. Try again later",
  AUTH_FAILED: "User with entered login and password wasn't found",
  NO_GROUP: "Access denied. User should be added to needed group",
  TOKEN_EXPIRED: "Your session has expired. You need to login again",
  INACTIVE_USER: "You can't login with inactive user",
  NOT_STAFF_USER: "You can't login with non-staff user",
  DEFAULT: "Login failed",
};
