import React from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import Spinner from "components/common/Spinner";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Table from "@mui/material/Table";
import { adminLiveCategoryPath } from "constants/paths";
import { useHistory } from "react-router-dom";

interface Category {
  id: number;
  name: string;
  order: number;
  color: string;
}

interface Categories {
  categories: Category[];
}

const GET_CATEGORIES = gql`
  query Categories {
    categories {
      id
      name
      order
    }
  }
`;

export default function Categories() {
  const history = useHistory();
  const { loading, error, data } = useQuery<Categories>(GET_CATEGORIES);
  // const [createCategory] = useMutation(CREATE_CATEGORY);
  // const [deleteCategory, asd] = useMutation(DELETE_CATEGORY);
  // console.log(asd);
  // console.log(data)

  if (loading) return <Spinner />;
  // if (loading) return "Loading";
  if (error) return `Error! ${error.message}`;

  const handleClick = (id: number) => () => {
    history.push(adminLiveCategoryPath.replace(":categoryId", String(id)));
  };

  function create() {
    // createCategory({
    //   variables: {
    //     input: {
    //       id: 666,
    //       name: "asd",
    //       order: 666,
    //       color: "asd",
    //       bgColor: "qwe"
    //     }
    //   }
    // });
  }

  function deleteCat() {
    // deleteCategory({ variables: { id: 666 } });
  }

  return (
    <div>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="left">Id</TableCell>
            <TableCell align="left">Name</TableCell>
            <TableCell align="left">Order</TableCell>

            {/*<TableCell align="left">Hidden</TableCell>*/}
            {/*<TableCell align="left">Recorded</TableCell>*/}
          </TableRow>
        </TableHead>
        <TableBody>
          {data &&
            data.categories.map((c) => (
              <TableRow
                hover
                style={{ cursor: "pointer" }}
                key={c.id}
                onClick={handleClick(c.id)}
                // onClick={create}
              >
                <TableCell align="left">{c.id}</TableCell>
                <TableCell align="left">{c.name}</TableCell>
                <TableCell align="left">{c.order}</TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <button title={"delete"} onClick={deleteCat} />
    </div>
  );
}
