import React, { useState, useMemo, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { Table as MaterialTable } from "@mui/material";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableSortLabel from "@mui/material/TableSortLabel";
import { Align, Padding, Size, Columns, TableData } from "./types";

const useStyles = makeStyles((theme) => ({
  defaultTable: {
    padding: theme.spacing(2),
  },
  hoverRow: {
    cursor: "pointer",
  },
}));

// modified from "utils/helpers" to make types match
const sort = (field: any, asc: boolean, type: string) => (a: any, b: any) => {
  let fieldA = a[field];
  let fieldB = b[field];
  if (type === "Number") {
    fieldA = Number(fieldA);
    fieldB = Number(fieldB);
  } else if (type === "Date") {
    fieldA = new Date(fieldA);
    fieldB = new Date(fieldB);
  }
  const ascMulti = asc ? 1 : -1;
  if (fieldA < fieldB) {
    return ascMulti * -1;
  } else if (fieldA > fieldB) {
    return ascMulti * 1;
  }
  return 0;
};

export default function Table<Display, Data extends Display = Display>({
  data,
  columns,
  hover = true,
  onClick = () => void 0,
  sortField = Object.keys(columns)[0] as keyof Display,
  className,
  padding = "normal",
  size = "medium",
  stickyHeader = false,
  actionComponent,
}: {
  data: TableData<Display, Data>[];
  columns: Columns<Display>;
  hover?: boolean;
  onClick?: (row: Data) => void;
  sortField?: keyof Display;
  className?: string;
  padding?: Padding;
  size?: Size;
  stickyHeader?: boolean;
  actionComponent?: (x: Data) => JSX.Element;
}) {
  const classes = useStyles();

  const [columnNames, alignments, labels, formatters] = useMemo(() => {
    const columnNames: string[] = Object.keys(columns);
    let alignments: Record<string, Align> = {};
    let labels: Record<string, string> = {};
    let formatters: Record<string, Function> = {};
    columnNames.forEach((name) => {
      alignments[name] = columns[name as keyof Display].align || Align.Left;
      labels[name] = columns[name as keyof Display].label || name;
      formatters[name] =
        columns[name as keyof Display].format || ((raw: any) => String(raw));
    });
    return [columnNames, alignments, labels, formatters];
  }, [columns]);

  const [state, setState] = useState({
    data: [] as TableData<Display, Data>[],
    sortField,
    isAscending: true,
  });

  // Table keeps a local copy of the data so sort mutations don't leak out.
  // To show fresh values, we make another copy whenever data changes.
  useEffect(() => {
    const copiedData = data.map((rowObj) => ({ ...rowObj }));
    const sortField = state.sortField;
    const isAscending = state.isAscending;
    const sortFunction = columns[sortField].sort || sort;
    copiedData.sort(
      sortFunction(sortField, isAscending, typeof data[0][sortField])
    );
    setState({
      data: copiedData,
      sortField,
      isAscending,
    });
  }, [data]);

  const sortData = (sortField: keyof Display) => {
    const isAscending = !state.isAscending;
    const sortFunction = columns[sortField].sort || sort;
    const sortedData = state.data.sort(
      sortFunction(sortField, isAscending, typeof state.data[0][sortField])
    );
    setState({
      data: sortedData,
      sortField,
      isAscending,
    });
  };

  return (
    <MaterialTable
      className={className || classes.defaultTable}
      padding={padding}
      size={size}
      stickyHeader={stickyHeader}
    >
      <TableHead>
        <TableRow>
          {columnNames.map((col) => (
            <TableCell key={col} align={alignments[col]}>
              <TableSortLabel
                active={col === state.sortField}
                direction={state.isAscending ? "asc" : "desc"}
                onClick={() => sortData(col as keyof Display)}
              >
                {labels[col]}
              </TableSortLabel>
            </TableCell>
          ))}
          {actionComponent && <TableCell align="right">Actions</TableCell>}
        </TableRow>
      </TableHead>
      <TableBody>
        {state.data.map((dataRow, index) => (
          <TableRow
            hover={hover}
            // @ts-ignore
            key={dataRow.id || index}
            className={classes.hoverRow}
          >
            {columnNames.map((col) => (
              <TableCell
                key={col + index}
                align={alignments[col]}
                onClick={() => onClick(dataRow)}
              >
                {formatters[col](dataRow[col as keyof Display])}
              </TableCell>
            ))}
            {actionComponent && (
              <TableCell align="right">{actionComponent(dataRow)}</TableCell>
            )}
          </TableRow>
        ))}
      </TableBody>
    </MaterialTable>
  );
}
