import React, { useState } from "react";
import { alpha } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import Grid from "@mui/material/Grid";
import { useHistory } from "react-router-dom";
import { searchCustomersPath } from "constants/paths";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 500,
    height: "35px",
    color: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    borderRadius: theme.shape.borderRadius,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    color: "#fff",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  iconButtonContainer: {
    display: "flex",
    justifyContent: "end",
  },
  inputBase: {
    width: "100%",
  },
  inputInput: {
    color: "#fff",
  },
  selectEmpty: {
    color: "#fff",
    background: "inherit",
  },
  icon: {
    color: "#fff",
  },
}));

export default function SearchField() {
  const classes = useStyles();
  const history = useHistory();
  const [field, setField] = useState("");
  const [query, setQuery] = useState("");

  const handleFieldChange = (
    event: SelectChangeEvent<string>,
    child: React.ReactNode
  ) => {
    setField(event.target.value);
  };

  const handleQueryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value);
  };

  function search() {
    let url = searchCustomersPath.replace(":query", query);
    url += field ? `?field=${field}` : "";
    query && history.push(url);
  }

  function handleEnter(
    event: React.KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>
  ) {
    if (event.keyCode === 13) {
      search();
    }
  }

  return (
    <Paper className={classes.root}>
      <Grid container alignItems={"center"}>
        <FormControl sx={{ pl: "10px", pr: "5px" }}>
          <Select
            variant="standard"
            disableUnderline
            value={field}
            onChange={handleFieldChange}
            displayEmpty
            className={classes.selectEmpty}
            classes={{
              icon: classes.icon,
            }}
          >
            <MenuItem value={""}>All</MenuItem>
            <MenuItem value={"username"}>Username</MenuItem>
            <MenuItem value={"email"}>Email</MenuItem>
            <MenuItem value={"phone"}>Phone</MenuItem>
          </Select>
        </FormControl>
        <Grid item xs>
          <InputBase
            className={classes.inputBase}
            classes={{ root: classes.input, input: classes.inputInput }}
            placeholder="Search..."
            value={query}
            onChange={handleQueryChange}
            onKeyDown={handleEnter}
          />
        </Grid>
        <div className={classes.iconButtonContainer}>
          <IconButton className={classes.iconButton} onClick={search}>
            <SearchIcon />
          </IconButton>
        </div>
      </Grid>
    </Paper>
  );
}

interface MuiSelect {
  name?: string | undefined;
  value: unknown;
}
