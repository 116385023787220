import { gql } from "@apollo/client";

export const LIST_CATEGORIES = gql`
  query Categories {
    categories {
      id
      name
      order
      color
      bgColor
    }
  }
`;

export const GET_CATEGORY = gql`
  query Category($id: Int!) {
    category(id: $id) {
      id
      name
      order
      color
      bgColor
    }
  }
`;

export const LIST_TIMEZONES = gql`
  query Timezones {
    timezones {
      name
      order
      city
      isActive
      longitude
      latitude
    }
  }
`;

export const GET_TIMEZONE = gql`
  query Timezone($name: String!) {
    timezone(name: $name) {
      name
      order
      city
      isActive
      longitude
      latitude
    }
  }
`;

export const LIST_STAFF = gql`
  query Users {
    users {
      id
      username
      email
      groups
    }
  }
`;

export const LIST_GROUPS = gql`
  query Groups {
    groups {
      id
      name
      memberCount
    }
  }
`;

export const LIST_GROUP_MEMBERS = gql`
  query GroupMembers($id: Int!) {
    groupMembers(id: $id) {
      name
      users {
        id
        username
        email
      }
    }
  }
`;
