import React, { useEffect, useMemo } from "react";
import TextField from "@mui/material/TextField/TextField";
import MenuItem from "@mui/material/MenuItem/MenuItem";
import { makeStyles } from "@mui/styles";
import { UPDATE_FIELD } from "reducers/formReducer";

const useStyles = makeStyles((theme) => ({
  menu: {
    height: 400,
  },
}));

export default function State({
  handleChange,
  handleFocus,
  autoComplete,
  name,
  country,
  countries,
  state,
  dispatch,
}) {
  const classes = useStyles();

  const activeCountry = useMemo(
    () => countries.find((c) => c.iso === country.value),
    [countries, country.value]
  );

  useEffect(() => {
    dispatch({
      type: UPDATE_FIELD,
      field: "state",
      payload: {
        blank: !Boolean(activeCountry && activeCountry.states),
      },
    });
  }, [activeCountry]);

  const options = useMemo(
    () =>
      activeCountry && activeCountry.states
        ? activeCountry.states.map((state) => (
            <MenuItem key={state.name} value={state.code}>
              {state.name}
            </MenuItem>
          ))
        : [],
    [activeCountry]
  );

  return (
    <TextField
      error={state.error}
      disabled={!activeCountry || (activeCountry && !activeCountry.states)}
      autoComplete={autoComplete}
      name={name}
      select
      fullWidth
      label={"State"}
      value={state.value}
      onChange={handleChange("state")}
      margin="normal"
      variant="outlined"
      helperText={state.error ? state.helperText : ""}
      onFocus={handleFocus("state")}
      SelectProps={{
        MenuProps: {
          className: classes.menu,
        },
      }}
    >
      {options}
    </TextField>
  );
}
