import React, {
  createContext,
  Fragment,
  useReducer,
  useMemo,
  useEffect,
} from "react";
import CustomerAccountDetails from "customers/components/CustomerAccountDetails";
import { useQuery } from "@apollo/client";
import { Customer, GetCustomer } from "customers/types/Customer";
import { GET_CUSTOMER } from "customers/queries";
import etvClient from "config/etvClient";
import { loadState } from "utils/localStorage";
import { useParams } from "react-router-dom";
import Spinner from "components/common/Spinner";
import Grid from "@mui/material/Grid";
import Tabs from "components/Customer/Page/Main/Tabs";
import Paper from "@mui/material/Paper";
import PersonIcon from "@mui/icons-material/Person";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import SmsIcon from "@mui/icons-material/Sms";
import EmailIcon from "@mui/icons-material/Email";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { makeStyles } from "@mui/styles";
import Profile from "components/Customer/Page/Main/ProfileTab/Profile";
import Activity from "components/Customer/Page/Main/ActivityTab/Activity";
import Billing from "components/Customer/Page/Main/Billing";
import LogCall from "components/Customer/Page/Sidebar/LogCallTab/LogCall";
import NewTask from "components/Customer/Page/Sidebar/NewTaskTab/NewTask";
import Account from "components/Customer/Page/Main/BillingTab/Account";
import CustomerBillingInfoDetails from "customers/components/CustomerBillingInfoDetails/CustomerBillingInfoDetails";
import BillingInvoiceDetails from "customers/components/BillingInvoiceDetails/BillingInvoiceDetails";
import SmsModal from "customers/components/SmsModal";
import EmailModal from "customers/components/EmailModal";
import { CustomerTxs } from "customers/components/CustomerTxs/CustomerTxs";
import Address from "components/Customer/Page/Main/ProfileTab/Address";
import AdditionalInfo from "components/Customer/Page/Main/ProfileTab/AdditionalInfo";
import Task from "customers/components/Task";
import { ModalState, ModalAction } from "customers/types/Customer";
import { ListSms } from "components/LegacySms/ListSms";
import { storeProfile } from "home/components/RecentlyViewedCustomers/RecentlyViewedCustomers";

const useStyles = makeStyles((theme) => ({
  paper: {
    margin: "16px 0",
    padding: theme.spacing(1),
    background: "#f5f5f5",
  },
  usernameGrid: {
    display: "flex",
    alignItems: "center",
  },
  username: {
    marginLeft: theme.spacing(1),
  },
  buttons: {
    display: "flex",
  },
  tabs: {
    marginTop: theme.spacing(1),
  },
}));

export const CustomerContext = createContext<Customer | undefined>(void 0);

export default function CustomerInfo() {
  const classes = useStyles();
  const { customerId } = useParams();
  const [modalState, modalDispatch] = useReducer(
    modalReducer,
    modalInitialState
  );

  const { loading, error, data } = useQuery<GetCustomer>(GET_CUSTOMER, {
    variables: { id: customerId },
    client: etvClient,
  });
  const customer = data?.customer;
  const agent = useMemo(() => loadState("user")?.username, []);

  // store for RecentlyViewedCustomers
  useEffect(() => {
    if (customer) {
      const profile = {
        id: customer.id,
        username: customer.username,
        lastSeen: new Date().toISOString(),
      };
      storeProfile(profile);
    }
  }, [customer]);

  if (loading) return <Spinner />;
  if (error || !customer) return <div>Error</div>;

  const Header = (
    <Paper className={classes.paper}>
      <Grid container justifyContent="space-between">
        <Grid item className={classes.usernameGrid}>
          <PersonIcon />
          <div className={classes.username}>
            <Typography variant="h6">{customer.username}</Typography>
            <Typography variant="body2">
              {customer.planStatus}, {customer.planName}
            </Typography>
          </div>
        </Grid>
        <Grid item className={classes.buttons}>
          {/* <IconButton
            onClick={() => modalDispatch({ field: "sms", open: true })}
          >
            <SmsIcon />
          </IconButton> */}
          <IconButton
            onClick={() => modalDispatch({ field: "email", open: true })}
          >
            <EmailIcon />
          </IconButton>
          <IconButton>
            <MoreVertIcon />
          </IconButton>
        </Grid>
      </Grid>
      <SmsModal
        open={modalState.sms}
        dispatch={modalDispatch}
        agent={agent}
        customer={customer}
        phones={hardcodedPhones}
      />
      <EmailModal
        open={modalState.email}
        dispatch={modalDispatch}
        agent={agent}
        customer={customer}
      />
    </Paper>
  );

  return (
    <CustomerContext.Provider value={customer}>
      <Fragment>
        {Header}
        <Grid container className={classes.tabs} spacing={2} sx={{ mb: 2 }}>
          <Grid item lg={8} md={12}>
            <Tabs tabsData={mainTabsData} active={0} />
          </Grid>
          <Grid item xs>
            <Tabs tabsData={sideTabsData} active={1} />
          </Grid>
        </Grid>
      </Fragment>
    </CustomerContext.Provider>
  );
}

const hardcodedPhones: string[] = [""];

// TODO rewrite remaining to Typescript
const mainTabsData = [
  {
    label: "Profile",
    component: (
      <Fragment>
        <CustomerAccountDetails />
        <ListSms />
        {/*<Address />*/}
        {/* <Phones data={hardcodedPhones} /> */}
        {/*<AdditionalInfo />*/}
      </Fragment>
    ),
  },
  {
    label: "Activity",
    component: <Activity />,
  },
  {
    label: "Billing",
    component: (
      <Fragment>
        <Account />
        <CustomerBillingInfoDetails />
        <BillingInvoiceDetails />
        <CustomerTxs />
      </Fragment>
    ),
  },
];

const sideTabsData = [
  {
    label: "Log a call",
    component: <LogCall />,
  },
  {
    label: "New task",
    component: <Task />,
  },
];

const modalInitialState: ModalState = {
  sms: false,
  email: false,
};

function modalReducer(state: ModalState, action: ModalAction): ModalState {
  const newState = { ...state };
  newState[action.field] = action.open;
  return newState;
}
