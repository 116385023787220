import React, { Fragment, useState } from "react";
import { oldCrmCustomersUrl, txsStatsUrl } from "constants/urls";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import { DateTime } from "luxon";
import DateSelector from "../common/DateSelector";
import Grid from "@mui/material/Grid";
import Spinner from "../common/Spinner";
import Checkbox from "../common/fields/CheckboxBase";
import { useSimpleFetch } from "hooks/useSimpleFetch";
import TableSortLabel from "@mui/material/TableSortLabel";

export default function TxsStats() {
  const { state, fetch, sort } = useSimpleFetch();
  const { isFetching, data, sorting, errorMessage } = state;
  const [date, setDate] = useState({
    startDate: DateTime.local().minus({ days: 1 }),
    endDate: DateTime.local(),
  });

  const [checked, setChecked] = useState(false);

  function handleCheckBoxChange() {
    setChecked((f) => !f);
  }

  const handleChange = (field) => (value) => {
    setDate({ ...date, [field]: value });
  };

  function handleClick() {
    const config = {
      params: {
        start_date: date.startDate.toISODate(),
        end_date: date.endDate.toISODate(),
        failed: checked ? checked : null,
      },
    };
    fetch(txsStatsUrl, config);
  }

  const fields = [
    {
      name: "created",
      label: "Created",
      type: "Date",
    },
    {
      name: "amount",
      label: "Amount",
      type: "Number",
    },
    {
      name: "currency",
      label: "Currency",
    },
    {
      name: "status",
      label: "Status",
    },
    {
      name: "response",
      label: "Response",
    },
    {
      name: "cc_type",
      label: "CC type",
    },
    {
      name: "merchant",
      label: "Merchant",
    },
    {
      name: "user",
      label: "User",
    },
  ];

  return (
    <Fragment>
      <Grid
        container
        alignItems="flex-end"
        justify={"center"}
        style={{ marginBottom: 20 }}
        spacing={10}
      >
        <Grid item>
          <DateSelector
            date={date.startDate}
            handleChange={handleChange("startDate")}
            label={"Select start date"}
          />
        </Grid>
        <Grid item>
          <DateSelector
            date={date.endDate}
            handleChange={handleChange("endDate")}
            label={"Select end date"}
          />
        </Grid>
        <Grid item>
          <Checkbox
            checked={checked}
            label={"Failed only"}
            handleChange={handleCheckBoxChange}
          />
        </Grid>
        <Grid item>
          <Button color="secondary" variant="contained" onClick={handleClick}>
            Search
          </Button>
        </Grid>
      </Grid>
      {isFetching ? (
        <Spinner />
      ) : (
        data && (
          <Table>
            <TableHead>
              <TableRow>
                {fields.map((field) => (
                  <TableCell align="center" key={field.name}>
                    <TableSortLabel
                      active={sorting.field === field.name}
                      direction={sorting.isAscending ? "asc" : "desc"}
                      onClick={sort(field)}
                    >
                      {field.label}
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((item, index) => (
                <TableRow key={index + "asd"}>
                  <TableCell align="center">{item.created}</TableCell>
                  <TableCell align="center">{item.amount}</TableCell>
                  <TableCell align="center">{item.currency}</TableCell>
                  <TableCell align="center">{item.status}</TableCell>
                  <TableCell align="center">{item.response}</TableCell>
                  <TableCell align="center">{item.cc_type}</TableCell>
                  <TableCell align="center">{item.merchant}</TableCell>
                  <TableCell align="center">
                    <a
                      style={{ color: "#000" }}
                      href={`${oldCrmCustomersUrl}${item.user}`}
                      target={"_blank"}
                    >
                      {item.user}
                    </a>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )
      )}
    </Fragment>
  );
}
