import React, {
  useReducer,
  createContext,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  callcenterInitialState,
  callcenterReducer,
  UPDATE_QUEUE_LENGTH,
} from "context/CallcenterContext/callcenterReducer";
import { callcenterWsUrl } from "constants/urls";
import { makeStyles } from "@mui/styles";

export const CallcenterContext = createContext();

const useStyles = makeStyles({
  root: {
    "& iframe": {
      display: "none",
    },
  },
});

export default function CallcenterContextProvider({ children }) {
  const [state, dispatch] = useReducer(
    callcenterReducer,
    callcenterInitialState
  );

  const classes = useStyles();
  const ref = useRef();
  const [agent, setAgent] = useState();
  const [contact, setContact] = useState();
  const connect = window.connect;

  // useEffect(() => {
  //   if (connect) {
  //     connect.core.initCCP(ref.current, {
  //       ccpUrl: "https://etvnet.awsapps.com/connect/ccp#/",
  //       softphone: { allowFramedSoftphone: true }
  //     });
  //     connect.agent(agent => setAgent(agent));
  //     connect.contact(contact => setContact(contact));
  //   }
  // }, [connect]);

  useEffect(() => {
    if (contact) {
      contact.onAccepted(() => {
        const initialConn = contact.getActiveInitialConnection();
        const endpoint = initialConn.getEndpoint();
        const attributes = contact.getAttributes();
        console.log(attributes);
        if (endpoint) {
          console.log(endpoint.phoneNumber);
          const userId = attributes.userId;
          userId && window.open(`/customers/${userId.value}`);
        }
      });
    }
  }, [contact]);

  const context = { state, dispatch, agent, contact };
  // Exposing for test
  window.agent = agent;
  window.contact = contact;

  // useEffect(() => {
  //   const socket = new WebSocket(callcenterWsUrl);
  //   socket.addEventListener("message", event => {
  //     const count = parseInt(event.data);
  //     dispatch({ type: UPDATE_QUEUE_LENGTH, count });
  //   });
  // }, []);

  return (
    <CallcenterContext.Provider value={context}>
      {children}
      <div ref={ref} className={classes.root} />
    </CallcenterContext.Provider>
  );
}
