import { Grid, Typography, Alert, AlertTitle } from "@mui/material";
import React, { useEffect, useState, useCallback, useRef } from "react";
import { useIndex } from "./VodIndexContext";
import IndexItem from "./IndexItem";
import { fetchIndex } from "./api";
import debounce from "lodash.debounce";
import Spinner from "components/common/Spinner";

export default function Index({ name }) {
  const index = useIndex();
  const [result, setResult] = useState([]);
  const [duration, setDuration] = useState("");
  const [status, setStatus] = useState("success");
  const prevQueryRef = useRef();

  const debounceFetch = useCallback(debounce(fetchIndex, 500), []);

  useEffect(() => {
    if (index.query && prevQueryRef.current !== index) {
      setStatus("fetching");
      debounceFetch({
        ...index,
        index: name,
        cb: ({ hits, duration }) => {
          setStatus("success");
          setResult(hits);
          setDuration(duration);
        },
        error: (e) => {
          setStatus("error");
        },
      });
    }
  }, [index, name, debounceFetch]);

  useEffect(() => {
    prevQueryRef.current = index;
  });

  return (
    <Grid item container xs={3} alignContent="flex-start">
      {status === "fetching" && (
        <Spinner xs={{ height: "100wh", alignSelf: "center" }} />
      )}
      {status === "success" && (
        <>
          <Typography variant="h4">{name}</Typography>
          <Grid item alignSelf="center" marginLeft="auto">
            {duration}
          </Grid>
          <Grid item container>
            {result && result.map((el) => <IndexItem key={el.id} {...el} />)}
          </Grid>
        </>
      )}
      {status === "error" && (
        <Grid item xs={12}>
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            Something went wrong
          </Alert>
        </Grid>
      )}
    </Grid>
  );
}
