import React, { memo } from "react";
import MuiTextField from "@mui/material/TextField/TextField";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  label: {
    backgroundColor: "white",
  },
});

function TextFieldBase({
  type,
  label,
  maxLength,
  error,
  helperText,
  autoComplete,
  name,
  handleChange,
  handleFocus,
  handleBlur,
  value,
  multiline,
  disabled,
}) {
  const classes = useStyles();

  return (
    <MuiTextField
      error={error}
      type={type}
      autoComplete={autoComplete}
      name={name}
      label={label}
      fullWidth
      margin="normal"
      variant="outlined"
      value={value}
      disabled={disabled}
      rowsMax={"4"}
      multiline={multiline}
      helperText={error ? helperText : ""}
      onChange={handleChange}
      onBlur={handleBlur}
      onFocus={handleFocus}
      inputProps={{
        maxLength,
        classes: {
          root: classes.label,
        },
      }}
    />
  );
}

export default memo(TextFieldBase);
