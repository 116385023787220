import React from "react";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  root: {
    marginTop: 16,
    display: (props) => (props.display ? "block" : "none"),
    width: "100%",
    height: 525,
  },

  phone: {
    "& iframe": {
      borderWidth: 0,
    },
    height: 520,
  },
});

export default function SoftPhone(props) {
  const classes = useStyles({ display: props.display });

  return (
    <div className={classes.root}>
      <div id={"callcenter"} className={classes.phone} />
    </div>
  );
}
