import React from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import etvClient from "config/etvClient";
import { GetCustomerInvoice } from "customers/types/Customer";
import { GET_CUSTOMER_INVOICE } from "customers/queries";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import { makeStyles } from "@mui/styles";
import EmailIcon from "@mui/icons-material/Email";
import Divider from "@mui/material/Divider";
import Spinner from "components/common/Spinner";

const useStyles = makeStyles((theme) => ({
  iconButton: {
    "&:hover": {
      backgroundColor: "transparent",
    },
    padding: 0,
  },
}));

export default function BillingInvoiceDetails() {
  const classes = useStyles();
  const { customerId } = useParams();

  const { loading, error, data } = useQuery<GetCustomerInvoice>(
    GET_CUSTOMER_INVOICE,
    {
      variables: { id: customerId },
      client: etvClient,
    }
  );
  const invoice = data?.customer.invoice;
  const taxAmount = (invoice?.amountDue ?? 0) - (invoice?.amount ?? 0);

  if (loading) return <Spinner />;
  if (error || !invoice) return null;

  return (
    <div style={{ marginTop: 50 }}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h5">Invoices</Typography>
        <IconButton className={classes.iconButton}>
          <EmailIcon />
        </IconButton>
      </div>
      <Divider />
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="left">Period</TableCell>
            <TableCell align="left">Plan name</TableCell>
            <TableCell align="right">Sum</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell align="left">
              {invoice.start} - {invoice.end}
            </TableCell>
            <TableCell align="left">
              {invoice.planName} ({invoice.totalTime})
            </TableCell>
            <TableCell align="right">
              ${invoice.amount.toFixed(2)} {invoice.currency}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell rowSpan={1} />
            <TableCell align="right">Tax</TableCell>
            <TableCell align="right">
              ${taxAmount.toFixed(2)} {invoice.currency}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell />
            <TableCell align="right">Total</TableCell>
            <TableCell align="right">
              ${invoice.amountDue.toFixed(2)} {invoice.currency}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );
}
