import React from "react";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/AddCircle";
import Divider from "@mui/material/Divider";
import { makeStyles } from "@mui/styles";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import EmailIcon from "@mui/icons-material/Email";

import Account from "./BillingTab/Account";
import CustomerBillingInfoDetails from "customers/components/CustomerBillingInfoDetails";
import BillingInvoiceDetails from "customers/components/BillingInvoiceDetails";

const useStyles = makeStyles((theme) => ({
  iconButton: {
    "&:hover": {
      backgroundColor: "transparent",
    },
    padding: 0,
  },
}));

export default function Billing(props) {
  const classes = useStyles();
  return (
    <div>
      <Account />

      <CustomerBillingInfoDetails />

      <BillingInvoiceDetails />
    </div>
  );
}
