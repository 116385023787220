import React, { useState } from "react";
import { AppBar } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Toolbar from "@mui/material/Toolbar";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import ConfirmModal from "components/ConfirmModal";

const useStyles = makeStyles({
  root: {
    top: "auto",
    bottom: 0,
  },
});

export interface ActionButtonProps {
  label?: string;
  title?: string;
  body?: string;
  onClick: () => void;
  disabled?: boolean;
}

export interface ActionButtonsBarProps {
  back: ActionButtonProps;
  save: ActionButtonProps;
  del: ActionButtonProps;
}

export default function ActionButtonsBar({
  back,
  save,
  del,
}: ActionButtonsBarProps) {
  const classes = useStyles();
  const [modal, setModal] = useState({
    save: false,
    delete: false,
  });

  const openModal = (type: string) => () => {
    setModal((m) => ({ ...m, [type]: true }));
  };
  const closeModal = (type: string) => () => {
    setModal((m) => ({ ...m, [type]: false }));
  };
  function handleSaveConfirm() {
    save.onClick();
    closeModal("save")();
  }
  function handleDeleteConfirm() {
    del.onClick();
    closeModal("delete")();
  }

  return (
    <div>
      <AppBar position="fixed" color="transparent" className={classes.root}>
        <Toolbar>
          <Grid container>
            <Grid item xs>
              <Button
                onClick={openModal("delete")}
                variant={"contained"}
                color={"secondary"}
                disabled={del.disabled}
              >
                {del.label || "Delete"}
              </Button>
            </Grid>
            <Grid item xs style={{ display: "flex", justifyContent: "end" }}>
              <Button onClick={back.onClick}>{back.label || "Back"}</Button>
              <Button
                disabled={save.disabled}
                onClick={openModal("save")}
                variant={"contained"}
                color={"primary"}
              >
                {save.label || "Save"}
              </Button>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      {/*Needs to be here according to docs*/}
      <Toolbar />
      <ConfirmModal
        onCancel={closeModal("save")}
        onConfirm={handleSaveConfirm}
        open={modal.save}
        title={save.title || "Confirmation"}
        body={save.body || "Are you sure you want to save?"}
      />
      <ConfirmModal
        onCancel={closeModal("delete")}
        onConfirm={handleDeleteConfirm}
        open={modal.delete}
        title={del.title || "Confirmation"}
        body={del.body || "Are you sure you want to delete?"}
      />
    </div>
  );
}
