import { Grid, Typography } from "@mui/material";
import React, { useState } from "react";

export default function IndexItem({ id, name, description, type, score }) {
  const [showDesc, setShowDesc] = useState(false);
  return (
    <Grid item container rowGap="8px" marginBottom="12px">
      <Grid item xs={12}>
        <Typography variant="h5">{name}</Typography>
      </Grid>
      <Grid item xs={12}>
        Type: {type === "c" ? "Container" : "Object"}
      </Grid>
      <Grid item xs={12}>{`Score: ${Math.round(score * 100) / 100}`}</Grid>
      <Grid item xs={12}>
        <a href={`#${id}`} onClick={() => setShowDesc(!showDesc)}>
          Show description {!showDesc && ">"}
        </a>
        <br />
        {showDesc ? description : null}
      </Grid>
    </Grid>
  );
}
