import { ApolloClient, HttpLink, InMemoryCache } from "@apollo/client";
import { liveGraphQLDomain } from "constants/urls";

const apolloClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: new HttpLink({
    uri: liveGraphQLDomain,
    // headers: { "X-Api-Key":  }
  }),
});

export default apolloClient;
