import React, { useEffect, useMemo, useState } from "react";
import MenuItem from "@mui/material/MenuItem/MenuItem";
import TextField from "@mui/material/TextField/TextField";
import { UPDATE_FIELD } from "reducers/formReducer";
import { countryToFlag } from "utils/helpers";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  menu: {
    height: 400,
  },
}));

export default function Country({
  handleChange,
  dispatch,
  handleFocus,
  autoComplete,
  name,
  country,
  manuallyChanged,
  countries,
}) {
  const classes = useStyles();
  useEffect(() => {
    if (country.value && manuallyChanged.current) {
      dispatch({
        type: UPDATE_FIELD,
        field: "state",
        payload: {
          value: "",
        },
      });
    }
  }, [country.value]);

  const options = useMemo(
    () =>
      countries.map((country) => (
        <MenuItem key={country.name} value={country.iso}>
          {country.name} {countryToFlag(country.iso)}
        </MenuItem>
      )),
    [countries]
  );

  return (
    <TextField
      error={country.error}
      autoComplete={autoComplete}
      name={name}
      select
      fullWidth
      label={country.label}
      value={country.value}
      onChange={handleChange("country")}
      margin="normal"
      variant="outlined"
      helperText={country.error ? country.helperText : ""}
      onFocus={handleFocus("country")}
      SelectProps={{
        MenuProps: {
          className: classes.menu,
        },
      }}
    >
      {options}
    </TextField>
  );
}
