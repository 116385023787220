import { Agent, Contact, Queue, Customer, Callback } from "callcenter/types";

export const INIT = "INIT";
export const CALLBACK = "CALLBACK";
export const STATE_CHANGE = "STATE_CHANGE";
export const SET_AGENT = "SET_AGENT";
export const SET_CONTACT = "SET_CONTACT";
export const SET_CUSTOMERS = "SET_CUSTOMERS";
export const DELETE_CALLBACK = "DELETE_CALLBACK";

export interface CallCenterState {
  agent?: connect.Agent;
  contact?: connect.Contact;
  agents: Agent[];
  queues: Queue[];
  customers: Customer[];
  callbacks: Callback[];
}

type InitPayload = {
  agents: Agent[];
  queues: Queue[];
};

type CallbackPayload = {
  callbacks: Callback[];
};

type StateChangePayload = {
  agent: Agent;
  queues: Queue[];
};

type Action =
  | { type: typeof INIT; payload: InitPayload }
  | { type: typeof CALLBACK; payload: CallbackPayload }
  | { type: typeof STATE_CHANGE; payload: StateChangePayload }
  | { type: typeof SET_AGENT; payload: connect.Agent }
  | { type: typeof SET_CONTACT; payload: connect.Contact }
  | { type: typeof SET_CUSTOMERS; payload: Customer[] }
  | { type: typeof DELETE_CALLBACK; payload: Callback };

export default function callcenterReducer(
  state: CallCenterState,
  action: Action
) {
  console.log(action.type);
  switch (action.type) {
    case INIT:
      return {
        ...state,
        agents: action.payload.agents,
        queues: action.payload.queues,
      };
    case CALLBACK:
      return {
        ...state,
        callbacks: action.payload.callbacks,
      };
    case STATE_CHANGE:
      const { agent } = action.payload;
      return {
        ...state,
        queues: action.payload.queues,
        agents: state.agents.map((a) => {
          if (a.username === agent.username) {
            a.status = agent.status;
          }
          return a;
        }),
      };
    case SET_AGENT:
      return {
        ...state,
        agent: action.payload,
      };
    case SET_CONTACT:
      return {
        ...state,
        contact: action.payload,
      };
    case SET_CUSTOMERS:
      return {
        ...state,
        customers: action.payload,
      };
    case DELETE_CALLBACK:
      const p = action.payload;
      return {
        ...state,
        callbacks: state.callbacks.filter(
          (c) => !(c.date === p.date && c.phoneNumber === p.phoneNumber)
        ),
      };

    default:
      return state;
  }
}
