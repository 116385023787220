import React from "react";
import Grid from "@mui/material/Grid/Grid";
import Month from "./Month";
import Year from "./Year";
import { UPDATE_FIELD } from "reducers/formReducer";

export default function ExpirationDate(props) {
  const { state, dispatch } = props;

  const handleChange = (field) => (event) => {
    state[field].value !== event.target.value &&
      dispatch({
        type: UPDATE_FIELD,
        field,
        payload: {
          value: event.target.value,
        },
      });
  };

  const handleFocus = (field) => () => {
    dispatch({ type: UPDATE_FIELD, field, payload: { error: false } });
  };

  return (
    <Grid spacing={1} container>
      <Grid item xs={6}>
        <Month
          {...state}
          handleChange={handleChange}
          dispatch={dispatch}
          handleFocus={handleFocus}
        />
      </Grid>
      <Grid item xs={6}>
        <Year
          {...state}
          handleChange={handleChange}
          handleFocus={handleFocus}
        />
      </Grid>
    </Grid>
  );
}
