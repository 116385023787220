import React from "react";
import { TextField } from "@mui/material";
import { useIndex, useIndexDispatch } from "./VodIndexContext";

export default function VodIndexInput() {
  const { query } = useIndex();
  const dispatch = useIndexDispatch();

  function handleChange(e) {
    dispatch({
      type: "option",
      option: "query",
      value: e.target.value,
    });
  }

  return (
    <TextField
      placeholder="Search in indexes"
      fullWidth
      value={query}
      label="Query"
      onChange={handleChange}
    />
  );
}
