import React, { useMemo } from "react";
import MenuItem from "@mui/material/MenuItem/MenuItem";
import TextField from "@mui/material/TextField/TextField";
import { UPDATE_FIELD } from "reducers/formReducer";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  menu: {
    height: 400,
  },
}));

export default function SelectField({
  dispatch,
  autoComplete,
  name,
  field,
  value,
  label,
  error,
  helperText,
  options,
  disabled = false,
  multiple = false,
}) {
  const classes = useStyles();

  const menuItems = useMemo(
    () =>
      options.map((o, index) => (
        <MenuItem key={o + index} value={o}>
          {o}
        </MenuItem>
      )),
    [options]
  );

  function handleChange(event) {
    value !== event.target.value &&
      dispatch({
        type: UPDATE_FIELD,
        field,
        payload: {
          value: event.target.value,
        },
      });
  }

  function handleFocus() {
    dispatch({ type: UPDATE_FIELD, field, payload: { error: false } });
  }

  return (
    <TextField
      error={error}
      autoComplete={autoComplete}
      name={name}
      disabled={disabled}
      select
      fullWidth
      label={label}
      value={value}
      onChange={handleChange}
      margin="normal"
      variant="outlined"
      helperText={error ? helperText : ""}
      onFocus={handleFocus}
      SelectProps={{
        MenuProps: {
          className: classes.menu,
        },
        multiple,
      }}
    >
      {menuItems}
    </TextField>
  );
}
