import React, { Fragment, useRef, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import SendIcon from "@mui/icons-material/Send";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import Chip from "@mui/material/Chip";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { makeStyles } from "@mui/styles";
import { smsUrl } from "constants/urls";
import { _fetch } from "hooks/useFetch";
import { successAlert, errorAlert } from "components/common/Alert";
import { Customer, ModalAction } from "customers/types/Customer";

const useStyles = makeStyles((theme) => ({
  dialog: {
    textAlign: "center",
    marginTop: 20,
  },
  button: {
    margin: theme.spacing(1),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  backdrop: {
    zIndex: theme.zIndex.modal,
  },
  container: {
    height: 500,
  },
  grid: {
    marginTop: 20,
    textAlign: "left",
    justify: "center",
  },
  textfield: {
    width: "300px",
  },
  textarea: {
    width: "500px",
    marginTop: 50,
  },
  dialogActions: {
    justifyContent: "center",
  },
}));

export default function SmsModal({
  open,
  dispatch,
  agent,
  phones,
  customer,
}: {
  open: boolean;
  dispatch: React.Dispatch<ModalAction>;
  agent: string;
  phones: string[];
  customer: Customer;
}) {
  const classes = useStyles();
  const { username } = customer;
  const [backdrop, setBackdropOpen] = useState(false);

  const [existingPhones, setExistingPhones] = useState([...phones]);
  const initialPhones = useRef(existingPhones);
  const [text, setText] = useState("");
  const templates = [
    {
      name: "фин препей",
      text: `eTVnet: Предоплата, сделанная вами на аккаунте ${username} подходит к концу. Свяжитесь с нами по телефону +1 (888) 311-7811`,
    },
    {
      name: "фин дебт",
      text: `eTVnet: Сервис на аккаунте ${username} приостановлен из-за задолженности. Свяжитесь с нами по телефону +1 (888) 311-7811`,
    },
    {
      name: "замена карты",
      text: `eTVnet: Срок действия кредитной карты на аккаунте ${username} истекает. Пожалуйста, обновите данные в личном кабинете на сайте.`,
    },
    {
      name: "инф",
      text: `eTVnet: Ethnic Television Network / Matvil Corp. 312 Dolomite Dr. Unit 215, Toronto, ON, CA, M3J 2N2`,
    },
    {
      name: "инф (USA)",
      text: `eTVnet: +1 (888) 311-7811, 9am-11pm`,
    },
    {
      name: "инф (Canada)",
      text: `eTVnet: +1 (888) 311-7811, 9am-11pm`,
    },
    {
      name: "Свободный план",
      text: `eTVnet: Спасибо что вы были с нами! Мы хотим предоставить вам переход на "Свободный план", полный доступ ко всем каналам в прямом эфире и к архиву за 0.60 центов в час. Если вы не смотрите, вы не платите. Для активации плана свяжитесь с нами по телефону +1 (888) 311-7811`,
    },
  ];

  function handleTextChange(event: React.ChangeEvent<HTMLTextAreaElement>) {
    setText(event.target.value);
  }

  function handleTemplateChange(event: React.ChangeEvent<HTMLInputElement>) {
    setText(event.target.value);
  }

  function handleClose() {
    dispatch({ field: "sms", open: false });
    // avoid flickering
    setTimeout(() => {
      setExistingPhones(initialPhones.current);
      setText("");
    }, 200);
  }

  const handleDelete = (phone: string) => () => {
    setExistingPhones(existingPhones.filter((ph) => ph !== phone));
  };

  const onSuccess = () => {
    setBackdropOpen(false);
    successAlert(`Successfully sent sms message to ${username}`);
  };

  const onFail = () => {
    setBackdropOpen(false);
    errorAlert(`Could not send sms message to ${username}`);
  };

  function handleSumbit() {
    const data = {
      agent: agent,
      phones: existingPhones,
      message: text,
      customer: `${username}`,
    };
    const config = {
      method: "POST",
      timeout: 5000,
      headers: {
        "x-api-key": "7RWazrhtwq7yA0emwQ64P4CVwDWu1YIK38raM7EP",
      },
      data,
    };
    handleClose();
    // setBackdropOpen(true);
    // _fetch({
    //   url: smsUrl,
    //   config,
    //   skipRetry: true,
    //   onFail: onFail,
    //   onError: onFail,
    //   onSuccess: onSuccess,
    // });
  }

  return (
    <Fragment>
      <Dialog
        fullWidth
        maxWidth="md"
        open={open}
        onClose={handleClose}
        className={classes.dialog}
        aria-labelledby="form-dialog-title"
      >
        <Container className={classes.container}>
          <DialogTitle id="form-dialog-title">
            Sending sms to user <strong>{username}</strong>{" "}
          </DialogTitle>
          {existingPhones.length > 0 ? (
            <DialogContent>
              {existingPhones.map((phone) => (
                <Chip
                  color={"primary"}
                  key={phone}
                  label={phone}
                  onDelete={handleDelete(phone)}
                  className={classes.chip}
                />
              ))}
              <Grid container className={classes.grid} justifyContent="center">
                <TextField
                  select
                  className={classes.textfield}
                  label="Template"
                  value={text}
                  onChange={handleTemplateChange}
                >
                  {templates.map((item) => (
                    <MenuItem key={item.name} value={item.text}>
                      {item.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <TextareaAutosize
                className={classes.textarea}
                value={text}
                onChange={handleTextChange}
                minRows={8}
                placeholder="Текст смс"
              />
            </DialogContent>
          ) : (
            <Typography variant="h6">No verified numbers available</Typography>
          )}
          <DialogActions className={classes.dialogActions}>
            <Button
              onClick={handleSumbit}
              disabled={!(existingPhones.length > 0)}
              variant="contained"
              color="secondary"
              className={classes.button}
            >
              Send
              <SendIcon className={classes.rightIcon} />
            </Button>
          </DialogActions>
        </Container>
      </Dialog>
      <Backdrop className={classes.backdrop} open={backdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Fragment>
  );
}
