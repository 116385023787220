import React, { Fragment, useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { MutationHookOptions } from "@apollo/client/react/types/types";
import { LIST_STAFF } from "admin/queries";
import { REMOVE_STAFF } from "admin/mutations";
import {
  User,
  ListStaff,
  RemoveUserFromStaff,
  RemoveUserFromStaffVars,
} from "admin/types/Staff";
import Spinner from "components/common/Spinner";
import ConfirmModal from "components/ConfirmModal/";
import Table from "components/Table";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import etvClient from "config/etvClient";
import { successAlert } from "components/common/Alert";

export default function UsersStaffList() {
  const [delId, setDelId] = useState(NaN);

  const { loading, error, data } = useQuery<ListStaff>(LIST_STAFF, {
    client: etvClient,
  });

  const [removeStaff] = useMutation<
    RemoveUserFromStaff,
    RemoveUserFromStaffVars
  >(REMOVE_STAFF, {
    ...removeOptions,
    client: etvClient,
    onCompleted: onRemoveCompleted,
  });

  const handleConfirm = () => {
    removeStaff({ variables: { id: delId } });
    setDelId(NaN);
  };

  const actionComponent = (row: User) => (
    <IconButton onClick={() => setDelId(row.id)}>
      <DeleteIcon />
    </IconButton>
  );

  if (loading) return <Spinner />;
  if (error) return <div>Error! {error.message}</div>;
  if (!data) return <div>No Data Available</div>;

  return (
    <Fragment>
      <Table<User>
        data={data.users}
        columns={columns}
        actionComponent={actionComponent}
        hover={false}
      />
      <ConfirmModal
        onCancel={() => setDelId(NaN)}
        onConfirm={handleConfirm}
        open={!isNaN(delId)}
        title={"Confirmation"}
        body={`Are you sure you want to delete ${
          data.users.find((u) => u.id === delId)?.username
        }`}
      />
    </Fragment>
  );
}

const removeOptions: MutationHookOptions<
  RemoveUserFromStaff,
  RemoveUserFromStaffVars
> = {
  update(cache, { data }) {
    const cached = cache.readQuery<ListStaff>({
      query: LIST_STAFF,
    });
    const existing = cached?.users;
    const toRemove = data?.removeUserFromStaff;
    existing &&
      toRemove &&
      cache.writeQuery({
        query: LIST_STAFF,
        data: {
          users: existing.filter((u) => u.id !== toRemove.id),
        },
      });
  },
};

const onRemoveCompleted = () => successAlert("Deleted successfully");

const columns = {
  id: {
    label: "Id",
  },
  username: {
    label: "User",
  },
  email: {
    label: "Email",
  },
  groups: {
    label: "Groups",
    format: (x: string[]) => x.join(", "),
  },
};
