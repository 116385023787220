// Types defined in material-ui api https://material-ui.com/api/table/
// | ***
export type Padding = "normal" | "checkbox" | "none";

export type Size = "small" | "medium";
// *** |

export enum Align {
  Center = "center",
  Inherit = "inherit",
  Justify = "justify",
  Left = "left",
  Right = "right",
}

export interface ColumnOptions {
  label?: string;
  align?: Align;
  sort?: (field: any, asc: boolean, type: string) => (a: any, b: any) => number;
  format?: (raw: any) => string;
}

export type Columns<T> = {
  [P in keyof T]: ColumnOptions;
};

// Data should have all the columns of T but can have more so you
// don't have to filter the object
export type TableData<Display, Data extends Display = Display> = {
  [P in keyof Data]: Data[P];
};

// as defined in simpleReducer.js
export interface Sorting {
  isAscending: boolean;
  field: string;
}
