import React, { useContext, useMemo, useReducer, useState } from "react";
import { CustomerContext } from "components/Customer/Page/index";
import formReducer, { UPDATE_FORM } from "reducers/formReducer";
import { getFormData, getFormErrors } from "utils/helpers";
import { customerAddressUrl } from "constants/urls";
import { UPDATE_DATA_FIELD } from "constants/actions";
import Grid from "@mui/material/Grid";
import TextField from "components/common/fields/TextField";
import Button from "@mui/material/Button";
import SelectField from "components/common/fields/SelectField";
import Problem from "components/Customer/Page/Sidebar/LogCallTab/Problem";
import { DateTimePicker } from "@mui/lab";

export default function NewTask() {
  const [state, dispatch] = useReducer(formReducer, logCallFields);

  async function handleSubmit(event) {
    event.preventDefault();
    const errors = getFormErrors(state);
    if (errors) {
      console.log(errors);
      dispatch({ type: UPDATE_FORM, payload: errors });
    } else {
      let data = getFormData(state);
      console.log(data);
      // const config = {
      //   method: "POST",
      //   data
      // };
      // const now = new Date().toISOString();
      // data = { ...data, created: data.created || now, updated: now };
      // const id = customerContext.customerId;
      // const url = customerAddressUrl.replace(":customerId", id);
      // const onError = () => {
      //   const message = "Failed to update";
      //   messageContext.dispatch({
      //     type: SHOW_MESSAGE,
      //     message,
      //     variant: "error"
      //   });
      // };
      // const onSuccess = () => {
      //   messageContext.dispatch({
      //     type: SHOW_MESSAGE,
      //     message: "Updated successfully",
      //     variant: "success"
      //   });
      //   customerContext.dispatch({
      //     type: UPDATE_DATA_FIELD,
      //     data,
      //     field: "address"
      //   });
      // };
      //
      // await fetch({ url, config, onSuccess, onError });
    }
  }
  const [selectedDate, handleDateChange] = useState(new Date());

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <SelectField
          {...state.action}
          dispatch={dispatch}
          options={actionOptions}
        />
        <div style={{ marginTop: 16 }}>
          {/*<DateTimePicker*/}
          {/*  label="Due date"*/}
          {/*  variant="outlined"*/}
          {/*  value={selectedDate}*/}
          {/*  onChange={handleDateChange}*/}
          {/*/>*/}
        </div>
        <SelectField
          {...state.agent}
          dispatch={dispatch}
          options={agentOptions}
        />
        <SelectField
          {...state.priority}
          dispatch={dispatch}
          options={priorityOptions}
        />
        <SelectField
          {...state.category}
          dispatch={dispatch}
          options={categoryOptions}
        />
        <TextField {...state.comments} dispatch={dispatch} />
        <Grid container justify={"center"}>
          <Button variant="contained" type="submit" color="primary">
            Submit
          </Button>
        </Grid>
      </form>
    </div>
  );
}
const actionOptions = ["Call", "SMS", "Email", "Action"];
const agentOptions = ["Vasya", "Petya"];
const priorityOptions = ["High", "Normal", "Low"];
const categoryOptions = ["Financial", "Technical", "General"];
const financialOptions = ["Prepay", "Debt", "Cancel", "Return", "Other"];
const technicalOptions = ["Other"];
const generalOptions = ["Send box", "Other"];

const logCallFields = {
  action: {
    value: "",
    field: "action",
    label: "Action",
    error: false,
    helperText: "Please select action",
  },
  agent: {
    value: "",
    field: "agent",
    label: "Agent",
    error: false,
    helperText: "Please select agent",
  },
  priority: {
    value: "",
    field: "priority",
    label: "Priority",
    error: false,
    helperText: "Please select priority",
  },
  category: {
    value: "",
    field: "category",
    label: "Category",
    error: false,
    helperText: "Please select category",
  },
  comments: {
    value: "",
    field: "comments",
    label: "Comments",
    maxLength: 500,
    blank: true,
    multiline: true,
  },
};
