export const UPDATE_QUEUE_LENGTH = "UPDATE_QUEUE_LENGTH";

export const callcenterInitialState = {
  count: 0
};

export function callcenterReducer(state, action) {
  switch (action.type) {
    case UPDATE_QUEUE_LENGTH:
      return {
        ...state,
        count: action.count
      };
    default:
      return state;
  }
}
