import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { createSlice } from "@reduxjs/toolkit";
import HoverlessIconButton from "components/common/HoverlessIconButton";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import CheckIcon from "@mui/icons-material/Check";
import Divider from "@mui/material/Divider";
import EditIcon from "@mui/icons-material/Edit";

import { _fetch } from "hooks/useFetch";
import { customerBillingUrl } from "constants/urls";
import AccountForm from "components/Customer/Page/Main/BillingTab/AccountForm";

export default function Account() {
  const dispatch = useDispatch();
  const { customerId } = useParams();
  const billing = useSelector((state) => state.billing);

  const [edit, setEdit] = useState(false);

  useEffect(() => {
    fetchBilling();
  }, []);

  async function fetchBilling() {
    const onSuccess = (account) => {
      return dispatch(showSuccessBilling(account));
    };

    await _fetch({
      url: customerBillingUrl.replace(":customerId", customerId),
      onSuccess,
    });
  }

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h5">Account</Typography>
        <HoverlessIconButton onClick={() => setEdit(true)}>
          <EditIcon />
        </HoverlessIconButton>
      </div>
      <Divider />
      {edit ? (
        <AccountForm closeEdit={() => setEdit(false)} />
      ) : (
        <Grid container spacing={2} style={{ padding: "2%" }}>
          <Grid item lg={2} style={{ textAlign: "right" }}>
            <Typography variant="body1">
              <strong>Plan name:</strong>{" "}
            </Typography>
            <Typography variant="body1">
              <strong>Plan status:</strong>
            </Typography>
            <Typography variant="body1">
              <strong>Merchant:</strong>
            </Typography>
            <Typography variant="body1">
              <strong>Tax:</strong>
            </Typography>
            <Typography variant="body1">
              <strong>Balance:</strong>
            </Typography>
            <Typography variant="body1">
              <strong>Bad debt:</strong>
            </Typography>
            <Typography variant="body1">
              <strong>Billing status:</strong>
            </Typography>
            <Typography variant="body1">
              <strong>Billing day:</strong>
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1">{billing.planName || "N/A"}</Typography>
            <Typography variant="body1">
              {billing.planStatus || "N/A"}
            </Typography>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Typography variant="body1">
                {billing.merchant || "N/A"}
              </Typography>
              {billing.merchant && (
                <CheckIcon fontSize={"inherit"} style={{ color: "green" }} />
              )}
            </div>
            <Typography variant="body1">
              {billing.taxName
                ? billing.taxName + " - " + billing.taxRate.toFixed(2) + "%"
                : "N/A"}
            </Typography>
            <Typography variant="body1">
              ${billing.balance.toFixed(2)}
            </Typography>
            <Typography variant="body1">
              ${billing.badDept.toFixed(2)}
            </Typography>
            <Typography variant="body1">
              {billing.billingStatus || "N/A"}
            </Typography>
            <Typography variant="body1">{billing.billingDay}</Typography>
          </Grid>
        </Grid>
      )}
    </div>
  );
}

const initialState = {
  planId: void 0,
  planName: "",
  planStatusId: void 0,
  planStatus: "",
  merchant: "",
  taxName: "",
  taxRate: 0,
  balance: 0,
  badDept: 0,
  billingStatus: "",
  billingDay: 0,
};

const billing = createSlice({
  name: "billing",
  initialState,
  reducers: {
    showSuccessBilling: (state, { payload }) => {
      const account = payload.account;
      return {
        planId: account.plan_id,
        planName: account.plan_name,
        planStatusId: account.plan_status_id,
        planStatus: account.plan_status,
        merchant: account.merchant,
        taxName: account.tax_name,
        taxRate: account.tax_rate,
        balance: account.balance,
        badDept: account.bad_debt,
        billingStatusId: account.billing_status_id,
        billingStatus: account.billing_status,
        billingDay: account.billing_day,
      };
    },
    updateBillingPlan: (state, { payload }) => {
      return {
        ...state,
        planId: payload.planId,
        planName: payload.planName,
        planStatus: payload.planStatus,
      };
    },
  },
});

export const { showSuccessBilling, updateBillingPlan } = billing.actions;
export const billingReducer = billing.reducer;
