import { Grid, Paper } from "@mui/material";
import { IndexProvider } from "./VodIndexContext";
import React from "react";
import VodIndexesTable from "./VodIndexesTable";
import VodIndexInput from "./VodIndexInput";
import VodIndexesOptions from "./VodIndexesOptions";

export default function VodIndexes() {
  return (
    <Paper sx={{ padding: 2 }}>
      <IndexProvider>
        <Grid container item spacing={3}>
          <Grid item xs={5}>
            <VodIndexInput />
          </Grid>
          <Grid item xs={7}>
            <VodIndexesOptions />
          </Grid>
          <VodIndexesTable />
        </Grid>
      </IndexProvider>
    </Paper>
  );
}
