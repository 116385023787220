import React, { FormEvent, useReducer, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useMutation } from "@apollo/client";
import tasksApolloClient from "config/tasksApolloClient";
import { AddTask, TaskInput, TaskVars } from "customers/types/Customer";
import { MutationHookOptions } from "@apollo/client/react/types/types";
import { CREATE_TASK } from "customers/mutations";

import TF from "@mui/material/TextField";
import TextField from "components/common/fields/TextField";
import SelectField from "components/common/fields/SelectField";
import formReducer, { FormState, UPDATE_FORM } from "reducers/formReducer";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Backdrop from "@mui/material/Backdrop";
import Spinner from "components/common/Spinner";
import { DateTimePicker } from "@mui/lab";
import { getFormErrors } from "utils/helpers";
import { DateTime } from "luxon";
import { errorAlert, successAlert } from "components/common/Alert";

export interface TaskFormProps {
  close: () => void;
}

export default function TaskForm({ close }: TaskFormProps) {
  const [state, dispatch] = useReducer(formReducer, logCallFields);
  const [selectedDate, handleDateChange] = useState<DateTime | null>(
    DateTime.local()
  );
  const { customerId } = useParams();

  function handleSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();

    let errors = getFormErrors(state);
    if (errors) {
      dispatch({ type: UPDATE_FORM, payload: errors });
      return;
    }

    const input: TaskVars = {
      agent: state.agent.value,
      customerId: customerId,
      comments: state.comments.value,
      action: state.action.value,
      dueDate: selectedDate?.toISO() ?? "",
      priority: state.priority.value,
      isCompleted: false,
      category: state.category.value,
      problem: state.problem.value,
    };

    addTask({ variables: { input } });
  }

  const [addTask, { loading }] = useMutation<AddTask, TaskInput>(CREATE_TASK, {
    onCompleted: onAddCompleted,
    client: tasksApolloClient,
  });

  function onAddCompleted() {
    successAlert("Created successfully");
    close();
  }

  function getProblemOptions(category: string) {
    switch (category) {
      case "Financial":
        return financialOptions;
      case "Technical":
        return technicalOptions;
      case "General":
        return generalOptions;
      default:
        return [];
    }
  }

  return (
    <>
      <form onSubmit={handleSubmit}>
        <SelectField
          {...state.action}
          name=""
          dispatch={dispatch}
          options={actionOptions}
          autoComplete={""}
          error={state.action.error}
          helperText={state.action.helperText}
        />
        <div style={{ marginTop: 16 }}>
          <DateTimePicker
            label="Due Date"
            value={selectedDate}
            onChange={handleDateChange}
            renderInput={(props) => (
              <TF {...props} variant="outlined" fullWidth />
            )}
          />
        </div>
        <SelectField
          {...state.agent}
          name=""
          dispatch={dispatch}
          options={agentOptions}
          autoComplete={""}
          error={state.agent.error}
          helperText={state.agent.helperText}
        />
        <SelectField
          {...state.priority}
          name=""
          dispatch={dispatch}
          options={priorityOptions}
          autoComplete={""}
          error={state.priority.error}
          helperText={state.priority.helperText}
        />
        <SelectField
          {...state.category}
          name=""
          dispatch={dispatch}
          options={categoryOptions}
          autoComplete={""}
          error={state.category.error}
          helperText={state.category.helperText}
        />
        <SelectField
          {...state.problem}
          name=""
          dispatch={dispatch}
          options={getProblemOptions(state.category.value)}
          autoComplete={""}
          error={state.problem.error}
          helperText={state.problem.helperText}
        />
        <TextField {...state.comments} dispatch={dispatch} />
        <Grid container justifyContent="center">
          <Button variant="contained" type="submit" color="primary">
            Submit
          </Button>
        </Grid>
        <Backdrop style={{ zIndex: 9999 }} open={loading}>
          <Spinner />
        </Backdrop>
      </form>
    </>
  );
}

const actionOptions = ["Call", "SMS", "Email", "Action"];
const agentOptions = ["Vasya", "Petya"];
const priorityOptions = ["High", "Normal", "Low"];
const categoryOptions = ["Financial", "Technical", "General"];
const financialOptions = ["Prepay", "Debt", "Cancel", "Return", "Other"];
const technicalOptions = ["Other"];
const generalOptions = ["Send box", "Other"];

const logCallFields: FormState = {
  action: {
    value: "",
    field: "action",
    label: "Action",
    error: false,
    helperText: "Please select action",
  },
  agent: {
    value: "",
    field: "agent",
    label: "Agent",
    error: false,
    helperText: "Please select agent",
  },
  priority: {
    value: "",
    field: "priority",
    label: "Priority",
    error: false,
    helperText: "Please select priority",
  },
  category: {
    value: "",
    field: "category",
    label: "Category",
    error: false,
    helperText: "Please select category",
  },
  problem: {
    value: "",
    field: "problem",
    label: "Problem",
    error: false,
    helperText: "Please select problem",
  },
  comments: {
    value: "",
    field: "comments",
    label: "Comments",
    maxLength: 500,
    blank: true,
    multiline: true,
  },
};
