import React, { useState } from "react";
import TextFieldBase from "components/common/fields/TextFieldBase";
import { isEmail, sanitize } from "utils/helpers";
import { UPDATE_FIELD } from "reducers/formReducer";

export default function EmailField(props) {
  const { dispatch } = props;
  const [value, setValue] = useState(props.value);

  function handleChange(event) {
    setValue(event.target.value);
  }

  function handleFocus() {
    dispatch({
      type: UPDATE_FIELD,
      field: props.field,
      payload: { error: false }
    });
  }

  const handleBlur = field => event => {
    const value = event.target.value;
    const sanitizedValue = sanitize(value, props.pattern);
    setValue(sanitizedValue);
    if (isEmail(sanitizedValue)) {
      dispatch({
        type: UPDATE_FIELD,
        field,
        payload: { value: sanitizedValue }
      });
    } else if (sanitizedValue) {
      dispatch({ type: UPDATE_FIELD, field, payload: { error: true } });
    }
  };

  const additionalProps = {
    value,
    handleChange,
    handleFocus,
    handleBlur: handleBlur(props.field)
  };

  return <TextFieldBase {...props} {...additionalProps} />;
}
