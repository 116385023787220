import { gql } from "@apollo/client";

export const CREATE_CATEGORY = gql`
  mutation CreateCategory($input: CategoryInput!) {
    createCategory(input: $input) {
      id
      name
      order
      color
      bgColor
    }
  }
`;

export const DELETE_CATEGORY = gql`
  mutation DeleteCategory($id: Int!) {
    deleteCategory(id: $id) {
      id
    }
  }
`;

export const CREATE_TIMEZONE = gql`
  mutation CreateTimezone($input: TimezoneInput!) {
    createTimezone(input: $input) {
      name
      order
      city
      isActive
      longitude
      latitude
    }
  }
`;

export const DELETE_TIMEZONE = gql`
  mutation DeleteTimezone($name: String!) {
    deleteTimezone(name: $name) {
      name
    }
  }
`;

export const REMOVE_STAFF = gql`
  mutation RemoveUserFromStaff($id: Int!) {
    removeUserFromStaff(id: $id) {
      id
    }
  }
`;

export const REMOVE_USER = gql`
  mutation RemoveUserFromGroup($userId: Int!, $groupId: Int!) {
    removeUserFromGroup(userId: $userId, groupId: $groupId) {
      userId
    }
  }
`;

export const ADD_USER = gql`
  mutation AddUserToGroup($username: String!, $groupId: Int!) {
    addUserToGroup(username: $username, groupId: $groupId) {
      user {
        id
        username
        email
      }
    }
  }
`;
