import React, { Fragment } from "react";
import { Route, Redirect } from "react-router-dom";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import { loginPath } from "../constants/paths";
import Frame from "frame";
import { useSelector } from "react-redux";

export default function AuthenticatedRoute(props) {
  const user = useSelector((state) => state.user);

  return user?.token ? (
    <Fragment>
      <Box sx={{ display: "flex" }}>
        <Frame />
        <Container
          component="main"
          maxWidth={false}
          sx={{ flexGrow: 1, overflow: "auto" }}
        >
          <Toolbar />
          <Route {...props} />
        </Container>
      </Box>
    </Fragment>
  ) : (
    <Redirect to={loginPath} />
  );
}
