import React from "react";
import { UPDATE_FIELD } from "reducers/formReducer";
import CheckboxBase from "components/common/fields/CheckboxBase";

export default function Checkbox(props) {
  const { dispatch } = props;

  function handleChange(event) {
    dispatch({
      type: UPDATE_FIELD,
      field: props.field,
      payload: { checked: !props.checked }
    });
  }

  return <CheckboxBase {...props} handleChange={handleChange} />;
}
