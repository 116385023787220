import React, { Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import Login from "../components/Login";
import AnonymousRoute from "./AnonymousRoute";
import NotFound from "pages/NotFound";
import {
  entryPath,
  loginPath,
  notFoundPath,
  abTestingStatsPath,
  txsPath,
  searchCustomersPath,
  customerProfilePath,
  homePath,
  callcenterPath,
  // adminPath,
  adminLiveChannelsPath,
  adminLiveChannelPath,
  adminLiveCategoriesPath,
  adminLiveCategoryPath,
} from "constants/paths";
import AuthenticatedRoute from "./AuthenticatedRoute";
import AbTestingStats from "components/Stats/AbTestingStats";
import TxsStats from "components/Stats/TxsStats";
import Search from "components/Customer/Search";
import CustomerPage from "components/Customer/CustomerPage";
import Profile from "components/Customer/Page";
import Admin from "components/Admin";
import Channels from "components/Admin/Live/Channels";
import Channel from "components/Admin/Live/Channels/Channel";
import Categories from "components/Admin/Live/Categories";
import Category from "components/Admin/Live/Categories/Category";
import AdminSection from "admin";
import Home from "home";
import CallCenter from "callcenter";
import { adminPath } from "admin/urls";
import CustomersSection from "customers";
import { customersPath } from "customers/urls";

export default function Routes() {
  return (
    <Suspense fallback={""}>
      <Switch>
        <Redirect exact from={entryPath} to={homePath} />
        <AnonymousRoute
          component={Login}
          exact
          path={loginPath}
          redirect={homePath}
        />
        <AuthenticatedRoute
          title="Home"
          component={Home}
          exact
          path={homePath}
        />
        {/* <AuthenticatedRoute
          title="Home"
          component={Profile}
          exact
          path={homePath}
        /> */}
        <AuthenticatedRoute
          title="Call Center"
          component={CallCenter}
          exact
          path={callcenterPath}
        />
        <AuthenticatedRoute
          title="Stats"
          component={AbTestingStats}
          exact
          path={abTestingStatsPath}
        />
        <AuthenticatedRoute
          title="Transactions"
          component={TxsStats}
          exact
          path={txsPath}
        />
        <AuthenticatedRoute
          title="Search"
          component={Search}
          exact
          path={searchCustomersPath}
        />
        {/*<AuthenticatedRoute*/}
        {/*  title="Customer Profile"*/}
        {/*  component={Profile}*/}
        {/*  exact*/}
        {/*  path={customerProfilePath}*/}
        {/*/>*/}
        <AuthenticatedRoute component={CustomersSection} path={customersPath} />
        {/*<AuthenticatedRoute*/}
        {/*  title="Admin"*/}
        {/*  component={Admin}*/}
        {/*  exact*/}
        {/*  path={adminPath}*/}
        {/*/>*/}
        {/*<AuthenticatedRoute*/}
        {/*  title="Live Channels"*/}
        {/*  component={Channels}*/}
        {/*  exact*/}
        {/*  path={adminLiveChannelsPath}*/}
        {/*/>*/}
        {/*<AuthenticatedRoute*/}
        {/*  title="Live Channel"*/}
        {/*  component={Channel}*/}
        {/*  exact*/}
        {/*  path={adminLiveChannelPath}*/}
        {/*/>*/}
        {/*<AuthenticatedRoute*/}
        {/*  title="Live Categories"*/}
        {/*  component={Categories}*/}
        {/*  exact*/}
        {/*  path={adminLiveCategoriesPath}*/}
        {/*/>*/}
        {/*<AuthenticatedRoute*/}
        {/*  title="Live LiveCategory"*/}
        {/*  component={LiveCategory}*/}
        {/*  exact*/}
        {/*  path={adminLiveCategoryPath}*/}
        {/*/>*/}
        <AuthenticatedRoute
          title="Admin"
          component={AdminSection}
          path={adminPath}
        />
        <Route component={NotFound} exact path={notFoundPath} />
        <Redirect to={notFoundPath} />
      </Switch>
    </Suspense>
  );
}
