export const adminPath = "/admin/";
export const adminLivePath = adminPath + "live/";
export const adminUsersPath = adminPath + "users/";
export const adminVodPath = `${adminPath}vod/`;

export const adminLiveCategoriesPath = adminLivePath + "categories/";
export const adminLiveCategoryPath = (id: string) =>
  adminLiveCategoriesPath + id;

export const adminLiveTimezonesPath = adminLivePath + "timezones/";
export const adminLiveTimezonePath = (name: string) =>
  adminLiveTimezonesPath + name;

export const adminUsersStaffPath = adminUsersPath + "staff/";
export const adminUsersGroupsPath = adminUsersPath + "groups/";
export const adminUsersGroupPath = (id: string) => adminUsersGroupsPath + id;

export const adminVodIndexesPath = `${adminVodPath}indexes/`;
