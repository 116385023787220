export const entryPath = "/";
export const loginPath = "/login";
export const notFoundPath = "/not-found";
export const devicesPath = "/devices";
export const homePath = "/home";
export const callcenterPath = "/callcenter";

export const abTestingStatsPath = "/stats/ab-testing";
export const txsPath = "/stats/txs";
export const callCenterStatsPath = "/stats/call-center";

export const fbLeadsStatsPath = "/stats/fb-leads";
export const listSmsPath = "/sms/list";
export const sendSmsPath = "/sms/send";

export const customersPath = "/customers";
export const recentCustomersPath = customersPath + "/recent";
export const searchCustomersPath = customersPath + "/search/:query";
export const customerProfilePath = customersPath + "/:customerId";

export const adminPath = "/admin";
export const adminLivePath = adminPath + "/live";
export const adminLiveChannelsPath = adminLivePath + "/channels";
export const adminLiveChannelPath = adminLiveChannelsPath + "/:channelId";
export const adminLiveCategoriesPath = adminLivePath + "/categories";
export const adminLiveCategoryPath = adminLiveCategoriesPath + "/:categoryId";
