import React, { useContext, useReducer, useRef, useState } from "react";
import Typography from "@mui/material/Typography";
import AddIcon from "@mui/icons-material/Add";
import Grid from "@mui/material/Grid";
import { CustomerContext } from "components/Customer/Page/index";
import Chip from "@mui/material/Chip";
import { makeStyles } from "@mui/styles";
import FormModal from "components/common/FormModal";
import formReducer, { UPDATE_FIELD, UPDATE_FORM } from "reducers/formReducer";
import EmailField from "components/common/fields/EmailField";
import { getFormData, getFormErrors } from "utils/helpers";
import { customerSecondaryEmailUrl } from "constants/urls";
import { SET_DATA_FIELD, UPDATE_DATA_FIELD } from "constants/actions";
import ConfirmModal from "components/common/ConfirmModal";
import { useDispatch } from "react-redux";
import { showErrorAlert, showSuccessAlert } from "components/common/Alert";
import { _fetch } from "hooks/useFetch";
import { unknownError } from "constants/errors";

const useStyles = makeStyles((theme) => ({
  chip: {
    margin: theme.spacing(0.5),
  },
}));

export default function SecondaryEmails() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const customerContext = useContext(CustomerContext);
  const { secondary_emails } = customerContext.data;
  const [form, dispatchForm] = useReducer(formReducer, emailField);
  const [state, setState] = useState({
    isAddModalOpen: false,
    isDeleteModalOpen: false,
    emailToDelete: null,
  });
  const { isAddModalOpen, isDeleteModalOpen, emailToDelete } = state;

  const isFetching = useRef(false);

  function handleAddModalOpen() {
    !isFetching.current && setState((s) => ({ ...s, isAddModalOpen: true }));
  }

  function handleAddModalClose() {
    setState((s) => ({ ...s, isAddModalOpen: false }));
  }

  function handleAddModalReset() {
    dispatchForm({
      type: UPDATE_FIELD,
      field: "email",
      payload: emailField.email,
    });
  }

  function handleAddModalCancel() {
    handleAddModalClose();
    handleAddModalReset();
  }

  async function handleAddModalSubmit() {
    isFetching.current = true;
    const errors = getFormErrors(form);
    if (errors) {
      console.log(errors);
      dispatchForm({ type: UPDATE_FORM, payload: errors });
    } else {
      handleAddModalClose();
      const data = getFormData(form);
      const config = {
        method: "PUT",
        data,
      };
      const { email } = data;
      const id = customerContext.customerId;
      const url = customerSecondaryEmailUrl.replace(":customerId", id);

      const onSuccess = () => {
        dispatch(
          showSuccessAlert({
            message: `Successfully added ${email}`,
          })
        );
        customerContext.dispatch({
          type: UPDATE_DATA_FIELD,
          data: [data.email],
          field: "secondary_emails",
        });
        handleAddModalReset();
      };

      const onFail = (data) => {
        const title = `Failed to add ${email}`;
        const message = emailErrors[data?.err] || unknownError;
        dispatch(
          showErrorAlert({
            message,
            title,
          })
        );
      };

      await _fetch({ url, config, onSuccess, onFail });
      isFetching.current = false;
    }
  }

  const handleDeleteModalOpen = (email) => () => {
    setState((s) => ({ ...s, isDeleteModalOpen: true, emailToDelete: email }));
  };

  function handleDeleteModalClose() {
    setState((s) => ({ ...s, isDeleteModalOpen: false }));
    setTimeout(() => setState((s) => ({ ...s, emailToDelete: null })), 200);
  }

  const handleDelete = (email) => async () => {
    if (isFetching.current) {
      return;
    }
    isFetching.current = true;
    const config = {
      method: "DELETE",
      data: { email },
    };
    const id = customerContext.customerId;
    const url = customerSecondaryEmailUrl.replace(":customerId", id);

    const onSuccess = () => {
      dispatch(
        showSuccessAlert({
          message: `Successfully deleted ${email}`,
        })
      );
      customerContext.dispatch({
        type: SET_DATA_FIELD,
        data: secondary_emails.filter((e) => e !== email),
        field: "secondary_emails",
      });
    };

    const onFail = (data) => {
      const title = `Failed to delete ${email}`;
      const message = emailErrors[data?.err] || unknownError;
      dispatch(
        showErrorAlert({
          message,
          title,
        })
      );
    };
    handleDeleteModalClose();
    await _fetch({ url, config, onSuccess, onFail });
    isFetching.current = false;
  };

  return (
    <Grid container alignItems={"center"}>
      <Grid item lg={2}>
        <Typography align={"right"} variant="body1">
          <strong>Secondary emails:</strong>
        </Typography>
      </Grid>
      <Grid item style={{ paddingLeft: 16 }}>
        {secondary_emails.map((email) => (
          <Chip
            key={email}
            label={email}
            onDelete={handleDeleteModalOpen(email)}
            className={classes.chip}
          />
        ))}
        <FormModal
          open={isAddModalOpen}
          title={"Enter secondary email"}
          handleSubmit={handleAddModalSubmit}
          handleClose={handleAddModalCancel}
          handleOpen={handleAddModalOpen}
          buttonIcon={<AddIcon />}
          buttonText="New"
        >
          <EmailField {...form.email} dispatch={dispatchForm} />
        </FormModal>
      </Grid>
      <ConfirmModal
        handleCancel={handleDeleteModalClose}
        handleOk={handleDelete(emailToDelete)}
        open={isDeleteModalOpen}
        title={"Confirmation"}
        text={`Are you sure you want to delete ${emailToDelete}?`}
      />
    </Grid>
  );
}

const emailField = {
  email: {
    value: "",
    field: "email",
    label: "Email",
    pattern: "^\\S+@\\S+$",
    error: false,
    helperText: "Please enter email",
  },
};

const emailErrors = {
  VALIDATION_FAILED:
    "Entered email didn't pass validation. Make sure you enter a valid email",
  ALREADY_EXISTS: "You can't add same secondary email twice",
  SAME_AS_PRIMARY: "Secondary email can't be same as primary email",
  USER_NOT_FOUND: "User was not found",
  EMAIL_NOT_FOUND: "Secondary email wasn't found. Maybe it was deleted already",
  DELETE_FAILED: "Failed to delete secondary email. Please try again alter",
};
