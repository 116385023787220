import React, { Fragment, useState } from "react";
import { abTestingStatsUrl } from "constants/urls";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import { DateTime } from "luxon";
import DateSelector from "../common/DateSelector";
import Grid from "@mui/material/Grid";
import Spinner from "../common/Spinner";
import { useSimpleFetch } from "hooks/useSimpleFetch";
import TableSortLabel from "@mui/material/TableSortLabel";
import DetailedAbTestingStats from "components/Stats/DetailedAbTestingStats";

export default function AbTestingStats() {
  const { state, fetch, sort } = useSimpleFetch();
  const { isFetching, data, errorMessage, sorting } = state;
  const [date, setDate] = useState({
    startDate: DateTime.local().minus({ days: 1 }),
    endDate: DateTime.local(),
  });

  const handleChange = (field) => (value) => {
    setDate({ ...date, [field]: value });
  };

  function handleClick() {
    const config = {
      method: "GET",
      params: {
        start_date: date.startDate.toISODate(),
        end_date: date.endDate.toISODate(),
      },
    };
    fetch(abTestingStatsUrl, config);
  }

  const fields = [
    {
      name: "payment_flow",
      label: "Payment Flow",
    },
    {
      name: "registration_count",
      label: "Registered",
    },
    {
      name: "conversion_count",
      label: "Converted",
    },
    {
      name: "conversion_percent",
      label: "%",
      type: "Number",
    },
  ];

  return (
    <Fragment>
      <Grid
        container
        alignItems="flex-end"
        justify={"center"}
        style={{ marginBottom: 20 }}
        spacing={10}
      >
        <Grid item>
          <DateSelector
            date={date.startDate}
            handleChange={handleChange("startDate")}
            label={"Select start date"}
          />
        </Grid>
        <Grid item>
          <DateSelector
            date={date.endDate}
            handleChange={handleChange("endDate")}
            label={"Select end date"}
          />
        </Grid>
        <Grid item>
          <Button color="secondary" variant="contained" onClick={handleClick}>
            Search
          </Button>
        </Grid>
      </Grid>
      {isFetching ? (
        <Spinner />
      ) : (
        data && (
          <Table>
            <TableHead>
              <TableRow>
                {fields.map((field) => (
                  <TableCell align="center" key={field.name}>
                    <TableSortLabel
                      active={sorting.field === field.name}
                      direction={sorting.isAscending ? "asc" : "desc"}
                      onClick={sort(field)}
                    >
                      {field.label}
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((item, index) => (
                <TableRow key={item.payment_flow}>
                  <TableCell align="center">{item.payment_flow}</TableCell>
                  <TableCell align="center">
                    {item.registration_count}
                  </TableCell>
                  <TableCell align="center">{item.conversion_count}</TableCell>
                  <TableCell align="center">
                    {item.conversion_percent}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )
      )}
      {data && <DetailedAbTestingStats date={date} />}
    </Fragment>
  );
}
