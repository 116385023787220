import React, { useEffect, useContext, useMemo, useReducer } from "react";
import formReducer, { UPDATE_FORM, UPDATE_FIELD } from "reducers/formReducer";
import { getFormData, getFormErrors } from "utils/helpers";
import { useSelector, useDispatch } from "react-redux";
import { createSlice } from "@reduxjs/toolkit";

import { _fetch } from "hooks/useFetch";
import { customerBillingUrl, plansUrl } from "constants/urls";
import { CustomerContext } from "components/Customer/Page/index";
import { showAlert } from "components/common/Alert";
import { updateBillingPlan } from "./Account";

import SelectField from "components/common/fields/SelectField";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";

export default function AccountForm({ closeEdit }) {
  const dispatch = useDispatch();
  const customerContext = useContext(CustomerContext);
  const billing = useSelector((state) => state.billing);
  const state = useSelector((state) => state.plans);
  const { plans, planStatuses } = state;
  const fields = useMemo(populateValues, []);
  const [formState, dispatchForm] = useReducer(formReducer, fields);

  function populateValues() {
    const fields = { ...billingFields };
    fields.plan.value = billing.planName;
    fields.plan_status.value = billing.planStatus;
    return fields;
  }

  useEffect(() => {
    fetchPlans();
  }, []);

  async function fetchPlans() {
    const config = {
      method: "GET",
    };
    const onSuccess = (data) => {
      const plans = data.plans;
      const planStatuses = data.plan_statuses;
      dispatch(fetchingPlansSuccess({ plans, planStatuses }));
    };

    _fetch({ url: plansUrl, config, onSuccess });
  }

  async function handleSubmit(event) {
    event.preventDefault();
    const errors = getFormErrors(formState);
    if (errors) {
      console.log(errors);
      dispatchForm({ type: UPDATE_FORM, payload: errors });
    } else {
      let data = getFormData(formState);
      const plan = plans.find((p) => p.name === data.plan);
      const planStatus = planStatuses.find((p) => p.name === data.plan_status);
      data = {
        ...data,
        plan: plan.id,
        plan_status: planStatus.id,
      };
      const config = {
        method: "POST",
        data,
      };
      const id = customerContext.customerId;
      const url = customerBillingUrl.replace(":customerId", id);
      const onError = () => {
        dispatch(
          showAlert({
            message: "Failed to update",
            severity: "error",
          })
        );
      };
      const onSuccess = () => {
        dispatch(
          showAlert({
            message: "Updated successfully",
            severity: "success",
          })
        );
        dispatch(
          updateBillingPlan({
            planId: plan.id,
            planName: plan.name,
            planStatusId: planStatus.id,
            planStatus: planStatus.name,
          })
        );
      };
      await _fetch({ url, config, onSuccess, onError });
      closeEdit();
    }
  }

  const handleChange = (field) => (event) => {
    formState[field].value !== event.target.value &&
      dispatchForm({
        type: UPDATE_FIELD,
        field,
        payload: {
          value: event.target.value,
        },
      });
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <Grid container justify={"center"}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <SelectField
                {...formState.plan}
                dispatch={dispatchForm}
                disabled={plans.length === 0}
                options={plans.map((x) => x.name)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <SelectField
                {...formState.plan_status}
                dispatch={dispatchForm}
                disabled={planStatuses.length === 0}
                options={planStatuses.map((x) => x.name)}
              />
            </Grid>
          </Grid>
          <Button variant="contained" type="submit" color="primary">
            Update
          </Button>
          <Button
            style={{ marginLeft: 10 }}
            onClick={closeEdit}
            color="primary"
          >
            Cancel
          </Button>
        </Grid>
      </form>
    </div>
  );
}

const billingFields = {
  plan: {
    value: "",
    field: "plan",
    label: "Plan",
    error: false,
    helperText: "Please enter plan",
  },
  plan_status: {
    value: "",
    field: "plan_status",
    label: "Plan Status",
    error: false,
    helperText: "Please enter plan status",
  },
};

const initialState = {
  plans: [],
  planStatuses: [],
};

const plans = createSlice({
  name: "plans",
  initialState,
  reducers: {
    fetchingPlansSuccess: (state, { payload }) => {
      const { plans, planStatuses } = payload;
      state.plans = plans;
      state.planStatuses = planStatuses;
    },
  },
});

const { fetchingPlansSuccess } = plans.actions;

export const plansReducer = plans.reducer;
