import {
  MenuItem,
  Select,
  TextField,
  Switch,
  InputLabel,
  FormControl,
  FormControlLabel,
  Grid,
} from "@mui/material";
import React from "react";
import { useIndexDispatch, useIndex } from "./VodIndexContext";

export default function IndexesOptions() {
  const { fuzziness, operator, commonFuzziness, size } = useIndex();
  const dispatch = useIndexDispatch();

  return (
    <Grid
      item
      xs={12}
      sx={{ justifyContent: "flex-end", display: "flex", gap: "10px" }}
    >
      <FormControl component="fieldset" variant="outlined">
        <TextField
          id="size"
          label="Size"
          value={size}
          style={{ width: "100px" }}
          onChange={(e) => {
            dispatch({
              type: "option",
              option: "size",
              value: parseInt(e.target.value) || 0,
            });
          }}
        />
      </FormControl>
      <FormControl component="fieldset" variant="outlined">
        <InputLabel id="fuzziness-label">Fuzziness</InputLabel>
        <Select
          labelId="fuzziness-labels"
          value={fuzziness}
          label="Fuzziness"
          style={{ width: "100px" }}
          onChange={(e) =>
            dispatch({
              type: "option",
              option: "fuzziness",
              value: e.target.value,
            })
          }
        >
          <MenuItem value="0">0</MenuItem>
          <MenuItem value="1">1</MenuItem>
          <MenuItem value="2">2</MenuItem>
          <MenuItem value="AUTO">AUTO</MenuItem>
        </Select>
      </FormControl>
      <FormControl component="fieldset" variant="outlined">
        <InputLabel id="operator-label">Operator</InputLabel>
        <Select
          value={operator}
          labelId="operator-label"
          label="Operator"
          style={{ width: "100px" }}
          onChange={(e) =>
            dispatch({
              type: "option",
              option: "operator",
              value: e.target.value,
            })
          }
        >
          <MenuItem value="AND">AND</MenuItem>
          <MenuItem value="OR">OR</MenuItem>
        </Select>
      </FormControl>
      <FormControl component="fieldset" variant="outlined">
        <FormControlLabel
          control={
            <Switch
              checked={commonFuzziness}
              sx={{ alignSelf: "center" }}
              onChange={(e) =>
                dispatch({
                  type: "option",
                  option: "commonFuzziness",
                  value: e.target.checked,
                })
              }
              inputProps={{ "aria-label": "controlled" }}
            />
          }
          label="Use fuzziness for all requests"
        />
      </FormControl>
    </Grid>
  );
}
