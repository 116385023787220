import React, { Fragment, useState } from "react";
import { Customer, ModalAction } from "customers/types/Customer";
import { makeStyles } from "@mui/styles";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import SendIcon from "@mui/icons-material/Send";
import Backdrop from "@mui/material/Backdrop";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import { successAlert, errorAlert } from "components/common/Alert";
import { _fetch } from "hooks/useFetch";
import { emailUrl } from "constants/urls";

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    paddingBottom: 0,
  },
  dialog: {
    textAlign: "center",
  },
  container: {
    height: 500,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  grid: {
    textAlign: "left",
    justify: "center",
    marginBottom: theme.spacing(3),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  backdrop: {
    zIndex: theme.zIndex.modal,
  },
  textfield: {
    width: 300,
  },
  textarea: {
    marginTop: theme.spacing(2),
  },
  dialogActions: {
    justifyContent: "center",
  },
}));

export default function EmailModal({
  open,
  dispatch,
  agent,
  customer,
}: {
  open: boolean;
  dispatch: React.Dispatch<ModalAction>;
  agent: string;
  customer: Customer;
}) {
  const classes = useStyles();
  const { username, email } = customer;
  const [backdrop, setBackdropOpen] = useState(false);
  const [subject, setSubject] = useState("");
  const [body, setBody] = useState("");

  const templates = [
    {
      name: "фин препей",
      text: `eTVnet: Предоплата, сделанная вами на аккаунте ${username} подходит к концу. Свяжитесь с нами по телефону +1 (888) 311-7811`,
    },
    {
      name: "фин дебт",
      text: `eTVnet: Сервис на аккаунте ${username} приостановлен из-за задолженности. Свяжитесь с нами по телефону +1 (888) 311-7811`,
    },
    {
      name: "замена карты",
      text: `eTVnet: Срок действия кредитной карты на аккаунте ${username} истекает. Пожалуйста, обновите данные в личном кабинете на сайте.`,
    },
    {
      name: "инф",
      text: `\n\neTVnet: Ethnic Television Network / Matvil Corp. 312 Dolomite Dr. Unit 215, Toronto, ON, CA, M3J 2N2`,
    },
    {
      name: "инф (USA)",
      text: `eTVnet: +1 (888) 311-7811, 9am-11pm`,
    },
    {
      name: "инф (Canada)",
      text: `eTVnet: +1 (888) 311-7811, 9am-11pm`,
    },
    {
      name: "Свободный план",
      text: `eTVnet: Спасибо что вы были с нами! Мы хотим предоставить вам переход на "Свободный план", полный доступ ко всем каналам в прямом эфире и к архиву за 0.60 центов в час. Если вы не смотрите, вы не платите. Для активации плана свяжитесь с нами по телефону +1 (888) 311-7811`,
    },
  ];

  const handleSubjectChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setSubject(event.target.value);
  };

  const handleBodyChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setBody(event.target.value);
  };

  function handleTemplateChange(event: React.ChangeEvent<HTMLInputElement>) {
    setBody(event.target.value);
  }

  const handleClose = () => {
    dispatch({ field: "email", open: false });
  };

  const onSuccess = () => {
    setBackdropOpen(false);
    successAlert(`Successfully sent email to ${username}`);
  };

  const onFail = () => {
    setBackdropOpen(false);
    errorAlert(`Could not send email to ${username}`);
  };

  const handleSubmit = () => {
    const data = {
      customer: username,
      customerEmail: email,
      agent,
      subject: subject,
      textMessage: body,
    };
    const config = {
      method: "POST",
      timeout: 5000,
      headers: {
        "x-api-key": "7RWazrhtwq7yA0emwQ64P4CVwDWu1YIK38raM7EP",
      },
      data,
    };
    handleClose();
    setBackdropOpen(true);
    _fetch({
      url: emailUrl,
      config,
      skipRetry: true,
      onFail: onFail,
      onError: onFail,
      onSuccess: onSuccess,
    });
  };

  return (
    <Fragment>
      <Dialog
        className={classes.dialog}
        fullWidth
        maxWidth="md"
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <Container className={classes.container}>
          <DialogTitle className={classes.dialogTitle}>
            Send Email to customer <strong>{username}</strong>{" "}
          </DialogTitle>
          <DialogContent>
            <Grid container className={classes.grid} justifyContent="center">
              <TextField
                select
                label="Template"
                className={classes.textfield}
                value={body}
                onChange={handleTemplateChange}
              >
                {templates.map((item) => (
                  <MenuItem key={item.name} value={item.text}>
                    {item.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <TextField
              fullWidth
              label="Subject"
              onChange={handleSubjectChange}
              value={subject}
            />
            <TextField
              multiline
              fullWidth
              label="Body"
              className={classes.textarea}
              variant="outlined"
              rows={11}
              onChange={handleBodyChange}
              value={body}
            />
          </DialogContent>
          <DialogActions className={classes.dialogActions}>
            <Button
              onClick={handleSubmit}
              disabled={!subject || !body}
              variant="contained"
              color="secondary"
            >
              Send
              <SendIcon className={classes.rightIcon} />
            </Button>
          </DialogActions>
        </Container>
      </Dialog>
      <Backdrop className={classes.backdrop} open={backdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Fragment>
  );
}
