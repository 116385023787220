import React, { useRef, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import { makeStyles } from "@mui/styles";
import SmsIcon from "@mui/icons-material/Sms";
import SendIcon from "@mui/icons-material/Send";
import Grid from "@mui/material/Grid";
import Chip from "@mui/material/Chip";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { smsUrl } from "constants/urls";
import { useSelector } from "react-redux";
import { _fetch } from "hooks/useFetch";

const useStyles = makeStyles((theme) => ({
  dialog: {
    textAlign: "center",
  },
  button: {
    margin: theme.spacing(1),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  chip: {
    margin: theme.spacing(0.5),
  },
}));

export default function SmsModal({ phones, customer }) {
  const classes = useStyles();
  const user = useSelector((state) => state.user);
  const { username, id } = customer;
  const [open, setOpen] = useState(false);
  const [existingPhones, setExistingPhones] = useState(() =>
    phones.filter((item) => item.validation_status === "Exists")
  );
  const initialPhones = useRef(existingPhones);
  const [text, setText] = useState("");
  const templates = [
    {
      name: "фин препей",
      text: `eTVnet: Предоплата, сделанная вами на аккаунте ${username} подходит к концу. Свяжитесь с нами по телефону +1 (888) 311-7811`,
    },
    {
      name: "фин дебт",
      text: `eTVnet: Сервис на аккаунте ${username} приостановлен из-за задолженности. Свяжитесь с нами по телефону +1 (888) 311-7811`,
    },
    {
      name: "замена карты",
      text: `eTVnet: Срок действия кредитной карты на аккаунте ${username} истекает. Пожалуйста, обновите данные в личном кабинете на сайте.`,
    },
    {
      name: "инф",
      text: `eTVnet: Ethnic Television Network / Matvil Corp. 312 Dolomite Dr. Unit 215, Toronto, ON, CA, M3J 2N2`,
    },
    {
      name: "инф (USA)",
      text: `eTVnet: +1 (888) 311-7811, 9am-11pm`,
    },
    {
      name: "инф (Canada)",
      text: `eTVnet: +1 (888) 311-7811, 9am-11pm`,
    },
    {
      name: "Свободный план",
      text: `eTVnet: Спасибо что вы были с нами! Мы хотим предоставить вам переход на "Свободный план", полный доступ ко всем каналам в прямом эфире и к архиву за 0.60 центов в час. Если вы не смотрите, вы не платите. Для активации плана свяжитесь с нами по телефону +1 (888) 311-7811`,
    },
  ];

  function handleTextChange(event) {
    setText(event.target.value);
  }

  function handleTemplateChange(event) {
    setText(event.target.value);
  }

  function handleOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
    // avoid flickering
    setTimeout(() => {
      setExistingPhones(initialPhones.current);
      setText("");
    }, 200);
  }

  const handleDelete = (phone) => () => {
    setExistingPhones((phones) => phones.filter((item) => item !== phone));
  };

  function handleSumbit() {
    const ipi = "HRMAnInEz7ONUPR7OlSgBZyBn5cCgxfo";
    const data = {
      // ipi,
      phones: existingPhones.map((p) => p.validated_number),
      message: text,
      user: user.username,
      customer: `${id}`,
    };
    const config = {
      method: "PUT",
      data,
    };
    _fetch({ url: smsUrl, config });
    handleClose();
  }

  function startOutboundCall() {
    const endpoint = window.connect.Endpoint.byPhoneNumber("+12262347718");
    window.agent.connect(endpoint, {
      failure: (e) => console.log(e, "outbound call failed"),
      success: () => console.log("success"),
    });
  }

  return (
    <div style={{ marginTop: 20 }}>
      {existingPhones.length > 0 && (
        <Grid container justify={"center"}>
          <Button
            onClick={handleOpen}
            variant="contained"
            color="secondary"
            className={classes.button}
          >
            Send sms
            <SmsIcon className={classes.rightIcon} />
          </Button>
          <Button
            onClick={startOutboundCall}
            variant="contained"
            color="primary"
            className={classes.button}
          >
            Позвонить
          </Button>
        </Grid>
      )}
      <Dialog
        fullWidth
        maxWidth={"md"}
        open={open}
        onClose={handleClose}
        className={classes.dialog}
        aria-labelledby="form-dialog-title"
      >
        <Container style={{ height: 500 }}>
          <DialogTitle id="form-dialog-title">
            Sending sms to user <strong>{username}</strong>{" "}
          </DialogTitle>
          <DialogContent>
            {existingPhones.map((phone) => (
              <Chip
                color={"primary"}
                key={phone.national_format}
                label={`${phone.national_format} - ${
                  phone.carrier_type || "unknown"
                }`}
                onDelete={handleDelete(phone)}
                className={classes.chip}
              />
            ))}
            <Grid
              container
              justify={"center"}
              style={{ marginTop: 20, textAlign: "left" }}
            >
              <TextField
                select
                label="Template"
                value={text}
                onChange={handleTemplateChange}
                style={{ width: "300px" }}
              >
                {templates.map((item) => (
                  <MenuItem key={item.name} value={item.text}>
                    {item.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <TextareaAutosize
              value={text}
              onChange={handleTextChange}
              style={{ width: "500px", marginTop: 50 }}
              rows={8}
              placeholder="Текст смс"
            />
          </DialogContent>
          <DialogActions style={{ justifyContent: "center" }}>
            <Button
              onClick={handleSumbit}
              variant="contained"
              color="secondary"
              className={classes.button}
            >
              Send
              <SendIcon className={classes.rightIcon} />
            </Button>
          </DialogActions>
        </Container>
      </Dialog>
    </div>
  );
}
