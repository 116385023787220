import React, { createContext, Fragment, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Header from "components/Customer/Page/Header";
import { useSimpleFetch } from "hooks/useSimpleFetch";
import { customerProfileUrl } from "constants/urls";
import Spinner from "components/common/Spinner";
import Tabs from "components/Customer/Page/Main/Tabs";
import { mainTabsData, sideTabsData } from "components/Customer/Page/config";
import { storeProfile } from "home/components/RecentlyViewedCustomers/RecentlyViewedCustomers";
export const CustomerContext = createContext();

export default function Page() {
  const { state, fetch, dispatch } = useSimpleFetch();
  const { isFetching, data } = state;
  const { customerId } = useParams();
  // const c = customerId || 159;
  // const c = customerId || 850980;
  // const c = customerId || 48;

  useEffect(() => {
    fetch(customerProfileUrl.replace(":customerId", customerId));
  }, [customerId]);

  // store for RecentlyViewedCustomers
  useEffect(() => {
    if (data?.user) {
      const profile = {
        id: data.user.id,
        username: data.user.username,
        lastSeen: new Date().toISOString(),
      };
      storeProfile(profile);
    }
  }, [data]);

  const context = { data, dispatch, customerId };

  return (
    <CustomerContext.Provider value={context}>
      {isFetching && <Spinner />}
      {data && (
        <Fragment>
          <Header user={data.user} />
          <Grid container style={{ marginTop: 10 }} spacing={2}>
            <Grid item lg={8} md={12}>
              <Tabs tabsData={mainTabsData} active={0} />
            </Grid>
            <Grid item xs>
              <Tabs tabsData={sideTabsData} active={1} />
            </Grid>
          </Grid>
        </Fragment>
      )}
    </CustomerContext.Provider>
  );
}
