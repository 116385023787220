import React from "react";

const masterCard = process.env.PUBLIC_URL + "/mcard_icon.png";
const amex = process.env.PUBLIC_URL + "/amex_icon.png";
const visa = process.env.PUBLIC_URL + "/visa_icon.png";

function CardIcons({ active, error }) {
  const getStyle = React.useCallback(
    (item) => {
      return { opacity: item === active ? 1 : 0.5 };
    },
    [active]
  );

  return (
    <div
      style={{
        position: "absolute",
        right: "10px",
        bottom: error ? "32px" : "13px",
      }}
    >
      <img src={masterCard} style={getStyle("masterCard")} alt="" />
      <img src={visa} style={getStyle("visa")} alt="" />
      <img src={amex} style={getStyle("amex")} alt="" />
    </div>
  );
}

export default React.memo(CardIcons);
