import React, { useContext, useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import { listSmsUrl } from "constants/urls";
import { useSimpleFetch } from "hooks/useSimpleFetch";
import Spinner from "components/common/Spinner";
import { Paper } from "@mui/material";
import Typography from "@mui/material/Typography";
import { sort } from "utils/helpers";
import { CustomerContext } from "customers/components/CustomerInfo/CustomerInfo";

export function ListSms() {
  const customer = useContext(CustomerContext);
  const { state, fetch } = useSimpleFetch();
  const { isFetching, data } = state;
  const [sms, setSms] = useState();

  useEffect(() => {
    fetch({ url: listSmsUrl.replace(":customerId", customer.id) });
  }, []);

  useEffect(() => {
    if (data) {
      const inbound = data.inbound
        ? data.inbound.map((item) => ({ ...item, type: "inbound" }))
        : [];
      const outgoing = data.outgoing
        ? data.outgoing.map((item) => ({ ...item, type: "outgoing" }))
        : [];
      const sms = [...inbound, ...outgoing].sort(
        sort("created", "asc", "Date")
      );
      sms.length && setSms(sms);
    }
  }, [data]);

  return (
    <div style={{ marginTop: 30 }}>
      {isFetching ? (
        <Spinner />
      ) : (
        sms && (
          <Paper style={{ padding: 8 }}>
            <Typography style={{ padding: 14 }} variant={"h6"}>
              Sms history
            </Typography>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="left">Type</TableCell>
                  <TableCell align="left">Phone</TableCell>
                  <TableCell align="left">Message</TableCell>
                  <TableCell align="left">Agent</TableCell>
                  <TableCell align="left">Status</TableCell>
                  <TableCell align="right">Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sms.map((item, index) => (
                  <TableRow key={index + "msg"}>
                    <TableCell align="left">{item.type}</TableCell>
                    <TableCell align="left">{item.phone}</TableCell>
                    <TableCell align="left">{item.message}</TableCell>
                    <TableCell align="left">{item.user}</TableCell>
                    <TableCell
                      align="left"
                      style={{
                        color: item.status_ === "undelivered" && "red",
                      }}
                    >
                      {item.status_}
                    </TableCell>
                    <TableCell align="right">
                      {item.created.slice(0, 10)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>
        )
      )}
    </div>
  );
}
