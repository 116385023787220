import React from "react";
import { Route, Switch } from "react-router-dom";
import { customerPath, customersPath } from "customers/urls";
import CustomerInfo from "customers/components/CustomerInfo";

export default function CustomersSection() {
  return (
    <Switch>
      <Route
        exact
        path={customersPath}
        component={() => <div>Customers</div>}
      />
      <Route
        exact
        path={customerPath(":customerId")}
        component={CustomerInfo}
      />
    </Switch>
  );
}
