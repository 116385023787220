import { createSlice } from "@reduxjs/toolkit";
import { loadState, deleteState } from "utils/localStorage";
import { userKey } from "constants/storageKeys";
import etvClient from "config/etvClient";

export function initUser() {
  return loadState(userKey) || initialState;
}

export interface UserState {
  isFetching: boolean;
  username?: string;
  token?: string;
  initials?: string;
  group?: string;
}

const initialState: UserState = {
  isFetching: false,
  username: void 0,
  token: void 0,
  initials: void 0,
  group: void 0,
};

const user = createSlice({
  name: "user",
  initialState,
  reducers: {
    loginStart: (state) => {
      state.isFetching = true;
    },
    loginSuccess: (_, { payload }) => {
      return {
        ...payload,
        isFetching: false,
      };
    },
    loginFailed: (state) => {
      state.isFetching = false;
    },
    logout: () => {
      deleteState(userKey);
      etvClient.clearStore();
      return initialState;
    },
  },
});

export const { loginStart, loginSuccess, loginFailed, logout } = user.actions;
export const userReducer = user.reducer;
