import React, { useEffect } from "react";
import Event from "components/Customer/Page/Main/ActivityTab/Event";
import Task from "components/Customer/Page/Main/ActivityTab/Task";
import Log from "components/Customer/Page/Main/ActivityTab/Log";
import { useSimpleFetch } from "hooks/useSimpleFetch";
import { useParams } from "react-router-dom";
import { customerEventsUrl, customerProfileUrl } from "constants/urls";
import { formatISODate } from "utils/helpers";

export default function Activity(props) {
  const { state, fetch, dispatch } = useSimpleFetch();
  const { isFetching, data } = state;
  const { customerId } = useParams();
  // http://localhost:3000/customers/1646143

  useEffect(() => {
    fetch(customerEventsUrl + customerId, { etvnetAuth: false });
  }, [customerId]);

  function getDiff(data) {
    let diff = [];
    const old = data.old;
    for (let p in old) {
      if (old.hasOwnProperty(p)) {
        diff.push({ field: p, old: old[p], new: data.new[p] });
      }
    }
    return diff;
  }

  return (
    <div>
      {data &&
        data.map(event => (
          <Event
            key={event.created}
            dueDate={formatISODate(event.created)}
            name={event.action}
            type={event.type}
          >
            <Log agent={event.agent} comment={""} diff={getDiff(event.data)} />
          </Event>
        ))}

      {/*<Event*/}
      {/*  dueDate={"22 Dec"}*/}
      {/*  name={"Follow Up with Howard on timing"}*/}
      {/*  type={"log"}*/}
      {/*>*/}
      {/*  <Task*/}
      {/*    agent={"Jora Kornev"}*/}
      {/*    created={"23 Sep 2018"}*/}
      {/*    description={*/}
      {/*      "        Япония- написать- напомнить о сервисе, у него было письмо осенью 2018\n" +*/}
      {/*      '        года - "Я решил отказаться от Вашего прекрасного сервиса, потому что\n' +*/}
      {/*      "        переехал в Москву жить и работать на несколько лет. Обещаю, когда\n" +*/}
      {/*      "        вернусь домой из России через несколько лет, обязательно снова запишусь\n" +*/}
      {/*      '        на Ваш сервис."'*/}
      {/*    }*/}
      {/*  />*/}
      {/*</Event>*/}
      {/*<Event*/}
      {/*  dueDate={"21 Dec"}*/}
      {/*  name={"Sent email to eblan@gmail.com"}*/}
      {/*  type={"email"}*/}
      {/*/>*/}
      {/*<Event*/}
      {/*  dueDate={"20 Dec"}*/}
      {/*  name={"Sent SMS to (643)-100-5001"}*/}
      {/*  type={"sms"}*/}
      {/*/>*/}
    </div>
  );
}
