export function loadState(key) {
  try {
    const serializedState = localStorage.getItem(key);

    if (serializedState !== null) {
      return JSON.parse(serializedState);
    }
  } catch (e) {}
  return void 0;
}

export function saveState(key, data) {
  try {
    const serializedState = JSON.stringify(data);
    localStorage.setItem(key, serializedState);
  } catch (e) {}
}

export function deleteState(key) {
  try {
    localStorage.removeItem(key);
  } catch (e) {}
}
