import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import TaskForm from "./TaskForm";

export default function Task() {
  const [submitted, set] = useState(false);

  return (
    <div>
      {!submitted ? (
        <TaskForm close={() => set(!submitted)} />
      ) : (
        <Grid container justifyContent="center">
          <Button
            variant="contained"
            color="primary"
            onClick={() => set(!submitted)}
          >
            New Task
          </Button>
        </Grid>
      )}
    </div>
  );
}
