import React, { useMemo, useState } from "react";
import { Tabs as MuiTabs } from "@mui/material";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

export default function Tabs({ tabsData, active = 0 }) {
  const [value, setValue] = useState(active);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const tabs = useMemo(
    () =>
      tabsData.map(({ label }) => <Tab key={label + "tab"} label={label} />),
    []
  );

  return (
    <Paper style={{ padding: 10 }}>
      <MuiTabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        variant="standard"
        aria-label="full width tabs example"
      >
        {tabs}
      </MuiTabs>

      {tabsData.map(({ label, component }, index) => (
        <TabPanel key={label + "panel"} value={value} index={index}>
          {component}
        </TabPanel>
      ))}
    </Paper>
  );
}
