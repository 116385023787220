import { useReducer } from "react";
import { simpleInitialState, simpleReducer } from "reducers/simpleReducer";
import { ERROR, FETCHING, SORT, SUCCESS } from "constants/actions";
import { sort } from "utils/helpers";
import { _fetch } from "hooks/useFetch";

export function useSimpleFetch() {
  const [state, dispatch] = useReducer(simpleReducer, simpleInitialState);

  async function simpleFetch(url, config = {}) {
    dispatch({ type: FETCHING });

    const onSuccess = data => {
      dispatch({ type: SUCCESS, data });
    };
    const onError = data => {
      console.log(data);
      const msg = "Произошла ошибка при загрузке данных";
      dispatch({ type: ERROR, msg: data || msg });
      // messageContext.dispatch({ type: SHOW_MESSAGE, message: data || msg });
    };
    await _fetch({ url, config, onSuccess, onError });
  }

  const sortData = field => () => {
    const { name, type } = field;
    const sorted = state.data.sort(sort(name, state.sorting.isAscending, type));
    dispatch({ type: SORT, sorted, field: name });
  };
  return { state, dispatch, fetch: simpleFetch, sort: sortData };
}
